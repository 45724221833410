import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { useState } from 'react'

export default function PasswordTextField(props: TextFieldProps) {
  const { name, label, value, onChange, ...rest } = props

  const [showPassword, setShowPassword] = useState(false)

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      name={name}
      label={label}
      value={value || ''}
      onChange={onChange}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}
