import { ToastOptions, toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'

export default function useNotification() {
  const notifySuccess = (message: string, options?: ToastOptions) => {
    toast.success(message, {
      ...options,
      style: { ...options?.style, pointerEvents: 'auto' },
    })
  }

  const notifyInfo = (message: string, options?: ToastOptions) => {
    toast.info(message, {
      ...options,
      style: { ...options?.style, pointerEvents: 'auto' },
    })
  }

  const notifyWarning = (message: string, options?: ToastOptions) => {
    toast.warning(message, {
      ...options,
      style: { ...options?.style, pointerEvents: 'auto' },
    })
  }

  const notifyError = (message: string, options?: ToastOptions) => {
    toast.error(message, {
      ...options,
      style: { ...options?.style, pointerEvents: 'auto' },
    })
  }

  const notifyException = (e: any, options?: ToastOptions) => {
    if (axios.isAxiosError(e)) {
      axiosError(e, {
        ...options,
        style: { ...options?.style, pointerEvents: 'auto' },
      })
    } else {
      toast.error(e.message, {
        ...options,
        style: { ...options?.style, pointerEvents: 'auto' },
      })
    }
  }

  // const notifyAction = (message: string, action: SnackbarAction) => {
  //   enqueueSnackbar(message, { variant: 'default', action })
  // }

  function axiosError(e: any, options?: ToastOptions) {
    const axiosError = e as AxiosError
    const defaultMessage = 'Ocorreu um erro ao tentar processar a requisição'

    const mappedStatusErrorsMessages: { [key: number]: string } = {
      // 403: 'Suas credenciais não estão corretas',
    }

    const status: number = axiosError.request.status as number

    if (mappedStatusErrorsMessages[status]) {
      toast.error(mappedStatusErrorsMessages[status], {
        ...options,
        style: { ...options?.style, pointerEvents: 'auto' },
      })
      return
    }

    // if (import.meta.env.VITE_ENV === 'development') {
    //   toast.error(
    //     e.response ? e.response?.data?.message || e.message : e.message,
    //   )
    //   return
    // }

    const responseMessage = Array.isArray(e.response?.data?.errors)
      ? e.response?.data?.errors[0]
      : defaultMessage

    if (e.response?.status >= 400 && e.response?.status <= 500) {
      toast.error(responseMessage, {
        ...options,
        style: { ...options?.style, pointerEvents: 'auto' },
      })
      return
    }

    toast.error(defaultMessage, {
      ...options,
      style: { ...options?.style, pointerEvents: 'auto' },
    })
  }

  return {
    notifySuccess,
    notifyInfo,
    notifyWarning,
    notifyError,
    // notifyAction,
    notifyException,
  }
}
