import { Download as DownloadIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'

import ProgressDownloadDialog from 'components/ProgressDownloadDialog'
import DropDownRelatorio from 'components/Inputs/DropDownRelatorio'
import FormDrawerContainer from 'components/FormDrawerContainer'

import { useSelectableCardContext } from 'contexts/SelectableCardContext'

import { BoletoDetalhadoModel } from 'hooks/queries/useBoleto'
import useValidations from 'hooks/useValidation'
import useDownload from 'hooks/useDownload'
import usePDF from 'hooks/queries/usePDF'

import * as yup from 'yup'

const schema = yup.object().shape({
  relatorioId: yup.string().required('Informe o relatório'),
})

export interface PDFBoletosFormProps {
  itensSelecionados: BoletoDetalhadoModel[]
  relatorioId: string
}

export default function Form() {
  const { onClearIntesSelecionados } =
    useSelectableCardContext<BoletoDetalhadoModel>()

  const { setValidationErrors, validationProps } = useValidations()
  const { downloadArquivo } = useDownload()

  const { useObterPDF } = usePDF()
  const { obterPDF, isDownloading, progress } = useObterPDF()

  const { closeForm, toggleSubmit, changeValue } =
    useFormApi<PDFBoletosFormProps>()
  const { formValues: data } = useFormData<PDFBoletosFormProps>()

  function handleObterPDF() {
    schema
      .validate(data, {
        abortEarly: false,
      })
      .then(async () => {
        toggleSubmit(true)
        const { itensSelecionados, relatorioId } = data
        const itensSelecionadosIds = itensSelecionados.map(
          (item) => item?.id || '',
        )

        const boletoPDF = await obterPDF({
          route: 'BoletoConsulta/ObterPDFBoletosUnificado',
          axiosRequestConfig: { params: { relatorioId } },
          ids: itensSelecionadosIds,
          api: 'stella-hubbank',
        })

        downloadArquivo(boletoPDF, 'boleto-pdf', 'pdf')
        closeForm()
        onClearIntesSelecionados()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  const chaveId = `BOLETO_${data?.itensSelecionados?.[0]?.convenioBancoCodigo}`

  return (
    <FormDrawerContainer
      title={`Baixar pdf ${
        data?.itensSelecionados?.length === 1 ? 'do boleto' : 'dos boletos'
      }`}
      onSubmit={handleObterPDF}
      confirmLabel="Baixar"
      confirmIcon={<DownloadIcon />}
    >
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <DropDownRelatorio
          name="relatorioId"
          required
          requestProps={{ chaveId, api: 'stella-hubbank' }}
          value={data?.relatorioId || ''}
          onChange={(e) => changeValue('relatorioId', e.target.value)}
          onRender={(primeiroRelatorio) =>
            changeValue('relatorioId', primeiroRelatorio?.id)
          }
          {...validationProps('relatorioId')}
        />
      </Grid>

      <ProgressDownloadDialog
        isOpen={isDownloading}
        progressValue={progress}
        title={`Baixando...`}
      />
    </FormDrawerContainer>
  )
}
