import { Stack, Typography, useTheme } from '@mui/material'
import { nfModeloMapping } from 'hooks/queries/useNF'
import emailEnviadoImage from 'assets/images/mail-send-image.svg'

interface HeaderProps {
  modelo: number
  emailsEnviados: string[]
}

export default function Header(props: HeaderProps) {
  const { modelo, emailsEnviados } = props

  const theme = useTheme()
  const nfModeloDescricao = nfModeloMapping[modelo]

  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: 3,
        paddingY: 2,
        marginBottom: 2,
      }}
    >
      <img
        src={emailEnviadoImage}
        width="120px"
        alt="Imagem de email enviado"
      />

      <Stack spacing={1}>
        <Typography
          variant="h1"
          component="h2"
          fontSize="28px"
          fontWeight="medium"
        >
          {emailsEnviados.length === 1
            ? 'Email enviado com sucesso!'
            : 'Emails enviados com sucesso'}
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.grey[500]}
          fontWeight="bold"
        >
          A {nfModeloDescricao} foi enviada para{' '}
          {emailsEnviados.length === 1
            ? 'o email listado'
            : 'os emails listados'}{' '}
          abaixo
        </Typography>
      </Stack>
    </Stack>
  )
}
