import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownNFeFinalidadeEnum {
  NORMAL_1 = 1,
  COMPLEMENTAR_2 = 2,
  AJUSTE_3 = 3,
  DEVOLUCAO_MERCADORIA_4 = 4,
}

const menuItems = [
  {
    value: DropDownNFeFinalidadeEnum.NORMAL_1,
    label: '1 - Normal',
  },
  {
    value: DropDownNFeFinalidadeEnum.COMPLEMENTAR_2,
    label: '2 - Complementar',
  },
  {
    value: DropDownNFeFinalidadeEnum.AJUSTE_3,
    label: '3 - Ajuste',
  },
  {
    value: DropDownNFeFinalidadeEnum.DEVOLUCAO_MERCADORIA_4,
    label: '4 - Devolução de mercadoria',
  },
]

export default function DropDownNFeFinalidade(props: DynamicDropDownProps) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="NF-e Finalidade"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
