import { Stack, useTheme } from '@mui/material'
import CardInfo from 'components/CardInfo'

interface ContentInfoProps {
  pessoaNome?: string | null
  contaFinanceiraNome?: string | null
  categoriaNome?: string | null
  documento?: string | null
  meioPagamentoDesc?: string | null
  boletoRegistroFormatado: string
  boletoSituacao?: string | null
  boletoNossoNumero?: string | null
  boletoMensagemErro?: string | null
}

export default function ContentInfo(props: ContentInfoProps) {
  const {
    pessoaNome,
    contaFinanceiraNome,
    categoriaNome,
    documento,
    meioPagamentoDesc,
    boletoRegistroFormatado,
    boletoSituacao,
    boletoNossoNumero,
    boletoMensagemErro,
  } = props
  const theme = useTheme()

  return (
    <Stack
      sx={{
        paddingX: 1,
        paddingBottom: 4,
        gap: 0.2,
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        cursor: 'default',
      }}
    >
      <CardInfo
        title="Pessoa"
        subtitle={pessoaNome || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Conta Financeira"
        subtitle={contaFinanceiraNome || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Categoria"
        subtitle={categoriaNome || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Documento"
        subtitle={documento || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Meio de Pagamento"
        subtitle={meioPagamentoDesc || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Boleto/Data de Emissão"
        subtitle={boletoRegistroFormatado || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Boleto/Situação"
        subtitle={boletoSituacao || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Boleto/Nosso Número"
        subtitle={boletoNossoNumero || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
      <CardInfo
        title="Boleto/Erros reportados"
        subtitle={boletoMensagemErro || '---'}
        sx={{ ':hover': { backgroundColor: '' } }}
      />
    </Stack>
  )
}
