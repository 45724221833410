import { IconButton, useTheme } from '@mui/material'
import { ReactElement } from 'react'

interface PlusMinusButtonProps {
  icon: ReactElement
  disabled?: boolean
  onClick: () => void
}

export default function PlusMinusButton(props: PlusMinusButtonProps) {
  const { icon, disabled, onClick } = props
  const theme = useTheme()

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        ':hover': { backgroundColor: theme.palette.primary.dark },
        borderRadius: '4px',
      }}
    >
      {icon}
    </IconButton>
  )
}
