import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Grid, Stack, TextField } from '@mui/material'

import {
  formatCurrency,
  isValidNumber,
  useDialog,
  useForm,
} from '@data-c/hooks'

import { useNFContext } from 'contexts/NFContext/NFContext'

import { FormaPagamentoModel } from 'hooks/queries/useFormaPagamento'
import useNF, { NFPagamentosProps, NFModel } from 'hooks/queries/useNF'
import useValidations from 'hooks/useValidation'
import extrairParcelasEPeriodo from 'hooks/useExtrairParcelasEPeriodo'

import { MeioPagamentoEnum } from 'enums/MeioPagamentoEnum'

import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import DropDownCartaoBandeira from 'components/Inputs/DropDownCartaoBandeira'
import CurrencyTextField from 'components/CurrencyTextField'
import DeleteDrawer from 'components/DeleteDrawer'
import FloatFooter from 'components/FloatFooter'
import Button from 'components/Button'

import CardParcelas from './components/CardParcelas'
import * as yup from 'yup'

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  valorPagamento: yup
    .number()
    .nullable(true)
    .required('Informe o valor do pagamento')
    .moreThan(0, 'O valor do pagamento deve ser superior a zero(0)')
    .transform(transform),
  cartaoBandeira: yup
    .number()
    .nullable(true)
    .when('$isMeioPagamentoCartao', {
      is: true,
      then: yup.number().nullable().required('Informe a Bandeira'),
    }),
})

const defaultData = {
  nfId: '',
  formaPagamentoId: '',
  valorPagamento: 0,
  numeroAutorizacao: '',
  parcelamento_CondPgto: '',
  numeroDeParcelas: 0,
  periodo: 0,
  cartaoBandeiraDesc: '',
  cartaoBandeira: null,
}

interface FormProps {
  nf?: NFModel
  formaPagamento?: FormaPagamentoModel
}

export default function Form(props: FormProps) {
  const { nf, formaPagamento } = props

  const navigate = useNavigate()

  const { nfIds, setNFIds } = useNFContext()
  const { validationProps, setValidationErrors } = useValidations()
  const { useCreateOrUpdateNFPagamento, useRemoverNFPagamento } = useNF()
  const { mutateAsync: removerNFPagamento, isLoading: isDeletingNFPagamento } =
    useRemoverNFPagamento()
  const { mutateAsync: createOrUpdateNFPagamento, isLoading: isSubmiting } =
    useCreateOrUpdateNFPagamento()

  const { isOpen: isOpenDialog, closeDialog, openDialog } = useDialog()
  const { data, setData, changeValue } = useForm<NFPagamentosProps>(defaultData)

  useEffect(() => {
    const nfPagamento = nf?.nfPagamentos?.find(
      (nfPagamento) => nfPagamento.id === nfIds.nfPagamentoId,
    )

    if (nfPagamento) {
      const { numeroDeParcelas, periodo } = extrairParcelasEPeriodo(
        nfPagamento.parcelamento_CondPgto,
      )

      setData({
        ...nfPagamento,
        numeroDeParcelas,
        periodo,
      })

      return
    }

    const { numeroDeParcelas, periodo } = extrairParcelasEPeriodo(
      formaPagamento?.parcelamento_CondPgto,
    )

    let valorPagamento = 0
    const totalNF = nf?.totalNF || 0
    const pagamentoTroco = nf?.pagamentoTroco || 0
    const pagamentoTotal = nf?.pagamentoTotal || 0

    if (totalNF > 0 && pagamentoTroco <= 0) {
      valorPagamento = totalNF
    }

    if (pagamentoTotal > 0 && pagamentoTroco <= 0) {
      valorPagamento = totalNF - pagamentoTotal
    }

    setData({
      ...data,
      valorPagamento,
      formaPagamento: formaPagamento,
      formaPagamentoId: formaPagamento?.id || '',
      nfId: nf?.id || '',
      parcelamento_CondPgto: formaPagamento?.parcelamento_CondPgto || '',
      numeroDeParcelas,
      periodo,
    })
  }, [formaPagamento, nf, nfIds])

  function calcParcelamentoCondPgto(numeroDeParcelas: number, periodo: number) {
    if (numeroDeParcelas > 0 && periodo > 0) {
      const newParcelamentoCondPgto = Array.from(
        { length: numeroDeParcelas },
        (_, index) => (index + 1) * periodo,
      )

      const resultado = newParcelamentoCondPgto.join('/')
      changeValue('parcelamento_CondPgto', resultado)
    }
  }

  async function handleRemoverNFPagamento() {
    await removerNFPagamento(data)
    navigate(`/NF/${nf?.id || ''}/detalhamento-nf`)
    closeDialog()
  }

  const isMeioPagamentoCartao =
    data?.formaPagamento?.meioPagamento === MeioPagamentoEnum.CARTAO_CREDITO ||
    data?.formaPagamento?.meioPagamento === MeioPagamentoEnum.CARTAO_DEBITO

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { context: { isMeioPagamentoCartao }, abortEarly: false })
      .then(async () => {
        await createOrUpdateNFPagamento({ nfId: nf?.id || '', item: data })
        navigate(`/NF/${nf?.id || ''}/detalhamento-nf`)
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const dataFormatada = useMemo(() => {
    return {
      pagamentoTotalFormatado: formatCurrency(data.valorPagamento),
      totalNFFormatado: formatCurrency(nf?.totalNF || 0),
      pagamentoTrocoFormatado: formatCurrency(nf?.pagamentoTroco || 0),
      descontoFormatado: formatCurrency(nf?.totalDesconto || 0),
    }
  }, [data])

  const existePagamentoNF = (nf?.nfPagamentos?.length || 0) > 0
  const existePagamento =
    data?.formaPagamento?.meioPagamento !== MeioPagamentoEnum.SEM_PAGAMENTO

  return (
    <Box sx={{ marginBottom: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            label="Forma de Pagamento"
            value={data?.formaPagamento?.nome || ''}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CurrencyTextField
            required
            name="valorPagamento"
            label="Valor pagamento"
            value={data.valorPagamento}
            onChange={(_, value) => {
              const parsedValorPagamento = parseFloat(value)
              changeValue('valorPagamento', parsedValorPagamento)
            }}
            {...validationProps('valorPagamento')}
          />
        </Grid>
        {existePagamento && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                label="Parcelas"
                value={data?.numeroDeParcelas || ''}
                inputProps={{
                  inputMode: 'numeric',
                }}
                onChange={(e) => {
                  const parcedNumeroDeParcelas = parseInt(e.target.value)
                  const periodo = data.periodo || 0

                  calcParcelamentoCondPgto(parcedNumeroDeParcelas, periodo)
                  changeValue('numeroDeParcelas', parcedNumeroDeParcelas)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                label="Periodicidade"
                value={data?.periodo || ''}
                inputProps={{
                  inputMode: 'numeric',
                }}
                onChange={(e) => {
                  const parsedPeriodo = parseInt(e.target.value)
                  const numeroDeParcelas = data.numeroDeParcelas || 0

                  calcParcelamentoCondPgto(numeroDeParcelas, parsedPeriodo)
                  changeValue('periodo', parsedPeriodo)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                label="Parcelas/Periodicidade"
                value={data.parcelamento_CondPgto}
                onChange={(e) => {
                  const value = e.target.value

                  const { numeroDeParcelas, periodo } =
                    extrairParcelasEPeriodo(value)

                  setData((prevState) => ({
                    ...prevState,
                    parcelamento_CondPgto: value,
                    numeroDeParcelas,
                    periodo,
                  }))
                }}
              />
            </Grid>

            {isMeioPagamentoCartao && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <DropDownCartaoBandeira
                    value={data?.cartaoBandeira || null}
                    required
                    onChange={(e) => {
                      const cartaoBandeira = parseInt(e.target.value)
                      changeValue('cartaoBandeira', cartaoBandeira || null)
                    }}
                    {...validationProps('cartaoBandeira')}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    label="Número de Autorização"
                    value={data?.numeroAutorizacao || ''}
                    onChange={(e) => {
                      const numeroAutorizacao = e.target.value
                      changeValue('numeroAutorizacao', numeroAutorizacao)
                    }}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      <CardParcelas
        valorPagamento={data.valorPagamento}
        numeroDeParcelas={data?.numeroDeParcelas || 0}
        parcelamentoCondPgto={data?.parcelamento_CondPgto || ''}
      />

      <FloatFooter>
        <Stack width="100%" gap={2}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FloatFooterCurrency
                title="Total"
                price={dataFormatada.totalNFFormatado}
              />
              <FloatFooterCurrency
                title="Total pagamento"
                price={dataFormatada.pagamentoTotalFormatado}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {(nf?.totalDesconto || 0) > 0 && (
                <FloatFooterCurrency
                  title="Desconto"
                  price={dataFormatada.descontoFormatado}
                />
              )}

              {(nf?.pagamentoTroco || 0) > 0 && (
                <FloatFooterCurrency
                  title="Troco"
                  price={dataFormatada.pagamentoTrocoFormatado}
                />
              )}
            </Box>
          </Box>

          <Stack gap={1}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              isLoading={isSubmiting}
            >
              {data?.id ? 'Alterar' : 'Adicionar'}
            </Button>

            {existePagamentoNF && (
              <Button
                fullWidth
                onClick={() => {
                  navigate(`/NF/${nf?.id || ''}/detalhamento-nf`)
                  setNFIds('nfPagamentoId', '')
                }}
              >
                Detalhes da venda
              </Button>
            )}

            {data?.id && (
              <Button color="error" fullWidth onClick={openDialog}>
                Excluir
              </Button>
            )}
          </Stack>
        </Stack>
      </FloatFooter>

      <DeleteDrawer
        isOpen={isOpenDialog}
        isLoading={isDeletingNFPagamento}
        onClose={closeDialog}
        onConfirm={handleRemoverNFPagamento}
      />
    </Box>
  )
}
