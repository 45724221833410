import { Stack, Typography } from '@mui/material'

export default function Header() {
  return (
    <Stack component="header" sx={{ alignItems: 'center', gap: 1 }}>
      <Typography
        component="h1"
        variant="h3"
        sx={{
          fontWeight: 'medium',
          color: (theme) => theme.palette.grey[600],
          textAlign: 'center',
        }}
      >
        Emissor de Documentos Fiscais Eletrônicos
      </Typography>
    </Stack>
  )
}
