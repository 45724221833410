import { useNavigate } from 'react-router-dom'

import {
  ChangeCircleOutlined as ChangeCircleOutlinedIcon,
  LockReset as LockResetIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material'

import { useDialog } from '@data-c/hooks'

import useCredentials from 'hooks/useCredentials'
import DilemmaDrawer from 'components/DilemmaDrawer'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import SimpleListItem from 'components/SimpleList/components/SimpleListItem'
import SimpleList from 'components/SimpleList'
import PageTitle from 'components/PageTitle'

import Header from './components/Header'

export default function Perfil() {
  const navigate = useNavigate()
  const { userLogged, logout } = useCredentials()

  function handleLogout() {
    logout()
  }

  const { isOpen, openDialog, closeDialog } = useDialog()

  function handleBack() {
    navigate('/vendas')
  }

  function handleNavigateToSelecaoLicenca() {
    navigate('selecao-licenca')
  }

  function handleNavigateToAlterarSenha() {
    navigate('alterar-senha')
  }

  return (
    <Container paper>
      <PageTitle title="Perfil" onBack={handleBack} />

      <ContentContainer
        disablePadding
        customSx={{
          paddingBottom: 3,
          height: 'calc(100% - 64px)',
          justifyContent: 'space-between',
        }}
      >
        <Header
          userName={userLogged?.name}
          empresaName={userLogged?.empresa?.nome}
        />

        <SimpleList>
          <SimpleListItem
            label="Mudar de Licença"
            startIcon={<ChangeCircleOutlinedIcon fontSize="medium" />}
            onClick={handleNavigateToSelecaoLicenca}
          />
          <SimpleListItem
            label="Alterar senha"
            startIcon={<LockResetIcon fontSize="medium" />}
            onClick={handleNavigateToAlterarSenha}
          />
          <SimpleListItem
            label="Sair da conta"
            startIcon={<LogoutIcon fontSize="medium" />}
            onClick={openDialog}
          />
        </SimpleList>
      </ContentContainer>

      <DilemmaDrawer
        description="Deseja realmente sair da sua conta?"
        confirmLabel="Sim, sair"
        isOpen={isOpen}
        onClose={closeDialog}
        onConfirm={handleLogout}
      />
    </Container>
  )
}
