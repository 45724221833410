import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'
import { useNFContext } from 'contexts/NFContext/NFContext'
import useOperacao from 'hooks/queries/useOperacao'
import Container from 'components/Container'

import PageTitle from 'components/PageTitle'
import ContentContainer from 'components/Container/components/ContentContainer'

import ListItens from './components/ListItens'

export default function Operacao() {
  const navigate = useNavigate()

  const { setNFIds } = useNFContext()
  const { useQueryObterOperacao } = useOperacao()
  const { data: operacoes, isFetching, error } = useQueryObterOperacao('')

  function handleClick(operacaoId: string) {
    setNFIds('operacaoId', operacaoId)
    navigate('/NF/clientes')
  }

  const operacoesAtivas = useMemo(() => {
    return operacoes?.data?.filter((operacao) => operacao.ativo === true)
  }, [operacoes])

  return (
    <Container paper>
      <PageTitle
        title="Operação"
        onBack={() => navigate('/NF/empresa')}
        onClose={() => navigate('/vendas')}
      />

      <ContentContainer disablePadding isLoading={isFetching} error={error}>
        <Typography
          variant="h1"
          fontSize="32px"
          fontWeight="medium"
          padding={2}
          marginBottom={2}
        >
          Selecione a operação desejada
        </Typography>

        <ListItens
          itens={operacoesAtivas || []}
          onClick={(operacaoId) => handleClick(operacaoId)}
        />
      </ContentContainer>
    </Container>
  )
}
