import { useState } from 'react'
import { Collapse, Grid, Stack, useTheme } from '@mui/material'

import Button from 'components/Button'
import CardInfo from 'components/CardInfo'

import { CardBoletoModel } from '../..'

export default function ContentInfoComponent(data: CardBoletoModel) {
  const {
    convenioBancoCodigoDesc,
    carteiraCodigo,
    nossoNumero,
    nossoNumeroSequencial,
    documentoSistema,
    dataEmissaoFormatada,
    dataPagamentoFormatada,
    pagadorNome,
    pagadorCNPJCPF,
    pagadorCidadeNome,
    pagadorUF,
    valorAbatimentoFormatado,
    valorAtualFormatado,
    multaValorFormatado,
    descontoValorFormatado,
    valorPagoFormatado,
    valorTarifaFormatado,
    dataTarifaFormatada,
    dataCancelamentoFormatada,
    dataRegistroFormatada,
    dataMovimentoFormatada,
    dataCreditoFormatada,
    origemDesc,
    dataAntecipacaoFormatada,
    dataAntecipacaoCancelamentoFormatada,
  } = data

  const theme = useTheme()
  const [isCollapsed, setCollapse] = useState(false)

  return (
    <Stack
      sx={{
        paddingX: 1,
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        cursor: 'default',
      }}
    >
      <Grid container>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CardInfo title="Banco" subtitle={convenioBancoCodigoDesc || '---'} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CardInfo title="Carteira" subtitle={carteiraCodigo || '---'} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CardInfo title="Nosso Número" subtitle={nossoNumero || '---'} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CardInfo
            title="Sequencial"
            subtitle={nossoNumeroSequencial || '---'}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CardInfo title="Documento" subtitle={documentoSistema || '---'} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CardInfo title="Emissão" subtitle={dataEmissaoFormatada || '---'} />
        </Grid>
      </Grid>

      <Collapse in={isCollapsed}>
        {isCollapsed && (
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo
                title="Data do Pagamento"
                subtitle={dataPagamentoFormatada || '---'}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo title="Pagador" subtitle={pagadorNome || '---'} />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo
                title="CNPJ do Pagador"
                subtitle={pagadorCNPJCPF || '---'}
              />
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <CardInfo
                title="Cidade do Pagador"
                subtitle={pagadorCidadeNome || '---'}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <CardInfo title="UF" subtitle={pagadorUF || '---'} />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Valor Abatimento"
                subtitle={valorAbatimentoFormatado || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Valor Atual"
                subtitle={valorAtualFormatado || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Valor Multa"
                subtitle={multaValorFormatado || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Valor Desconto"
                subtitle={descontoValorFormatado || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Valor Pago"
                subtitle={valorPagoFormatado || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Valor Tarifa"
                subtitle={valorTarifaFormatado || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Data da Tarifa"
                subtitle={dataTarifaFormatada || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Data de Cancelamento"
                subtitle={dataCancelamentoFormatada || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Data de Registro"
                subtitle={dataRegistroFormatada || '---'}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardInfo
                title="Data de Movimento"
                subtitle={dataMovimentoFormatada || '---'}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo
                title="Data de Crédito"
                subtitle={dataCreditoFormatada || '---'}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo title="Origem" subtitle={origemDesc || '---'} />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo
                title="Data de Antecipação"
                subtitle={dataAntecipacaoFormatada || '---'}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CardInfo
                title="Data de Antecipação Cancelamento"
                subtitle={dataAntecipacaoCancelamentoFormatada || '---'}
              />
            </Grid>
          </Grid>
        )}
      </Collapse>

      <Stack padding={3} textAlign="center">
        <Button variant="text" onClick={() => setCollapse(!isCollapsed)}>
          {isCollapsed ? 'Ver menos' : 'Ver mais'}
        </Button>
      </Stack>
    </Stack>
  )
}
