import { QueryClientProvider } from '@tanstack/react-query'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { ToastContainer } from 'react-toastify'
import { theme } from '@data-c/ui'
// import projectTheme from 'styles/theme'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'moment/locale/pt-br'
import NFProvider from 'contexts/NFContext/NFContext'

import queryClient from './services/query-client'

import AppRoutes from './routes'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <NFProvider>
            <AppRoutes />
          </NFProvider>
        </LocalizationProvider>
        {/* <ReactQueryDevtools initialIsOpen={false}  /> */}
      </QueryClientProvider>
      <ToastContainer
        position="bottom-right"
        style={{ pointerEvents: 'none' }}
        autoClose={2000}
        newestOnTop={false}
      />
    </ThemeProvider>
  )
}

export default App
