import { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { formatCurrency } from '@data-c/hooks'

import CardInfo from 'components/CardInfo'

import { NFModel } from 'hooks/queries/useNF'
import useDateFormat from 'hooks/queries/useDateFormat'
import useCapitalize from 'hooks/useCapitalize'

import CardFormasPagamento from 'pages/NF/DetalhamentoNF/components/CardFormasPagamento'
import CardOutrasInformacoes from './components/CardOutrasInformacoes'

interface InfoContentProps {
  nf?: NFModel
}

export default function InfoContent(props: InfoContentProps) {
  const { nf } = props

  const { capitalizeFirstLetter } = useCapitalize()
  const { formatDateToDiaMesAnoHoraMinuto } = useDateFormat()

  const dataFormatada = useMemo(() => {
    const endereco = `${nf?.pessoa?.logradouro || ''}, ${
      nf?.pessoa?.cidadeNome || ''
    } - ${nf?.pessoa?.cidadeUF || ''}`

    return {
      ...nf,
      operacaoNome: nf?.operacao?.nome || '',
      clienteNome: nf?.pessoa?.nome || '',
      totalNFFormatado: formatCurrency(nf?.totalNF || 0),
      pagamentoTotalFormatado: formatCurrency(nf?.pagamentoTotal || 0),
      pagamentoTrocoFormatado: formatCurrency(nf?.pagamentoTroco || 0),
      documentoIdFormatado: formatToCPFOrCNPJ(nf?.pessoa?.documentoId || ''),
      enderecoFormatado: capitalizeFirstLetter(endereco),
      descontoFormatado: formatCurrency(nf?.totalDesconto || 0),
      dtSaidaEntradaFormatada: formatDateToDiaMesAnoHoraMinuto(
        nf?.dtSaidaEntrada || '',
      ),
    }
  }, [nf])

  return (
    <Stack gap={1}>
      <CardInfo
        subtitle="Data de Saída"
        contentText={dataFormatada?.dtSaidaEntradaFormatada}
      />
      <CardInfo title="Operação" subtitle={dataFormatada.operacaoNome} />
      <CardInfo title="Cliente" subtitle={dataFormatada.clienteNome}>
        <Typography variant="body2">
          {dataFormatada.documentoIdFormatado}
        </Typography>
        <Typography variant="body2">
          {dataFormatada.enderecoFormatado}
        </Typography>
      </CardInfo>
      <CardInfo title="Formas de Pagamento" subtitle="">
        <CardFormasPagamento
          nfPagamentos={nf?.nfPagamentos || []}
          onClick={() => {}}
        />
      </CardInfo>

      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <CardInfo
          title="Valor total"
          subtitle={`R$ ${dataFormatada.totalNFFormatado}`}
        />
        <CardInfo
          textAlign="end"
          title="Total pagamento"
          subtitle={`R$ ${dataFormatada.pagamentoTotalFormatado}`}
        />
        <CardInfo
          title="Desconto"
          subtitle={`R$ ${dataFormatada.descontoFormatado}`}
        />

        <CardInfo
          textAlign="end"
          title="Troco"
          subtitle={`R$ ${dataFormatada.pagamentoTrocoFormatado}`}
        />
      </Box>

      <CardOutrasInformacoes
        informacoesAdicionais={nf?.informacoesAdicionais}
        informacoesComplementares={nf?.informacoesComplementares}
        nfReferenciados={nf?.nfReferenciados || []}
      />
    </Stack>
  )
}
