import { ReactNode } from 'react'
import { Paper } from '@mui/material'

interface IframeContainerProps {
  children: ReactNode
}

export default function IframeContainer({ children }: IframeContainerProps) {
  return (
    <Paper
      sx={{
        // height: 'calc(100vh - 70px)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {children}
    </Paper>
  )
}
