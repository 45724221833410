import { useFormApi, useFormData } from '@data-c/providers'
import DeleteDrawer from 'components/DeleteDrawer'

interface DeleteProps {
  onDelete: (v: any) => Promise<void>
  onClose?: () => void
  closeFormAfterDelete?: boolean
}

export default function Delete(props: DeleteProps) {
  const { closeFormAfterDelete = false, onDelete, onClose } = props
  const { toggleSubmit, closeConfirm, closeForm } = useFormApi()
  const { formValues, isSubmitting, isOpenConfirm } = useFormData()

  async function handleDelete() {
    toggleSubmit(true)
    try {
      await onDelete(formValues)
      closeConfirm()
      onClose && onClose()
      if (closeFormAfterDelete) {
        closeForm()
      }
    } finally {
      toggleSubmit(false)
    }
  }

  return (
    <DeleteDrawer
      isOpen={isOpenConfirm}
      isLoading={isSubmitting}
      onConfirm={() => handleDelete()}
      onClose={closeConfirm}
    />
  )
}
