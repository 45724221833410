import { useState } from 'react'

export default function useDownload() {
  const [downloadManager, setDownloadManager] = useState({
    error: null,
    loadedd: 0,
    total: 0,
    progress: 0,
    isDownloading: false,
  })

  function start() {
    setDownloadManager((oldState) => ({
      ...oldState,
      isDownloading: true,
      progress: 0,
    }))
  }

  function stop() {
    setDownloadManager((oldState) => ({
      ...oldState,
      isDownloading: false,
      progress: 100,
    }))
  }

  function updateProgress(loaded: number, total: number) {
    const progress = (loaded / total) * 100

    setDownloadManager((oldState) => ({
      ...oldState,
      loaded,
      total,
      progress,
    }))
  }

  function gerarNomeUnico(nomes: string[]) {
    const nome = nomes.reduce((acumulador: string, value, index) => {
      if (index === 0) {
        return value
      }

      return `${acumulador}_${value}`
    }, '')

    return nome
  }

  function blobArquivo(arquivo: string, type: 'text/xml' | 'application/pdf') {
    const file = new Blob([arquivo], { type: type })
    const fileURL = URL.createObjectURL(file)

    return fileURL
  }

  function downloadArquivo(
    arquivo: string,
    nomeArquivo: string | string[],
    extencao: 'pdf' | 'xml',
  ) {
    let nome = nomeArquivo

    if (Array.isArray(nomeArquivo)) {
      const nomeUnico = gerarNomeUnico(nomeArquivo)
      nome = nomeUnico
    }

    const blobType = extencao === 'pdf' ? 'application/pdf' : 'text/xml'
    const fileURL = blobArquivo(arquivo, blobType)

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', `${nome}.${extencao}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    downloadArquivo,
    blobArquivo,
    gerarNomeUnico,
    updateProgress,
    start,
    stop,
    progress: downloadManager.progress,
    total: downloadManager.total,
    isDownloading: downloadManager.isDownloading,
  }
}
