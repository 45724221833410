import { useLocation, useNavigate } from 'react-router-dom'

import { TypeNFModelo } from 'components/Inputs/DropDownNFModelo'
import PDFViewer from 'components/PDFViewer'

import { useNFContext } from 'contexts/NFContext/NFContext'

export default function VisualizarDanfe() {
  const { state } = useLocation()
  const navigate = useNavigate()

  const { nfIds } = useNFContext()
  let nfModelo: TypeNFModelo = state?.nfModelo

  return (
    <PDFViewer
      chaveId={nfModelo}
      pdfPath="NFConsulta/ObterPDF"
      pdfAxiosRequestConfig={{ params: { nfId: nfIds?.nfId } }}
      onBack={() => navigate(-1)}
    />
  )
}
