import { Checkbox, CheckboxProps as UICheckboxProps } from '@data-c/ui'
import { Box, useTheme } from '@mui/material'

interface CheckBoxProps extends UICheckboxProps {}

export default function CheckBox(props: CheckBoxProps) {
  const { checked } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `solid 1px ${
          checked ? theme.palette.primary.main : theme.palette.grey[400]
        }`,
        paddingX: 2,
        paddingY: 1,
        borderRadius: '4px',
        backgroundColor: checked ? 'rgba(89, 195, 224, 0.15)' : '',
      }}
    >
      <Checkbox name="ativo" {...props} />
    </Box>
  )
}
