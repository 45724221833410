import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum NFModeloEnum {
  NFE_55 = 55,
  NFCE_65 = 65,
  TODOS = -1,
}

export type TypeNFModelo = 'DANFE' | 'DANFCE'

export const danfDanfceMapping: Record<string | number, string> = {
  55: 'DANFE',
  65: 'DANFCE',
}

export default function DropDownNFModelo(props: TextFieldProps) {
  return (
    <TextField select {...props} label="Modelo">
      <MenuItem value={-1}>Todos</MenuItem>
      <MenuItem value={NFModeloEnum.NFE_55}>NF-e</MenuItem>
      <MenuItem value={NFModeloEnum.NFCE_65}>NFC-e</MenuItem>
    </TextField>
  )
}
