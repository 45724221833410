import {
  DoneAll as DoneAllIcon,
  Download as DownloadIcon,
} from '@mui/icons-material'
import { FormProvider } from '@data-c/providers'

import {
  OptionListButton,
  OptionsListContainer,
  ActionOptionsProvider,
} from 'components/OptionsListContainer'
import { useSelectableCardContext } from 'contexts/SelectableCardContext'
import { BoletoDetalhadoModel } from 'hooks/queries/useBoleto'
import BoletoImpressao from './components/BoletoImpressao'

interface ActionsProps {
  boletos: BoletoDetalhadoModel[]
}

export enum TipoOrigem {
  BAIXAR_BOLETOS = 'BAIXAR_BOLETOS',
  SELECIONAR_TODOS = 'SELECIONAR_TODOS',
}

export default function Actions(props: ActionsProps) {
  const { boletos } = props

  const { onSelecionarTodosItens } =
    useSelectableCardContext<BoletoDetalhadoModel>()

  function handleSelecionarTodosBoletosRegistrados() {
    const filtered = boletos.filter((boleto) => Boolean(boleto?.dataRegistro))
    onSelecionarTodosItens(filtered, 'Nenhum boleto registrado foi encontrado.')
  }

  return (
    <ActionOptionsProvider>
      <OptionsListContainer triggerLabel="Opções">
        <OptionListButton
          name={TipoOrigem.SELECIONAR_TODOS}
          label="Selecionar todos com registro"
          icon={<DoneAllIcon />}
          onClick={handleSelecionarTodosBoletosRegistrados}
        />
        <OptionListButton
          name={TipoOrigem.BAIXAR_BOLETOS}
          label="Baixar boletos"
          icon={<DownloadIcon />}
        />
      </OptionsListContainer>

      <FormProvider>
        <BoletoImpressao name={TipoOrigem.BAIXAR_BOLETOS} />
      </FormProvider>
    </ActionOptionsProvider>
  )
}
