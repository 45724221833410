export default function extrairParcelasEPeriodo(
  parcelamento_CondPgto?: string,
) {
  if (!parcelamento_CondPgto)
    return {
      numeroDeParcelas: 0,
      periodo: 0,
    }

  const valores = parcelamento_CondPgto.split('/').map(Number)
  const numeroDeParcelas = valores.length
  const periodo = valores[0]

  return {
    numeroDeParcelas,
    periodo,
  }
}
