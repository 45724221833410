import { ReactElement } from 'react'
import { Box, TextField, TextFieldProps } from '@mui/material'
import ActionButton from './components/ActionButton'

interface TextFieldWithActionProps<T> {
  value: T
  id?: string
  startIcon?: ReactElement
  actionIcon: ReactElement
  disabled?: boolean
  inputProps?: TextFieldProps
  onChange: (value: string, id?: string) => void
  onClick?: () => void
}

export default function TextFieldWithAction<T>(
  props: TextFieldWithActionProps<T>,
) {
  const {
    value,
    id,
    startIcon,
    actionIcon,
    disabled = false,
    inputProps,
    onChange,
    onClick,
  } = props

  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value, id)}
      InputProps={{
        startAdornment: (
          <Box
            sx={{
              width: '42px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            {startIcon}
          </Box>
        ),
        endAdornment: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              border: 'none',
            }}
          >
            <ActionButton
              color="error"
              disabled={disabled}
              icon={actionIcon}
              onClick={onClick}
            />
          </Box>
        ),
      }}
      {...inputProps}
    />
  )
}
