export default function useSort() {
  function ordenarDataPorMaisRecente<T>(data: T[], fieldName: keyof T) {
    const dataOrdenada = data.sort(
      (a, b) =>
        new Date(b[fieldName] as any).getTime() -
        new Date(a[fieldName] as any).getTime(),
    )

    return dataOrdenada
  }

  return {
    ordenarDataPorMaisRecente,
  }
}
