import { useNavigate } from 'react-router-dom'
import { Button } from '@data-c/ui'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useNF, { nfModeloMapping } from 'hooks/queries/useNF'
import useDownload from 'hooks/useDownload'

import XmlContentViewer from './components/XmlContentViewer'
import XmlContainer from './components/XmlContainer'
import XMLHeader from './components/XmlHeader'

export { default as XmlContentViewer } from './components/XmlContentViewer'
export { default as XmlContainer } from './components/XmlContainer'
export { default as XmlHeader } from './components/XmlHeader'

export default function XMLViewer() {
  const navigate = useNavigate()
  const { downloadArquivo } = useDownload()

  const { nfIds } = useNFContext()
  const { useQueryObterNFPorId } = useNF()
  const { data: nf, isLoading, error } = useQueryObterNFPorId(nfIds.nfId)

  function handleDownloadXML() {
    if (nf?.id) {
      const chaveAcesso = nf?.nFe_ChaveAcesso || ''
      const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]

      downloadArquivo(nf?.nFeXML || '', [chaveAcesso, nfModeloDescricao], 'xml')
    }
  }

  return (
    <XmlContainer>
      <XMLHeader title="XML" onBack={() => navigate(-1)}>
        <Button
          variant="contained"
          onClick={handleDownloadXML}
          sx={{ width: '140px' }}
        >
          Baixar XML
        </Button>
      </XMLHeader>

      <XmlContentViewer
        arquivoXML={nf?.nFeXML || ''}
        isLoading={isLoading}
        error={error}
      />
    </XmlContainer>
  )
}
