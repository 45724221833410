import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import { isValidNumber, useDialog, useForm } from '@data-c/hooks'

import { DropDownNFeFinalidadeEnum } from 'components/Inputs/DropDownNFeFinalidade'
import ContentContainer from 'components/Container/components/ContentContainer'
import CurrencyTextField from 'components/CurrencyTextField'
import DilemmaDrawer from 'components/DilemmaDrawer'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'

import useCalculos, { TipoCalculoTypes } from 'hooks/useCalculos'
import useProduto from 'hooks/queries/useProduto'
import useValidations from 'hooks/useValidation'
import useNF from 'hooks/queries/useNF'
import useAtualizarValoresItemPedido from 'hooks/queries/business/useAtualizarValoresItemPedido'

import {
  ItemProps,
  defaltItemData,
} from 'pages/NF/Produtos/components/ProdutoCardInfo'

import DescricaoProduto from './components/DescricaoProduto'
import Actions from './components/Actions'
import Footer from './components/Footer'

import * as yup from 'yup'

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  quantidade: yup
    .number()
    .nullable(true)
    .when('$isNFeFinalidadeComplementar', {
      is: false,
      then: yup
        .number()
        .nullable(true)
        .required('Informe a quantidade')
        .moreThan(0, 'A Quantidade deve ser superior a zero(0)')
        .transform(transform),
    }),
  unitario: yup
    .number()
    .nullable(true)
    .when('$isNFeFinalidadeComplementar', {
      is: false,
      then: yup
        .number()
        .nullable(true)
        .required('Informe o Preço de Venda')
        .moreThan(0, 'O Preço de Venda deve ser maior do que R$0,00')
        .transform(transform),
    }),
  unitarioBruto: yup
    .number()
    .nullable(true)
    .when('$isNFeFinalidadeComplementar', {
      is: false,
      then: yup
        .number()
        .nullable(true)
        .required('Informe o Preço de Venda')
        .moreThan(0, 'O Preço de Venda deve ser maior do que R$0,00')
        .transform(transform),
    }),
  totalBruto: yup
    .number()
    .nullable(true)
    .required('Informe o Total Bruto')
    .moreThan(0, 'O Total Bruto deve ser maior do que R$0,00')
    .transform(transform),
})

export default function Produto() {
  const { nfId, produtoId } = useParams()
  const { calcularItemPedido } = useCalculos()
  const { atualizarValoresItemPedido } = useAtualizarValoresItemPedido()
  const { setValidationErrors, validationProps } = useValidations()
  const { isOpen, openDialog, closeDialog } = useDialog()

  const {
    useQueryObterNFPorId,
    useCreateNFItem,
    useUpdateNFItem,
    useRemoverNFItem,
  } = useNF()
  const { useQueryObterProdutoPorId } = useProduto()
  const { mutateAsync: createNFItem, isLoading: isCreating } = useCreateNFItem()
  const { mutateAsync: updateNFItem, isLoading: isUpdating } = useUpdateNFItem()
  const { mutateAsync: removerNFItem, isLoading: isRemoving } =
    useRemoverNFItem()
  const {
    data: produto,
    isLoading: isLoadingProduto,
    error: errorProduto,
  } = useQueryObterProdutoPorId(produtoId || '')
  const {
    data: nf,
    isLoading: isLoadingNF,
    error: errorNF,
  } = useQueryObterNFPorId(nfId || '')

  const [tipoCalculo, setTipoCalculo] = useState<TipoCalculoTypes>()
  const [dataImutavel, setDataImutavel] = useState<ItemProps>(defaltItemData)
  const { data, setData, changeValue } = useForm<ItemProps>(defaltItemData)

  useEffect(() => {
    if (produto && nf) {
      const produtoAtualizado = atualizarValoresItemPedido(
        produto,
        nf,
        'paginaDoProduto',
      )

      setData(produtoAtualizado)
      setDataImutavel(produtoAtualizado)
    }
  }, [produto, nf])

  const navigate = useNavigate()
  const itemCalculado = calcularItemPedido(data, tipoCalculo)

  function handleBack() {
    const houveMudanca =
      JSON.stringify(itemCalculado) !== JSON.stringify(dataImutavel)

    if (houveMudanca) {
      openDialog()
      return
    }

    handleNavigateProdutos()
  }

  async function handleRemoverNFItem() {
    if (itemCalculado?.nfItemId) {
      const nfId = itemCalculado?.nf?.id || ''
      const nfItemId = itemCalculado.nfItemId

      await removerNFItem({ nfId, nfItemId })
      handleNavigateProdutos()
    }
  }

  function handleNavigateProdutos() {
    navigate(`/NF/${nf?.id || ''}/produtos`)
  }

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(itemCalculado, {
        context: { isNFeFinalidadeComplementar },
        abortEarly: false,
      })
      .then(async () => {
        const {
          produtoId,
          quantidade,
          totalBruto,
          pDesconto,
          unitario,
          unitarioBruto,
          total,
          desconto,
        } = itemCalculado

        if (itemCalculado?.nfItemId) {
          const findedItem = itemCalculado?.nf?.nfItems?.find(
            (nfItem) => nfItem.id === itemCalculado?.nfItemId,
          )

          if (findedItem) {
            await updateNFItem({
              item: {
                ...findedItem,
                quantidade,
                totalBruto,
                pDesconto,
                total,
                unitario,
                desconto,
                unitarioBruto,
              },
              nfId: itemCalculado?.nf?.id || '',
            })
            setDataImutavel(itemCalculado)
            handleNavigateProdutos()
          }
          return
        }

        await createNFItem({
          item: {
            produtoId,
            quantidade,
            unitario,
            unitarioBruto,
            total,
            totalBruto,
            desconto,
            pDesconto,
          },
          nfId: itemCalculado?.nf?.id || '',
        })
        setDataImutavel(itemCalculado)
        handleNavigateProdutos()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const isLoading = isLoadingProduto || isLoadingNF
  const error = errorProduto || errorNF
  const isCreatingOrUpdating = isCreating || isUpdating

  const isNFeFinalidadeComplementar =
    nf?.operacao?.nFe_Finalidade === DropDownNFeFinalidadeEnum.COMPLEMENTAR_2

  return (
    <Container paper customSx={{ marginBottom: '117px' }}>
      <PageTitle title="Produto" onBack={handleBack} />
      <ContentContainer
        isLoading={isLoading}
        error={error}
        customSx={{ justifyContent: 'initial' }}
      >
        <DescricaoProduto
          codigo={itemCalculado?.codigo || ''}
          nome={itemCalculado?.nome || ''}
          estoque={itemCalculado?.estoque || 0}
          unitarioBruto={itemCalculado?.unitarioBruto || 0}
        />

        <Actions
          quantidade={itemCalculado.quantidade || 0}
          unitario={itemCalculado?.unitario || 0}
          onChange={(quantidade) => {
            changeValue('quantidade', quantidade)
            setTipoCalculo(TipoCalculoTypes.QUANTIDADE)
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CurrencyTextField
              name="quantidade"
              label="Quantidade"
              required={!isNFeFinalidadeComplementar}
              value={itemCalculado?.quantidade || ''}
              onChange={(_, value) => {
                changeValue('quantidade', parseFloat(value) || 0)
                setTipoCalculo(TipoCalculoTypes.QUANTIDADE)
              }}
              {...validationProps('quantidade')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CurrencyTextField
              name="pDesconto"
              label="Desconto (%)"
              value={itemCalculado?.pDesconto || ''}
              onChange={(_, value) => {
                const pDesconto = Math.min(parseFloat(value), 100)
                changeValue('pDesconto', pDesconto || null)
                setTipoCalculo(TipoCalculoTypes.P_DESCONTO)
              }}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CurrencyTextField
              name="desconto"
              label="Desconto"
              value={itemCalculado?.desconto || ''}
              onChange={(_, value) => {
                changeValue('desconto', parseFloat(value))
                setTipoCalculo(TipoCalculoTypes.DESCONTO)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CurrencyTextField
              name="unitarioBruto"
              label="Preço"
              required={!isNFeFinalidadeComplementar}
              value={itemCalculado?.unitarioBruto || ''}
              onChange={(_, value) => {
                changeValue('unitarioBruto', parseFloat(value))
                setTipoCalculo(TipoCalculoTypes.UNITARIO_BRUTO)
              }}
              {...validationProps('unitarioBruto')}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CurrencyTextField
              name="totalBruto"
              label="Total Bruto"
              required
              value={itemCalculado?.totalBruto || ''}
              onChange={(_, value) => {
                changeValue('totalBruto', parseFloat(value))
                setTipoCalculo(TipoCalculoTypes.TOTAL_BRUTO)
              }}
              {...validationProps('totalBruto')}
            />
          </Grid>
        </Grid>
      </ContentContainer>

      <Footer
        total={itemCalculado?.total || 0}
        totalBruto={itemCalculado?.totalBruto || 0}
        isLoading={isCreatingOrUpdating}
        isRemoving={isRemoving}
        nfItemId={itemCalculado?.nfItemId}
        onSubmit={handleSubmit}
        onRemove={handleRemoverNFItem}
        pDesconto={itemCalculado?.pDesconto || 0}
        desconto={itemCalculado?.desconto || 0}
      />

      <DilemmaDrawer
        title="Sair sem salvar?"
        isOpen={isOpen}
        confirmLabel="Sair sem salvar"
        description="Você tem alterações não salvas. Ao sair, todas as alterações não salvas
        serão desfeitas!"
        onClose={closeDialog}
        onConfirm={handleNavigateProdutos}
        zIndex={99}
      />
    </Container>
  )
}
