import { Stack, Typography, useTheme } from '@mui/material'

interface HeaderProps {
  userName?: string
}

export default function Header(props: HeaderProps) {
  const { userName } = props
  const theme = useTheme()

  return (
    <Stack padding={2} spacing={1}>
      <Typography
        variant="h1"
        fontSize="32px"
        fontWeight="medium"
        marginBottom={0.5}
      >
        Selecione a licença desejada
      </Typography>

      <Typography
        variant="h5"
        color={theme.palette.grey[500]}
        fontWeight="bold"
      >
        Abaixo estão listadas todas as licenças vinculadas à conta{' '}
        {userName || 'do usuário'}
      </Typography>
    </Stack>
  )
}
