import { useFilterApi } from '@data-c/providers'

import ButtonContainer from 'components/ButtonContainer'
import Button from 'components/Button'

export default function FilterDrawerActions() {
  const { applyFilterValues, closeFilter, deleteAllFilterValues } =
    useFilterApi()

  function handleApplyFilter() {
    applyFilterValues()
    closeFilter()
  }

  function handleResetAllFilters() {
    if (deleteAllFilterValues) {
      deleteAllFilterValues()
      closeFilter()
    }
  }

  return (
    <ButtonContainer flexDirection="row">
      <Button fullWidth onClick={handleResetAllFilters} color="error">
        Limpar Filtro
      </Button>
      <Button fullWidth variant="contained" onClick={handleApplyFilter}>
        Aplicar
      </Button>
    </ButtonContainer>
  )
}
