import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import useEmpresa, { EmpresaModel } from 'hooks/queries/useEmpresa'
import { AutoCompleteProps } from 'hooks/useAutocomplete'

export default function AutoCompleteEmpresas(
  props: AutoCompleteProps<EmpresaModel>,
) {
  const { onChange, value, autoFocus } = props
  const { useQueryObterEmpresas } = useEmpresa()
  const { data, isLoading } = useQueryObterEmpresas()

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={data?.data || []}
      onChange={onChange}
      getOptionLabel={(empresa) => {
        return `${empresa.codigo} - ${empresa.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Empresa"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
