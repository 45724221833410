import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import FloatFooter from 'components/FloatFooter'
import Button from 'components/Button'

interface FooterProps {
  nfId?: string
}

export default function Footer(props: FooterProps) {
  const { nfId } = props

  const navigate = useNavigate()

  function handleBack() {
    navigate(`/NF/${nfId}/nf-transmitida`)
  }

  return (
    <FloatFooter>
      <Stack gap={1.5}>
        <Button
          variant="contained"
          onClick={() => navigate(`/NF/${nfId}/envio-nf-por-email`)}
        >
          Enviar novos emails
        </Button>
        <Button onClick={handleBack}>Voltar</Button>
      </Stack>
    </FloatFooter>
  )
}
