import { Box, Typography, useTheme } from '@mui/material'
import SimpleList from 'components/SimpleList'
import SimpleListItem from 'components/SimpleList/components/SimpleListItem'
import { OperacaoModel } from 'hooks/queries/useOperacao'

interface Operacao {
  id: string
  nome: string
}

interface GrupoDeOperacoes {
  tipoInterno: number
  tipoInternoDesc: string
  operacoes: Operacao[]
}

interface ListItensProps {
  itens: OperacaoModel[]
  onClick: (operacaoId: string) => void
}

export default function ListItens(props: ListItensProps) {
  const { itens, onClick } = props
  const theme = useTheme()

  const grupoDeOperacoes = itens.reduce(
    (acumulador: GrupoDeOperacoes[], objeto) => {
      const tipoInterno = objeto.tipoInterno
      const tipoInternoDesc = objeto.tipoInternoDesc
      const nome = objeto.nome
      const id = objeto.id

      const existente = acumulador.find(
        (item) => item.tipoInterno === tipoInterno,
      )

      if (existente) {
        existente.operacoes.push({ id, nome })
        existente.tipoInternoDesc = tipoInternoDesc
      } else {
        acumulador.push({
          tipoInterno,
          tipoInternoDesc,
          operacoes: [{ id, nome }],
        })
      }

      return acumulador
    },
    [],
  )

  return (
    <SimpleList>
      {grupoDeOperacoes.map((grupo) => {
        return (
          <Box
            key={grupo.tipoInterno}
            sx={{
              marginBottom: 4,
            }}
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ color: theme.palette.grey[500], marginLeft: 2 }}
            >
              {grupo.tipoInternoDesc}
            </Typography>
            {grupo.operacoes.map((operacao) => {
              return (
                <SimpleListItem
                  key={operacao.id}
                  label={operacao.nome}
                  onClick={() => onClick(operacao.id)}
                />
              )
            })}
          </Box>
        )
      })}
    </SimpleList>
  )
}
