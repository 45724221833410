import { Box, Stack, Typography } from '@mui/material'
import emptyCartImage from 'assets/images/empty-cart.svg'

export default function EmptyCart() {
  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 182px)',
        padding: 2,
        backgroundColor: '#fff',
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '82px',
          gap: 3,
        }}
      >
        <img
          src={emptyCartImage}
          width="30%"
          height="30%"
          alt="Imagem de erro"
        />

        <Box sx={{ paddingX: 3 }}>
          <Typography variant="h1" component="h2" fontWeight="medium">
            Você não possui produtos adicionados no momento!
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}
