import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Container, Paper, Stack } from '@mui/material'

import useCredentials, { LoginDataInterface } from 'hooks/useCredentials'

import Header from './components/Header'
import LoginForm from './components/LoginForm'
import EsqueciSenhaForm from './components/EsqueciSenhaForm'
import LicencaForm from './components/LicencaForm'

import appLogo from 'assets/images/logo.png'

export default function Login() {
  const [loginData, setLoginData] = useState<LoginDataInterface>()
  const [exibirEsqueciSenha, setExibirEsqueciSenha] = useState(false)
  const { selecionarLicenca } = useCredentials()
  const navigate = useNavigate()

  async function handleLoginSuccess(_loginData: LoginDataInterface) {
    if (_loginData?.empresas?.length === 1) {
      await selecionarLicenca(_loginData, _loginData.empresas[0].uuid)
      navigate('/vendas')
      return
    }
    setLoginData(_loginData)
  }

  function handleExibirEsqueciSenha() {
    setExibirEsqueciSenha(!exibirEsqueciSenha)
  }

  return (
    <Box sx={{ height: '100vh' }}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <img src={appLogo} alt="Logo App Data C" />
        <Paper>
          <Stack sx={{ padding: 5 }}>
            <Header />
            {!exibirEsqueciSenha && (
              <Box
                sx={{
                  marginTop: 3,
                }}
              >
                {loginData?.empresas && loginData?.empresas?.length > 0 ? (
                  <LicencaForm loginData={loginData} />
                ) : (
                  <LoginForm
                    onLoginSuccess={handleLoginSuccess}
                    onEsqueciSenhaClick={handleExibirEsqueciSenha}
                  />
                )}
              </Box>
            )}

            {exibirEsqueciSenha && (
              <Box sx={{ marginTop: 3 }}>
                <EsqueciSenhaForm onLoginClick={handleExibirEsqueciSenha} />
              </Box>
            )}
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
}
