import { ReactNode, createContext, useContext, useState } from 'react'

interface NFIdsProps {
  operacaoId: string
  empresaId: string
  id: string
  pessoaId: string
  formaPagamentoId: string
  nfPagamentoId: string
  nfId: string
}

interface NFContextData {
  nfIds: NFIdsProps
  mensagemSucesso: boolean
  isLoading: boolean
  setMensagemSucesso: (exibir: boolean) => void
  setNFIds: (idNome: string, novoId: string) => void
  setLoading: (loading: boolean) => void
}

interface NFProviderProps {
  children: ReactNode
}

export const NFContext = createContext({} as NFContextData)

const defaultIds = {
  operacaoId: '',
  empresaId: '',
  id: '',
  pessoaId: '',
  formaPagamentoId: '',
  nfPagamentoId: '',
  nfId: '',
}

export default function NFProvider({ children }: NFProviderProps) {
  const [nfIds, setNFIds] = useState<NFIdsProps>(defaultIds)
  const [mensagemSucesso, setMensagemSucesso] = useState(false)
  const [isLoading, setLoading] = useState(false)

  function exibirMensagemSucesso(exibir: boolean) {
    setMensagemSucesso(exibir)
  }

  function updateNFIds(idNome: string, novoId: string) {
    setNFIds((prevState) => ({
      ...prevState,
      [idNome]: novoId,
    }))
  }

  function handleSetLoading(loading: boolean) {
    setLoading(loading)
  }

  return (
    <NFContext.Provider
      value={{
        nfIds,
        mensagemSucesso,
        isLoading,
        setMensagemSucesso: exibirMensagemSucesso,
        setNFIds: updateNFIds,
        setLoading: handleSetLoading,
      }}
    >
      {children}
    </NFContext.Provider>
  )
}

export const useNFContext = () => useContext(NFContext)
