import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum DropDownTransmissaoEnum {
  TODAS_0 = 0,
  TRANSMITIDAS_1 = 1,
  NAO_TRANSMITIDAS_2 = 2,
}

const { TODAS_0, TRANSMITIDAS_1, NAO_TRANSMITIDAS_2 } = DropDownTransmissaoEnum

export const nfTipoTransmissao: Record<number, string> = {
  [TODAS_0]: 'Todas',
  [TRANSMITIDAS_1]: 'Transmitidas',
  [NAO_TRANSMITIDAS_2]: 'Não transmitidas',
}

export default function DropDownTransmissao(props: TextFieldProps) {
  return (
    <TextField select {...props} label="Transmissão">
      <MenuItem value={TODAS_0.toString()}>Todas</MenuItem>
      <MenuItem value={TRANSMITIDAS_1}>1 - Transmitidas</MenuItem>
      <MenuItem value={NAO_TRANSMITIDAS_2}>2 - Não transmitidas</MenuItem>
    </TextField>
  )
}
