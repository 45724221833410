import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'

export interface OperacaoModel {
  id: string
  tipoInterno: number
  tipoInternoDesc: string
  nome: string
  ativo: boolean
  gerarDataSaidaAuto: boolean
  nFe_Finalidade: number
  finalidadeNFeDesc?: string | null
}

export async function obterOperacao(
  query: string,
): Promise<HttpResponseInterface<OperacaoModel>> {
  const response = await api.get<HttpResponseInterface<OperacaoModel>>(
    `Operacao/Pesquisar/${query}`,
  )

  return response.data
}

export function useQueryObterOperacao(query: string) {
  return useQuery<
    HttpResponseInterface<OperacaoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['OPERACAO', query], () => {
    return obterOperacao(query)
  })
}

export default function useOperacao() {
  return {
    useQueryObterOperacao,
  }
}
