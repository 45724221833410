import jwtDecode from 'jwt-decode'
import useApi from 'hooks/useApi'
import authApi from 'services/auth-api'
import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import credentialsConfigs from 'configs/credentials'

export type TokenPermissoesKeys =
  | 'stella-hubbank'
  | 'stella-cadastro'
  | 'stella-financeiro'
  | 'stella-faturamento'

export interface Empresa {
  configs?: any
  documento: string
  nome: string
  uuid: string
}

export interface LoginDataInterface {
  empresas?: Array<Empresa>
  token: string
  refreshToken?: string
  userId: string
  userName: string
  email?: string
  password?: string
}

export interface AmbienteDataInterface {
  refreshToken?: string
  token: string
}

export interface PermissaoModel {
  name: string
  ativo: boolean
  empresas?: Array<Empresa>
}

export type PermissoesModel = {
  [key in TokenPermissoesKeys]: PermissaoModel
}

export interface DecodedTokenInterface {
  email: string
  name: string
  uuid: string
  permissoes: PermissoesModel
  empresaSelecionada: string
  empresa: Empresa
  empresas?: Array<Empresa>
}

export default function useCredentials() {
  const { post, isSubmitting, put } = useApi({
    errorHandler: 'notification',
    api: authApi,
  })
  const { put: _put } = useApi({
    errorHandler: 'disabled',
    api: authApi,
  })
  const navigate = useNavigate()
  //const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const TOKEN_KEY = credentialsConfigs.tokenKey
  const REFRESH_TOKEN_KEY = credentialsConfigs.refreshTokenKey

  function setToken(token: string, refreshToken?: string) {
    localStorage.setItem(TOKEN_KEY, token)
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    } else {
      localStorage.removeItem(REFRESH_TOKEN_KEY)
    }
  }

  function logout() {
    localStorage.removeItem(TOKEN_KEY)
    navigate('/')
  }

  function getToken() {
    return localStorage.getItem(TOKEN_KEY)
  }

  function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  }

  function decodeToken(): DecodedTokenInterface | null {
    const token = getToken()

    if (!token) return null

    const { data: decodedToken } = jwtDecode<{ data: DecodedTokenInterface }>(
      token,
    )
    const permissoes = decodedToken.permissoes
    const empresasDoFaturamento = permissoes['stella-faturamento']?.empresas

    return { ...decodedToken, empresas: empresasDoFaturamento }
  }

  function isLogged() {
    const token = getToken()
    if (token === null) return false
    try {
      const { exp } = jwtDecode<any>(token)
      const expDate = new Date(exp * 1000).getTime()
      const nowDate = new Date().getTime()
      return nowDate < expDate
    } catch (err) {
      return false
    }
  }

  async function login(
    email: string,
    password: string,
  ): Promise<LoginDataInterface | undefined> {
    const response = await post<LoginDataInterface>(
      'sessions',
      {
        email,
        password,
      },
      {
        headers: {
          'DC-SISTEMA': import.meta.env.VITE_ID,
        },
      },
    )

    const toReturn = response?.data
    if (toReturn) {
      const token = response.data.token
      const { data: decodedToken } = jwtDecode<any>(token)
      const permissoes = decodedToken.permissoes
      const permissao = permissoes['stella-faturamento']
      const uuid = decodedToken.uuid
      const name = decodedToken.name

      toReturn.email = email
      toReturn.password = password
      toReturn.userId = uuid
      toReturn.userName = name
      toReturn.empresas = permissao.empresas
    }

    return toReturn
  }

  async function alterarSenha(
    senhaAtual: string,
    senhaNova: string,
  ): Promise<any> {
    const token = getToken()
    if (token === null) throw new Error('Não foi possível alterar sua senha')

    const response = await put(
      `passwords/change`,
      {
        old: senhaAtual,
        new: senhaNova,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    if (!response) {
      throw new Error('Ocorreu um erro ao tentar alterar sua senha')
    }

    return response?.data
  }

  async function selecionarLicenca(
    loginData?: LoginDataInterface,
    empresaId?: string,
  ): Promise<AmbienteDataInterface | undefined> {
    if (!loginData) {
      throw new Error('As informações de login foram perdidas')
    }

    const response = await post<AmbienteDataInterface>(
      `selecionar-empresa`,
      {
        permissao: 'stella-faturamento',
        empresaUuid: empresaId,
      },
      {
        headers: {
          Authorization: 'Bearer ' + loginData.token,
        },
      },
    )

    if (!response) {
      throw new Error('Ocorreu um erro ao tentar selecionar a licença')
    }

    const { token, refreshToken } = response.data
    setToken(token, refreshToken)
    return response.data
  }

  async function recuperarSenha(
    email: string,
  ): Promise<AxiosResponse | undefined> {
    const response = await _put('/passwords/recover', { email })
    return response
  }

  const isAuthenticated: Boolean = isLogged()
  const userLogged = decodeToken()
  const token = getToken()
  const refreshToken = getRefreshToken()

  return {
    login,
    logout,
    isSubmitting,
    token,
    refreshToken,
    userLogged,
    isAuthenticated,
    selecionarLicenca,
    alterarSenha,
    recuperarSenha,
  }
}
