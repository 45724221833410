import { useNavigate } from 'react-router-dom'

import {
  PaymentsOutlined as PaymentsIcon,
  List as ListIcon,
} from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

import { formatCurrency } from '@data-c/hooks'
import { useNFContext } from 'contexts/NFContext/NFContext'
import { NFModel } from 'hooks/queries/useNF'

import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import FloatFooter from 'components/FloatFooter'
import Button from 'components/Button'
import ButtonContainer from 'components/ButtonContainer'

interface FooterProps {
  nf?: NFModel
}

export default function Footer(props: FooterProps) {
  const { nf } = props

  const navigate = useNavigate()

  const { isLoading } = useNFContext()

  const existeNFPagamento = (nf?.nfPagamentos?.length || 0) > 0
  const itensAdicionados = nf?.nfItems?.length
  const totalNFFormatado = formatCurrency(nf?.totalNF || 0)
  const flexSingularPluralText =
    itensAdicionados && itensAdicionados > 1
      ? ' produtos adicionados'
      : ' produto adicionado'

  function handleNavigateFormaPagamento() {
    if (isLoading) return
    navigate(`/NF/${nf?.id}/forma-de-pagamento`)
  }

  function handleNavigateDetalhamentoNF() {
    if (isLoading) return
    navigate(`/NF/${nf?.id}/detalhamento-nf`)
  }

  return (
    <FloatFooter>
      <Stack width="100%">
        <Typography variant="body1">
          {itensAdicionados}
          {flexSingularPluralText}
        </Typography>

        <FloatFooterCurrency title="Total" price={totalNFFormatado} />

        <ButtonContainer marginTop={1}>
          <Button
            variant={existeNFPagamento ? 'outlined' : 'contained'}
            fullWidth
            startIcon={<PaymentsIcon />}
            disabled={isLoading}
            onClick={handleNavigateFormaPagamento}
          >
            Forma de pagamento
          </Button>

          {existeNFPagamento && (
            <Button
              variant="contained"
              fullWidth
              startIcon={<ListIcon />}
              disabled={isLoading}
              onClick={handleNavigateDetalhamentoNF}
            >
              Detalhes da venda
            </Button>
          )}
        </ButtonContainer>
      </Stack>
    </FloatFooter>
  )
}
