import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FilterOptions } from '@data-c/providers'

import { DropDownTipoInternoOperacaoEnum } from 'components/Inputs/DropDownTipoInternoOperacao'
import { DropDownTransmissaoEnum } from 'components/Inputs/DropDownTransmissao'
import { DropDownNFTipoDataEnum } from 'components/Inputs/DropDownNFTipoData'
import { NFModeloEnum } from 'components/Inputs/DropDownNFModelo'
import { NFStatusEnum } from 'components/Inputs/DropDownStatus'

import useNotification from 'hooks/useNotification'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import api from 'services/api'

import { DocumentoReferenciadoModel } from './useNFOutrasInformacoes'
import { dateEndOfMonth, dateStartOfMonth } from './useDateFormat'
import { FormaPagamentoModel } from './useFormaPagamento'
import { OperacaoModel } from './useOperacao'
import { ProdutoModel } from './useProduto'
import { ClienteModel } from './useCliente'
import { EmpresaModel } from './useEmpresa'

export const nfModeloMapping: Record<number, string> = {
  55: 'NF-e',
  65: 'NFC-e',
  0: 'Nota Fiscal',
  [-1]: 'Todas',
}

export interface NFModel {
  id: string
  empresaId: string
  pessoaId: string
  pessoaNome?: string | null
  pessoaCodigo: number
  totalProduto: number
  operacaoId: string
  statusDesc?: string | null
  dtLancamento: string
  dtSaidaEntrada?: string | null
  nFe_NaturezaOperacao?: string | null
  dtEmissao?: string | null
  totalNF: number
  status: number
  pagamentoTotal?: number | null
  pagamentoTroco?: number | null
  operacao?: OperacaoModel | null
  pessoa?: ClienteModel | null
  nfItems?: NFItemModel[]
  nfPagamentos: NFPagamentosProps[]
  totalDesconto: number
  modeloDesc?: string | null
  modelo: number
  serie: number
  numeroNF?: number | null
  nFeErrosSchema?: NFeErrosSchemaProps[]
  wS_RetornoDesc?: string | null
  wS_EnvioDesc?: string | null
  emailEnviadoDesc?: string | null
  wS_CancelamentoProtocoloDesc?: string | null
  nFe_ChaveAcesso?: string | null
  nFeXML?: string | null
  informacoesComplementares?: string | null
  informacoesComplSistema?: string | null
  informacoesAdicionais?: string | null
  nfReferenciados?: DocumentoReferenciadoModel[]
}

export interface NFPagamentosProps {
  id?: string
  nfId: string
  formaPagamento?: FormaPagamentoModel
  valorPagamento: number
  formaPagamentoId: string
  cartaoBandeira: number | null
  cartaoBandeiraDesc: string
  numeroAutorizacao: string
  parcelamento_CondPgto: string
  numeroDeParcelas?: number
  periodo?: number
}

export interface NFItemModel {
  id?: string
  nfId: string
  quantidade: number
  produtoId: string
  unitarioBruto?: number | null
  produto?: ProdutoModel | null
  totalBruto?: number | null
  total?: number | null
  unitario?: number | null
  pDesconto?: number | null
  desconto?: number | null
}

export interface NFConsultaModel {
  id?: string
  empresaId?: string | null
  destinatarioId?: string | null
  dataInicio: string
  dataFim: string
  status: number
  statusDesc?: string | null
  tipoTransmissao: number
  operacaoInterna: number | string
  operacaoNome?: string | null
  modelo: number
  serie?: number | null
  numeroNF?: number | null
  nfConsultaTipoData: number | string
  dtEmissao?: string | null
  dtLancamento: string
  dtSaidaEntrada?: string | null
  pessoaNome?: string | null
  pessoaId: string
  pessoaCodigo: number
  totalProduto: number
  totalNF: number
  totalICMSST: number
  totalIPI: number
  nFe_ChaveAcesso?: string | null
  nFe_TipoAmbiente: number
  nFe_TipoAmbienteDesc?: string | null
  wS_RetornoCodigo?: string | null
}

export interface NFeErrosSchemaProps {
  name: string
  value: string
  message: string
  parentXml: string
  id: number
  isErro: boolean
}

interface CreateNFItemProps {
  produtoId: string
  quantidade: number
  unitarioBruto?: number | null
  totalBruto?: number | null
  pDesconto?: number | null
  desconto?: number | null
  unitario?: number | null
  total?: number | null
}

export interface AdicionarNFProps {
  operacaoId: string
  empresaId: string
  id: string
  pessoaId: string
}

export interface AlterarIdentificacaoParams {
  modelo?: number
  numeroNF?: number | null
  serie?: number
  dtEmissao?: string | null
  dtSaidaEntrada?: string | null
  naturezaOperacao?: string | null
  chaveAcesso?: string | null
}

export interface CancelarNFProps {
  nfId: string
  justificativa: string
}

export interface EnviarEmailNFParams {
  nfIds: string[]
  emails: string
  relatorioId: string
}

export interface NFConsultaFilters {
  destinatarioId?: FilterOptions<string | null>
  destinatario?: FilterOptions<ClienteModel | null>
  empresaId?: FilterOptions<string | null>
  empresa?: FilterOptions<EmpresaModel | null>
  dataInicio: FilterOptions<string>
  dataFim: FilterOptions<string>
  status: FilterOptions<number | string>
  tipoTransmissao: FilterOptions<number | string>
  operacaoInterna: FilterOptions<number | string>
  modelo?: FilterOptions<number | null>
  serie?: FilterOptions<number | null>
  numeroNF?: FilterOptions<number | null>
  nfConsultaTipoData: FilterOptions<number | string>
}

export interface NFConsultaFiltersOptions {
  destinatarioId?: string | null
  destinatario?: ClienteModel | null
  empresaId?: string | null
  empresa?: EmpresaModel | null
  dataInicio: string
  dataFim: string
  status?: number | string | null
  tipoTransmissao: number
  operacaoInterna?: number | string | null
  modelo?: number | null
  serie?: number | null
  numeroNF?: number | null
  nfConsultaTipoData: number | string
}

export const nfConsultafilters: NFConsultaFilters = {
  empresaId: {
    value: '',
    label: 'Empresa',
  },
  empresa: {
    label: 'Empresa',
  },
  dataInicio: {
    label: 'Início',
    value: dateStartOfMonth(),
  },
  dataFim: {
    label: 'Fim',
    value: dateEndOfMonth(),
  },
  status: {
    value: NFStatusEnum.TODOS,
    label: 'Status',
  },
  tipoTransmissao: {
    value: DropDownTransmissaoEnum.TODAS_0.toString(),
    label: 'Transmissão',
  },
  operacaoInterna: {
    value: DropDownTipoInternoOperacaoEnum.TODAS,
    label: 'Operação',
  },
  modelo: {
    value: NFModeloEnum.TODOS,
    label: 'Modelo',
  },
  serie: {
    value: null,
    label: 'Série',
  },
  numeroNF: {
    value: null,
    label: 'Número NF',
  },
  nfConsultaTipoData: {
    value: DropDownNFTipoDataEnum.EMISSAO_1,
    label: 'Tipo',
  },
  destinatarioId: {
    value: '',
    label: 'Destinatário',
  },
  destinatario: {
    value: null,
    label: 'Destinatário',
  },
}

const notificationBottomStyle = { style: { bottom: '146px' } }

export async function obterNFConsulta(
  data: NFConsultaFiltersOptions,
): Promise<HttpResponseInterface<NFConsultaModel>> {
  delete data.empresa
  delete data.destinatario

  const response = await api.post('NFConsulta/Consultar', data)
  return response.data
}

export async function obterNFPorId(id: string): Promise<NFModel> {
  const response = await api.get<{ data: NFModel }>('NF/ObterNF', {
    params: { id },
  })

  return response.data.data
}

async function obterNFs(
  data: NFConsultaModel,
): Promise<HttpResponseInterface<NFModel>> {
  const response = await api.post<HttpResponseInterface<NFModel>>(
    'NFConsulta/Consultar',
    data,
  )
  return response.data
}

async function adicionarNF(
  data: AdicionarNFProps,
): Promise<HttpResponseInterface<NFModel>> {
  const response = await api.post<HttpResponseInterface<NFModel>>(
    `NF/Adicionar`,
    {},
    { params: data },
  )
  return response.data
}

export async function updateNFItem(
  nfId: string,
  data: NFItemModel,
): Promise<HttpResponseInterface<NFModel>> {
  const response = await api.put('NF/AlterarItem', data, {
    params: { id: nfId },
  })
  return response.data
}

export async function createNFItem(
  nfId: string,
  data: CreateNFItemProps,
): Promise<HttpResponseInterface<NFModel>> {
  const response = await api.post('NF/AdicionarItem', data, {
    params: { id: nfId },
  })
  return response.data
}

export async function createOrUpdateNFPagamento(
  nfId: string,
  data: NFPagamentosProps,
): Promise<HttpResponseInterface<NFModel>> {
  delete data.numeroDeParcelas
  delete data.periodo

  if (data?.id) {
    const response = await api.put('NF/AlterarPagamento', data, {
      params: { id: nfId },
    })
    return response.data
  }

  const response = await api.post('NF/AdicionarPagamento', data, {
    params: { id: nfId },
  })
  return response.data
}

async function alterarIdentificacaoNF(
  nfId: string,
  data: AlterarIdentificacaoParams,
): Promise<HttpResponseInterface<NFModel>> {
  const response = await api.put<HttpResponseInterface<NFModel>>(
    `NF/AlterarIdentificacao`,
    data,
    { params: { id: nfId } },
  )
  return response.data
}

export async function removerNFItem(data: { nfId: string; nfItemId: string }) {
  await api.delete('/NF/RemoverItem', {
    params: { id: data.nfId, nfItemId: data.nfItemId },
  })
}

export async function fecharNF(data: NFModel): Promise<NFModel> {
  const response = await api.put<{ data: NFModel }>(
    'NF/Fechar',
    {},
    { params: { id: data.id } },
  )
  return response.data.data
}

export async function reabrirNF(data: NFModel): Promise<NFModel> {
  const response = await api.put<{ data: NFModel }>(
    'NF/Reabrir',
    {},
    { params: { id: data.id } },
  )
  return response.data.data
}

export async function transmitirNF(data: NFModel): Promise<NFModel> {
  const response = await api.get<{ data: NFModel }>(
    'Comunicacao/TransmitirNotaFiscal',
    { params: { id: data.id } },
  )
  return response.data.data
}

export async function cancelarNF(data: CancelarNFProps) {
  const { nfId, justificativa } = data

  const response = await api.get<{ data: NFModel }>(
    'Comunicacao/CancelarNotaFiscal',
    { params: { id: nfId, justificativa } },
  )
  return response.data.data
}

export async function removerNFPagamento(
  data: NFPagamentosProps,
): Promise<NFModel> {
  const response = await api.delete('/NF/RemoverPagamento', {
    params: { id: data.nfId, pagamentoId: data.id },
  })

  return response.data
}

async function enviarEmailNF(
  data: EnviarEmailNFParams,
): Promise<HttpResponseInterface<NFModel>> {
  const { nfIds, emails, relatorioId } = data

  const response = await api.post<HttpResponseInterface<any>>(
    'Comunicacao/EnviarEmail',
    nfIds,
    {
      params: { emails: emails },
      headers: {
        'DC-PrintRelatorioId': relatorioId,
      },
    },
  )
  return response.data
}

export function useQueryObterNFConsulta({
  queryParams,
  requestId,
}: HttpRequestInterface<NFConsultaFiltersOptions>) {
  return useQuery<HttpResponseInterface<NFConsultaModel>, AxiosError>(
    ['NF', queryParams, requestId],
    () => obterNFConsulta(queryParams),
  )
}

export function useQueryObterNFPorId(id: string) {
  return useQuery<NFModel, AxiosError>(['NF', id], () => {
    return obterNFPorId(id)
  })
}

export function useAdicionarNF() {
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError<ErrorInterface>,
    AdicionarNFProps
  >((data) => adicionarNF(data), {
    onSuccess: () => {
      notifications.notifySuccess(
        'Venda iniciada com sucesso!',
        notificationBottomStyle,
      )
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useCreateNFItem() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError,
    { nfId: string; item: CreateNFItemProps }
  >((data) => createNFItem(data.nfId, data.item), {
    onSuccess() {
      notifications.notifySuccess(
        'Item adicionado com sucesso!',
        notificationBottomStyle,
      )
      queryClient.invalidateQueries(['NF'])
    },
    onError(error) {
      notifications.notifyException(error, notificationBottomStyle)
    },
  })
}

export function useUpdateNFItem() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError,
    { nfId: string; item: NFItemModel }
  >((data) => updateNFItem(data.nfId, data.item), {
    onSuccess() {
      notifications.notifySuccess(
        'Item alterado com sucesso!',
        notificationBottomStyle,
      )
      queryClient.invalidateQueries(['NF'])
    },
    onError(error) {
      notifications.notifyException(error, notificationBottomStyle)
    },
  })
}

export function useAlterarIdentificacaoNF() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError<ErrorInterface>,
    {
      nfId: string
      data: AlterarIdentificacaoParams
    }
  >(({ nfId, data }) => alterarIdentificacaoNF(nfId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['NF'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useRemoverNFItem() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, { nfId: string; nfItemId: string }>(
    (data) => removerNFItem(data),
    {
      onSuccess() {
        notifications.notifySuccess(
          'Item excluído com sucesso!',
          notificationBottomStyle,
        )
        queryClient.invalidateQueries(['NF'])
      },
      onError(error) {
        notifications.notifyException(error, notificationBottomStyle)
      },
    },
  )
}

export function useCreateOrUpdateNFPagamento() {
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError,
    { nfId: string; item: NFPagamentosProps }
  >((data) => createOrUpdateNFPagamento(data.nfId, data.item), {
    onSuccess() {
      // const flexUpdateOrCreate = data?.item?.id ? 'alterado' : 'adicionado'
      // notifications.notifySuccess(
      //   `Pagamento ${flexUpdateOrCreate} com sucesso!`,
      // )
    },
    onError(error) {
      notifications.notifyException(error, {
        style: { bottom: '122px' },
      })
    },
  })
}

export function useRemoverNFPagamento() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<NFModel, AxiosError, NFPagamentosProps>(
    (data) => removerNFPagamento(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['NF'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useEnviarEmailNF() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError<ErrorInterface>, EnviarEmailNFParams>(
    (data) => enviarEmailNF(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['NF'])
      },
      onError: (error) => {
        notifications.notifyException(error, {
          style: { bottom: '82px' },
        })
      },
    },
  )
}

export function useFecharNF() {
  const notifications = useNotification()

  return useMutation<NFModel, AxiosError<ErrorInterface>, NFModel>(
    (data) => fecharNF(data),
    {
      onSuccess() {},
      onError(error) {
        notifications.notifyException(error, { style: { bottom: '122px' } })
      },
    },
  )
}

export function useReabrirNF() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<NFModel, AxiosError<ErrorInterface>, NFModel>(
    (data) => reabrirNF(data),
    {
      onSuccess() {
        notifications.notifySuccess('NF-e reaberta com sucesso!', {
          style: { bottom: '122px' },
        })
        queryClient.invalidateQueries(['NF'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useTransmitirNF() {
  const notifications = useNotification()

  return useMutation<NFModel, AxiosError<ErrorInterface>, NFModel>(
    (data) => transmitirNF(data),
    {
      onSuccess() {},
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useCancelarNF() {
  const notifications = useNotification()
  return useMutation<NFModel, AxiosError, CancelarNFProps>(
    (data) => cancelarNF(data),
    {
      onSuccess() {},
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useObterNFs() {
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError<ErrorInterface>,
    NFConsultaModel
  >((data) => obterNFs(data), {
    onSuccess: () => {},
    onError: (error) => {
      notifications.notifyException(error, { style: { bottom: '80px' } })
    },
  })
}

export default function useNF() {
  return {
    useQueryObterNFConsulta,
    useAdicionarNF,
    useQueryObterNFPorId,
    useRemoverNFItem,
    useCreateNFItem,
    useUpdateNFItem,
    useCreateOrUpdateNFPagamento,
    useAlterarIdentificacaoNF,
    useFecharNF,
    useReabrirNF,
    useRemoverNFPagamento,
    useTransmitirNF,
    useObterNFs,
    useCancelarNF,
    useEnviarEmailNF,
  }
}
