import { ReactNode } from 'react'

import { Stack, Typography, useTheme } from '@mui/material'
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material'

import { ButtonContainer } from '@data-c/ui'

import FloatDrawer from 'components/FloatDrawer'
import Button from 'components/Button'

interface DilemmaDrawerProps {
  title?: string
  description: string
  confirmLabel: string
  confirmIcon?: ReactNode
  isOpen: boolean
  zIndex?: number | 'initial'
  onClose: () => void
  onConfirm: () => void
}

export default function DilemmaDrawer(props: DilemmaDrawerProps) {
  const {
    title,
    description,
    confirmLabel,
    confirmIcon,
    zIndex,
    isOpen,
    onClose,
    onConfirm,
  } = props
  const theme = useTheme()

  return (
    <FloatDrawer isOpen={isOpen} action={<></>} zIndex={zIndex}>
      <Stack padding={2} gap={3}>
        <Stack spacing={0.5}>
          <Typography variant="h1" component="h2" fontWeight="medium">
            {title ? title : ''}
          </Typography>

          <Typography
            variant="h5"
            color={theme.palette.grey[500]}
            fontWeight="bold"
          >
            {description}
          </Typography>
        </Stack>

        <ButtonContainer flexDirection="column" gap={1.5}>
          <Button
            fullWidth
            startIcon={confirmIcon || <CheckIcon />}
            variant="contained"
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
          <Button
            fullWidth
            startIcon={<CloseIcon />}
            color="error"
            onClick={onClose}
          >
            Cancelar
          </Button>
        </ButtonContainer>
      </Stack>
    </FloatDrawer>
  )
}
