export default function useCapitalize() {
  function capitalizeFirstLetter(text: string) {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return { capitalizeFirstLetter }
}
