import { useEffect } from 'react'
import { useFormApi } from '@data-c/providers'

import {
  OptionListProps,
  useActionOptionsContext,
} from 'components/OptionsListContainer'
import { useSelectableCardContext } from 'contexts/SelectableCardContext'

import {
  DuplicataModel,
  RegistrarBoletoModel,
} from 'hooks/queries/useDuplicatas'
import useNotification from 'hooks/useNotification'

import Form from './components/Form'
import { TipoOrigem } from '../..'

export default function BoletoRegistro({ name }: OptionListProps<TipoOrigem>) {
  const { itensSelecionados } = useSelectableCardContext<DuplicataModel>()
  const { origem, onClearOrigem } = useActionOptionsContext()

  const notification = useNotification()
  const { openForm } = useFormApi<RegistrarBoletoModel>()

  useEffect(() => {
    if (origem === name) {
      handleOpenRegistrarBoletosForm()
    }
  }, [origem])

  function handleOpenRegistrarBoletosForm() {
    onClearOrigem({ _closeOptionsListContainer: true })

    if (itensSelecionados.some((item) => item?.boletoRegistro)) {
      return notification.notifyInfo(
        'Você selecionou duplicata com boleto já emitido! Remova essa duplicata da seleção para prosseguir.',
        {
          position: 'top-right',
          autoClose: 5000,
        },
      )
    }

    openForm({
      contasReceber: itensSelecionados,
      contaFinanceiraId: '',
    })
  }

  return <Form />
}
