import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { HttpResponseInterface } from '@data-c/hooks'
import ErrorInterface from 'interfaces/ErrorInterface'
import apiFinanceiro from 'services/api-financeiro'

export interface ContaFinanceiraConsultaModel {
  id?: string
  empresaId: string
  empresaNome?: string | null
  codigo: number
  nome?: string | null
  ativo: boolean
  bancoNumero?: number | null
  agencia?: number | null
  agenciaDV?: string | null
  conta?: number | null
  contaDV?: string | null
  numeroUltimoCheque?: number | null
  isFluxoCaixa: boolean
  isBancaria: boolean
  hubBankConvenioId?: string | null
  hubBankConvenioDescricao?: string | null
}

export async function obterContasFinanceirasAtivas(): Promise<
  HttpResponseInterface<ContaFinanceiraConsultaModel>
> {
  const response = await apiFinanceiro.get('ContaFinanceira/ObterAtivas')
  return response?.data
}

export function useQueryObterContasFinanceirasAtivas() {
  return useQuery<
    HttpResponseInterface<ContaFinanceiraConsultaModel>,
    AxiosError<ErrorInterface>
  >(['CONTAS_FINANCEIRAS'], () => {
    return obterContasFinanceirasAtivas()
  })
}

export default function useContaFinanceira() {
  return {
    useQueryObterContasFinanceirasAtivas,
  }
}
