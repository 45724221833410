import { ReactNode, useEffect } from 'react'
import { Drawer, DrawerProps, Stack, Typography, useTheme } from '@mui/material'
import { useFilterApi, useFilterData } from '@data-c/providers'
import Button from 'components/Button'
import FilterDrawerActions from '../FilterDrawerActions'

interface FilterDrawerContainerProps<T> {
  title?: string
  description?: string
  children: ReactNode
  drawerProps?: DrawerProps
  onApplyFilters?: (filters: T) => void
}

export default function FilterDrawerContainer<T>(
  props: FilterDrawerContainerProps<T>,
) {
  const { title, children, onApplyFilters } = props
  const theme = useTheme()

  const { isOpen, appliedValues } = useFilterData()
  const { closeFilter } = useFilterApi()

  useEffect(() => {
    if (onApplyFilters) onApplyFilters(appliedValues)
  }, [appliedValues])

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      PaperProps={{
        style: {
          padding: isOpen ? '8px 0 8px 0' : '',
          borderTop: `solid 1px ${theme.palette.primary.main}`,
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        },
      }}
    >
      <Stack padding={2} gap={3}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" component="h2" fontWeight="bold">
            {title ? title : ''}
          </Typography>

          <Button variant="text" onClick={closeFilter}>
            Fechar
          </Button>
        </Stack>

        {children}

        <FilterDrawerActions />
      </Stack>
    </Drawer>
  )
}
