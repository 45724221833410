import { Box, Stack, Typography, useTheme } from '@mui/material'
import CardInfo from 'components/CardInfo'
import { DocumentoReferenciadoModel } from 'hooks/queries/useNFOutrasInformacoes'

interface CardOutrasInformacoesProps {
  informacoesAdicionais?: string | null
  informacoesComplementares?: string | null
  nfReferenciados: DocumentoReferenciadoModel[]
}
export default function CardOutrasInformacoes(
  props: CardOutrasInformacoesProps,
) {
  const { informacoesAdicionais, informacoesComplementares, nfReferenciados } =
    props

  const theme = useTheme()

  const existeDocumentosReferenciados = nfReferenciados.length > 0

  return (
    <Box>
      {(informacoesAdicionais ||
        informacoesComplementares ||
        existeDocumentosReferenciados) && (
        <>
          <Typography variant="body2" sx={{ padding: 1, paddingBottom: 0 }}>
            Outras Informações
          </Typography>
          <Stack gap={1}>
            {informacoesAdicionais && (
              <CardInfo
                subtitle="Interesse do Fisco"
                contentText={informacoesAdicionais}
              />
            )}
            {informacoesComplementares && (
              <CardInfo
                subtitle="Interesse do Contribuínte"
                contentText={informacoesComplementares}
              />
            )}
            {existeDocumentosReferenciados && (
              <CardInfo subtitle="Documentos Referenciados">
                {nfReferenciados?.map((nfReferenciado) => (
                  <Stack
                    key={nfReferenciado?.id}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={1}
                    paddingBottom={0.5}
                    gap={1}
                    borderBottom={`solid 1px ${theme.palette.grey[400]}`}
                  >
                    <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                      {nfReferenciado?.chaveAcesso || ''}
                    </Typography>
                  </Stack>
                ))}
              </CardInfo>
            )}
          </Stack>
        </>
      )}
    </Box>
  )
}
