import { useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'

import useCredentials, { LoginDataInterface } from 'hooks/useCredentials'
import useValidations from 'hooks/useValidation'

import Button from 'components/Button'
import * as yup from 'yup'

interface LoginFormProps {
  onLoginSuccess: (loginData: LoginDataInterface) => void
  onEsqueciSenhaClick: () => void
}

const schema = yup.object().shape({
  email: yup.string().required('Informe o e-mail'),
  password: yup.string().required('Informe a senha'),
})

export default function LoginForm(props: LoginFormProps) {
  const { onLoginSuccess, onEsqueciSenhaClick } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { login } = useCredentials()
  const { validationProps, setValidationErrors } = useValidations()

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  async function handleLogin() {
    setValidationErrors(null)
    schema
      .validate({ email, password }, { abortEarly: false })
      .then(async () => {
        setLoading(true)
        const loginResponse = await login(email, password)

        if (loginResponse) {
          onLoginSuccess(loginResponse)
        }
      })
      .catch((err: any) => {
        setValidationErrors(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box component="form">
      <TextField
        name="email"
        label="E-mail"
        margin="normal"
        autoFocus
        value={email || ''}
        onChange={(e) => setEmail(e.target.value)}
        {...validationProps('email')}
      />
      <TextField
        name="password"
        label="Senha"
        margin="normal"
        value={password || ''}
        onChange={(e) => setPassword(e.target.value)}
        type={showPassword ? 'text' : 'password'}
        onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </InputAdornment>
          ),
        }}
        {...validationProps('password')}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={handleLogin}
        isLoading={isLoading}
        sx={{ mt: 1 }}
      >
        Entrar
      </Button>

      <Button
        onClick={onEsqueciSenhaClick}
        variant="text"
        sx={{
          marginTop: 0.5,
          fontWeight: '400',
          ':hover': {
            color: (theme) => theme.palette.primary.dark,
          },
        }}
      >
        Esqueci a senha
      </Button>
    </Box>
  )
}
