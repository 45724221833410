import { MoveUp as MoveUpIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'

import DropDownContasFinanceirasAtivas from 'components/Inputs/DropDownContasFinanceirasAtivas'
import FormDrawerContainer from 'components/FormDrawerContainer'

import { useSelectableCardContext } from 'contexts/SelectableCardContext'

import useDuplicatas, {
  DuplicataModel,
  RegistrarBoletoModel,
} from 'hooks/queries/useDuplicatas'
import useValidations from 'hooks/useValidation'

import * as yup from 'yup'

const schema = yup.object().shape({
  contaFinanceiraId: yup.string().required('Informe a Conta Financeira'),
})

export default function Form() {
  const { onClearIntesSelecionados } =
    useSelectableCardContext<DuplicataModel>()

  const { setValidationErrors, validationProps } = useValidations()

  const { closeForm, changeValue, toggleSubmit } =
    useFormApi<RegistrarBoletoModel>()
  const { formValues: data } = useFormData<RegistrarBoletoModel>()

  const { useRegistrarMultiplosBoletos } = useDuplicatas()
  const { mutateAsync: registrarMultiplosBoletos } =
    useRegistrarMultiplosBoletos()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await registrarMultiplosBoletos(data)
        closeForm()
        onClearIntesSelecionados()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormDrawerContainer
      title="Emitir Boletos"
      onSubmit={handleSubmit}
      confirmLabel="Emitir"
      confirmIcon={<MoveUpIcon />}
    >
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <DropDownContasFinanceirasAtivas
          name="contaFinanceiraId"
          required
          value={data?.contaFinanceiraId || ''}
          onChange={(value) => {
            changeValue('contaFinanceiraId', value)
          }}
          onRender={(primeiraContaFinanceira) =>
            changeValue('contaFinanceiraId', primeiraContaFinanceira?.id)
          }
          {...validationProps('contaFinanceiraId')}
        />
      </Grid>
    </FormDrawerContainer>
  )
}
