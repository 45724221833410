import { memo } from 'react'
import { Paper, useTheme } from '@mui/material'
import { DuplicataModel } from 'hooks/queries/useDuplicatas'

import ContentInfo from './components/ContentInfo'
import Header from './components/Header'
import Footer from './components/Footer'

interface CardDuplicataModel extends DuplicataModel {
  totalFormatado: string
  dataVencimentoFormatada: string
  boletoRegistroFormatado: string
}

interface CardDuplicataProps {
  data: CardDuplicataModel
  isSelected: boolean
  onSelect: (dataSelecionada: DuplicataModel) => void
}

function CardDuplicataComponent(props: CardDuplicataProps) {
  const { data, isSelected, onSelect } = props

  const {
    dataVencimentoFormatada,
    totalFormatado,
    pessoaNome,
    contaFinanceiraNome,
    categoriaNome,
    documento,
    boletoRegistroFormatado,
    meioPagamentoDesc,
    boletoRegistro,
    boletoNossoNumero,
    boletoSituacao,
    origemDesc,
    boletoMensagemErro,
  } = data

  const theme = useTheme()

  return (
    <Paper
      sx={{
        marginBottom: 1.5,
        boxShadow: isSelected ? '0px 0px 10px 0px rgba(0,0,0,0.25)' : '',
        scale: isSelected ? '0.96' : 'initial',
        transition: 'linear 150ms',
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          width: '4px',
          height: '100%',
          backgroundColor: boletoRegistro
            ? theme.palette.success.main
            : theme.palette.primary.main,
          top: 0,
          left: 0,
          bottom: 0,
        },
      }}
    >
      <Header
        dataVencimentoFormatada={dataVencimentoFormatada}
        isSelected={isSelected}
        boletoRegistro={boletoRegistro}
        onSelect={() => onSelect(data)}
      />
      <ContentInfo
        pessoaNome={pessoaNome}
        contaFinanceiraNome={contaFinanceiraNome}
        categoriaNome={categoriaNome}
        documento={documento}
        meioPagamentoDesc={meioPagamentoDesc}
        boletoRegistroFormatado={boletoRegistroFormatado}
        boletoSituacao={boletoSituacao}
        boletoNossoNumero={boletoNossoNumero}
        boletoMensagemErro={boletoMensagemErro}
      />
      <Footer origemDesc={origemDesc} totalFormatado={totalFormatado} />
    </Paper>
  )
}

export const CardDuplicata = memo(CardDuplicataComponent)
