import { Stack, Typography, useTheme } from '@mui/material'
import notifyImage from 'assets/images/notify.svg'

interface EmptyDataProps {
  description: string
  instructionText?: string
}

export default function EmptyData(props: EmptyDataProps) {
  const { description, instructionText } = props

  const theme = useTheme()

  return (
    <Stack
      gap={3}
      sx={{
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingX: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <img src={notifyImage} alt="Imagem de notificação" width="30%" />

      <Stack spacing={1}>
        <Typography variant="h1" component="h2" fontWeight="medium">
          {description}
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.grey[500]}
          fontWeight="bold"
        >
          {instructionText}
        </Typography>
      </Stack>
    </Stack>
  )
}
