import { ReactNode } from 'react'
import {
  Box,
  Collapse,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'

import Container from 'components/Container'
import FixedContainer from 'components/FixedContainer'
import DefaultAction from './components/DefaultAction'

interface FloatDrawerProps {
  action?: ReactNode
  children: ReactNode
  title?: string
  isOpen: boolean
  showActionOnlyWhenIsOpen?: boolean
  customSx?: SxProps
  moveEffect?: boolean
  zIndex?: number | 'initial'
  onOpenDrawer?: () => void
  onClose?: () => void
}

export default function FloatDrawer(props: FloatDrawerProps) {
  const {
    action,
    children,
    title,
    isOpen = false,
    showActionOnlyWhenIsOpen = false,
    customSx,
    onOpenDrawer,
    moveEffect = false,
    zIndex,
    onClose,
  } = props

  const theme = useTheme()

  function handleClick() {
    if (onOpenDrawer) {
      onOpenDrawer()
      return
    }

    if (onClose) {
      onClose()
    }
  }

  return (
    <FixedContainer
      customSx={{ zIndex, borderRadius: '12px' }}
      borderColor="none"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: isOpen ? '8px 0 8px 0' : '',
          borderTop: `solid 1px ${theme.palette.primary.main}`,
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          animation: moveEffect ? 'moveUp 500ms linear 3' : '',
          '@keyframes moveUp': {
            '0%': { transform: 'translateY(0)' },
            '50%': { transform: 'translateY(-20px)' },
            '100%': { transform: 'translateY(0)' },
          },
          backgroundColor: theme.palette.background.paper,
          ...customSx,
        }}
      >
        <Container overflow="visible" fullHeight={false}>
          <Stack gap={1}>
            {action && !showActionOnlyWhenIsOpen && action}
            {action && showActionOnlyWhenIsOpen && isOpen === true && action}
            {!action && showActionOnlyWhenIsOpen && isOpen === true && (
              <DefaultAction isOpen={isOpen} onClick={handleClick} />
            )}
            {!action && !showActionOnlyWhenIsOpen && (
              <DefaultAction isOpen={isOpen} onClick={handleClick} />
            )}
          </Stack>
          <Collapse in={isOpen}>
            {title && (
              <Typography variant="h4" fontWeight="bold" padding={2}>
                {title}
              </Typography>
            )}
            {children}
          </Collapse>
        </Container>
      </Box>
    </FixedContainer>
  )
}
