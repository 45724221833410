import { ReactNode } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

import BottomNavigation from 'components/BottomNavigation'
import { useNFContext } from 'contexts/NFContext/NFContext'

import useEmpresa from 'hooks/queries/useEmpresa'
import useCredentials from 'hooks/useCredentials'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated } = useCredentials()

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  const { setNFIds } = useNFContext()
  const { useQueryObterEmpresas } = useEmpresa()
  const { data: empresas } = useQueryObterEmpresas()

  function handleClick() {
    const pathName = location.pathname

    if (pathName === '/clientes/lista') {
      navigate('/clientes/cadastro-clientes')
      return
    }

    if (empresas?.data?.length === 1) {
      const empresaId = empresas?.data[0]?.id || ''
      setNFIds('empresaId', empresaId)
      navigate('/NF/operacao')
      return
    }

    navigate('/NF/empresa')
  }

  const showBottomNavigationIn = ['/vendas', '/clientes/lista']
  const currentPath = location.pathname.replace(/\/$/, '')

  return (
    <>
      <Box>{children}</Box>
      {showBottomNavigationIn.includes(currentPath) && (
        <BottomNavigation
          empresas={empresas?.data || []}
          onClick={handleClick}
        />
      )}
    </>
  )
}
