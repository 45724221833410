import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
}

export default function Button(props: ButtonProps) {
  const { isLoading, children, ...rest } = props
  const theme = useTheme()
  const isDesktopVersion = useMediaQuery(() => theme.breakpoints.up('sm'))

  return (
    <MuiButton
      size={isDesktopVersion ? 'small' : 'large'}
      {...rest}
      disabled={isLoading ? true : rest.disabled}
    >
      {isLoading ? <CircularProgress color="secondary" size={22} /> : children}
    </MuiButton>
  )
}
