import { List, ListProps } from '@mui/material'

export default function SimpleList(props: ListProps) {
  const { children, sx, ...rest } = props

  return (
    <List sx={{ width: '100%', ...sx }} {...rest}>
      {children}
    </List>
  )
}
