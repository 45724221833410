import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useCliente, { ClienteModel } from 'hooks/queries/useCliente'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'

import Form from './Form'

export default function CadastroCliente() {
  const { id: clienteId } = useParams()

  const location = useLocation()
  const navigate = useNavigate()

  const { useQueryObterClientePorId } = useCliente()

  const {
    data: cliente,
    isLoading,
    error,
  } = useQueryObterClientePorId(clienteId)

  function handleBack() {
    const pathname = location.pathname

    if (pathname.startsWith('/clientes')) {
      navigate('/clientes/lista')
    }

    if (pathname.startsWith('/NF')) {
      navigate('/NF/clientes')
    }
  }

  return (
    <Container paper>
      <PageTitle title="Cadastro de Cliente" onBack={handleBack} />

      <ContentContainer disablePadding isLoading={isLoading} error={error}>
        <Form
          cliente={cliente || ({} as ClienteModel)}
          cadastroOrigem={location.pathname}
        />
      </ContentContainer>
    </Container>
  )
}
