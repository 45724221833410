import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useConvenio, { ConvenioResumoModel } from 'hooks/queries/useConvenio'

export default function AutoCompleteConvenio(
  props: AutoCompleteProps<ConvenioResumoModel>,
) {
  const { onChange, value, autoFocus, InputLabelProps, placeholder } = props
  const { useQueryObterConvenio } = useConvenio()
  const { data, isLoading } = useQueryObterConvenio()

  const convenios = data?.data.filter((convenio) => convenio.ativo === true)

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={convenios || []}
      onChange={onChange}
      getOptionLabel={(convenio) => {
        return `${convenio?.descricao}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Convênios"
          autoFocus={autoFocus}
          InputLabelProps={InputLabelProps}
          placeholder={placeholder}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
