import { formatCurrency } from '@data-c/hooks'
import { Box, Typography, useTheme } from '@mui/material'
import DoubleActionButton from 'components/DoubleActionButton'

interface ActionsProps {
  quantidade: number
  disabled?: boolean
  unitario: number
  onChange: (quantidade: number) => void
}

export default function Actions(props: ActionsProps) {
  const { quantidade, unitario, disabled, onChange } = props

  const theme = useTheme()
  const unitarioFormatado = formatCurrency(unitario)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 3,
        marginBottom: 3,
      }}
    >
      <Typography
        variant="h3"
        fontWeight="bold"
        sx={{ color: theme.palette.grey[600] }}
      >
        R${unitarioFormatado}
      </Typography>

      <DoubleActionButton
        value={quantidade}
        disabled={disabled}
        onChange={(quantidade) => onChange(quantidade)}
      />
    </Box>
  )
}
