import { Add as AddIcon, Edit as EditICon } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import Button from 'components/Button'

interface CardInfoActionsProps {
  showAddButton: boolean
  onClick: () => void
}

export default function CardInfoActions(props: CardInfoActionsProps) {
  const { showAddButton, onClick } = props

  return (
    <>
      {showAddButton ? (
        <Button
          variant="text"
          startIcon={<AddIcon />}
          sx={{ width: '100px' }}
          onClick={onClick}
        >
          Adicionar
        </Button>
      ) : (
        <Stack
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            paddingTop: 0.5,
            paddingRight: 0.5,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton onClick={onClick}>
            <EditICon fontSize="medium" color="primary" />
          </IconButton>
        </Stack>
      )}
    </>
  )
}
