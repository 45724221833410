import { Box } from '@mui/material'

import Loading from 'components/Displays/Loading'
import Error from 'components/Displays/Error'

interface IframeProps {
  pdf: string
  errorMessage?: string
  isLoading?: boolean
  error?: unknown
}

export default function Iframe(props: IframeProps) {
  const { pdf, errorMessage, isLoading = false, error } = props

  return (
    <Box
      sx={{
        position: 'relative',
        height: '90vh',
        marginTop: '78px',
      }}
    >
      {isLoading && <Loading customSx={{ zIndex: 0 }} />}
      {Boolean(error) && <Error description={errorMessage} />}
      {!isLoading && Boolean(!error) && (
        <iframe width="100%" height="100%" src={pdf} />
      )}
    </Box>
  )
}
