import { Paper } from '@mui/material'
import { ReactNode } from 'react'

interface XmlContainerProps {
  children: ReactNode
}

export default function XmlContainer({ children }: XmlContainerProps) {
  return (
    <Paper
      sx={{
        // height: 'calc(100vh - 70px)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {children}
    </Paper>
  )
}
