import { useCallback, useEffect, useState } from 'react'

import { v4 } from 'uuid'

import { Stack, TextFieldProps } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

import Button from 'components/Button'
import { MemoizedTextField } from './components/MemoizedTextField'

interface DataProps {
  id: string
  value: string
}

interface TextFieldDynamicProps {
  inputProps?: TextFieldProps
  disabledAddButton?: boolean
  reset?: boolean
  onChange: (data: DataProps[]) => void
}

export default function TextFieldDynamic(props: TextFieldDynamicProps) {
  const {
    inputProps,
    disabledAddButton = false,
    reset = false,
    onChange,
  } = props

  const [data, setData] = useState<DataProps[]>([{ id: v4(), value: '' }])

  useEffect(() => {
    onChange(data)
  }, [data])

  useEffect(() => {
    if (reset) {
      setData([{ id: v4(), value: '' }])
    }
  }, [reset])

  const handleChange = useCallback((value: string, id?: string) => {
    if (id) {
      setData((prevState) =>
        prevState.map((item) => (item.id === id ? { ...item, value } : item)),
      )
    }
  }, [])

  function handleAddTextField() {
    const newData: DataProps = {
      id: v4(),
      value: '',
    }

    setData((prevState) => [...prevState, newData])
  }

  const handleRemoveTextField = useCallback((id: string) => {
    setData((prevState) => {
      const newData = prevState.filter((item) => item.id !== id)

      return newData
    })
  }, [])

  return (
    <Stack gap={2}>
      {data.map((item) => (
        <MemoizedTextField
          key={item.id}
          id={item.id}
          value={item.value}
          disabled={data.length === 1}
          inputProps={inputProps}
          onChange={handleChange}
          onClick={handleRemoveTextField}
        />
      ))}

      <Button
        fullWidth
        startIcon={<AddIcon />}
        disabled={disabledAddButton}
        onClick={handleAddTextField}
      >
        Adicionar
      </Button>
    </Stack>
  )
}
