import moment from 'moment'

export function dateStartOfMonth(monthsAgo?: number) {
  if (monthsAgo) {
    return moment()
      .subtract(monthsAgo, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
  }

  return moment().startOf('month').format('YYYY-MM-DD')
}

export function dateEndOfMonth() {
  return moment().endOf('month').format('YYYY-MM-DD')
}

export function formatDateToDiaMesAno(date: string) {
  if (!date) return ''
  return moment(date).format('DD/MM/YYYY')
}

export function formatDateToAnoMes(date: string) {
  if (!date) return ''
  return moment(date).format('YYYY/MM')
}

export function getCurrentDate() {
  const ano = moment().year().toString()
  const mes = moment().month().toString()
  const fullDate = moment().format('YYYY/MM/DD')
  const fullDateWithHours = moment().format('YYYY/MM/DD hh:mm')

  return { ano, mes, fullDate, fullDateWithHours }
}

function calcularReajusteDoPeriodo<T extends { [key: string]: any }>(
  dtInicio: string,
  dtInicioFieldName: keyof T,
  reajustes: T[],
  dtFim?: string | null,
  dtFimFieldName?: keyof T,
) {
  let newDataInicio = dtInicio
  let newDataFim = dtFim

  const ultimoReajuste = reajustes[reajustes.length - 1]

  if (ultimoReajuste) {
    const ultimoReajusteDtInicio = moment(ultimoReajuste[dtInicioFieldName])
    const ultimoReajusteDtFim = moment(ultimoReajuste?.[dtFimFieldName || ''])

    const diff = ultimoReajusteDtFim.diff(ultimoReajusteDtInicio, 'days')
    newDataInicio = ultimoReajusteDtFim.add(1, 'days').format('YYYY/MM/DD')
    newDataFim = ultimoReajusteDtFim.add(diff, 'days').format('YYYY/MM/DD')
  } else {
    if (!newDataFim) {
      newDataFim = moment(dtInicio).add(1, 'year').format('YYYY/MM/DD')
    }
  }

  return {
    newDataInicio,
    newDataFim,
  }
}

export function getFirstDayOfCurrentYear() {
  return moment().startOf('year').format('DD/MM/YYYY')
}
export function formatDateToDiaMesAnoHoraMinuto(
  date: string,
  formatoDaHora?: '12h',
) {
  if (!date) return ''

  if (formatoDaHora === '12h') {
    return moment(date).format('DD/MM/YYYY hh:mm')
  } else {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }
}

export default function useDateFormat() {
  return {
    dateStartOfMonth,
    dateEndOfMonth,
    formatDateToDiaMesAno,
    formatDateToAnoMes,
    formatDateToDiaMesAnoHoraMinuto,
    getCurrentDate,
    calcularReajusteDoPeriodo,
    getFirstDayOfCurrentYear,
  }
}
