import { Dialog } from '@data-c/ui'
import PercentageLinearProgress from 'components/PercentageLinearProgress'

interface ProgressDownloadDialog {
  isOpen: boolean
  title: string
  progressValue: number
}

export default function ProgressDownloadDialog(props: ProgressDownloadDialog) {
  const { isOpen, title, progressValue } = props

  return (
    <Dialog open={isOpen} title={title} actions={<></>}>
      <PercentageLinearProgress
        progressValue={progressValue}
        targetValue={100}
      />
    </Dialog>
  )
}
