import { ReactNode } from 'react'
import { Stack, SxProps } from '@mui/material'

import Error from 'components/Displays/Error'
import Loading from 'components/Displays/Loading'

interface ContentContainerProps {
  disablePadding?: boolean
  isLoading?: boolean
  error?: any
  customSx?: SxProps
  children: ReactNode
}

export default function ContentContainer(props: ContentContainerProps) {
  const {
    disablePadding = false,
    isLoading = false,
    error,
    customSx,
    children,
  } = props

  return (
    <Stack
      // height="calc(100% - 97px)"
      padding={disablePadding ? 0 : 2}
      // justifyContent="space-between"
      sx={{ ...customSx }}
    >
      {isLoading && <Loading />}
      {Boolean(error) && <Error />}
      {!isLoading && Boolean(!error) && <>{children}</>}
    </Stack>
  )
}
