import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Checkbox } from '@data-c/ui'

interface HeaderProps {
  dataVencimentoFormatada: string
  isSelected: boolean
  boletoRegistro?: string | null
  onSelect: () => void
}

export default function Header(props: HeaderProps) {
  const { dataVencimentoFormatada, isSelected, boletoRegistro, onSelect } =
    props

  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: isSelected
          ? boletoRegistro
            ? 'rgba(76, 175, 80, 0.15)'
            : 'rgba(89, 195, 224, 0.15)'
          : '',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: 1,
        paddingX: 2,
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
      }}
    >
      <Stack flexDirection="row" alignItems="center">
        <Checkbox
          label="Duplicata"
          checked={isSelected}
          onChange={onSelect}
          color={boletoRegistro ? 'success' : 'primary'}
        />
      </Stack>
      <Typography variant="body2">
        <strong>Vencimento:</strong> {dataVencimentoFormatada || '---'}
      </Typography>
    </Box>
  )
}
