import { Box, Typography, useTheme } from '@mui/material'
import { formatCurrency } from '@data-c/hooks'
import { NFPagamentosProps } from 'hooks/queries/useNF'
import extrairParcelasEPeriodo from 'hooks/useExtrairParcelasEPeriodo'

interface CardFormasPagamentoProps {
  nfPagamentos: NFPagamentosProps[]
  isLink?: boolean
  onClick: (nfPagamentoId: string) => void
}

export default function CardFormasPagamento(props: CardFormasPagamentoProps) {
  const { nfPagamentos, isLink = false, onClick } = props

  const theme = useTheme()

  return (
    <Box sx={{ marginBottom: 3 }}>
      {nfPagamentos?.map((nfPagamento) => {
        const formaPagamentoNome = nfPagamento?.formaPagamento?.nome || ''
        const valorPagamento = formatCurrency(nfPagamento.valorPagamento)

        const { numeroDeParcelas } = extrairParcelasEPeriodo(
          nfPagamento?.parcelamento_CondPgto,
        )

        const parselas = numeroDeParcelas > 0 ? `${numeroDeParcelas}x` : ''

        return (
          <Box
            key={nfPagamento.id}
            onClick={() => onClick(nfPagamento?.id || '')}
            sx={{
              color: isLink ? theme.palette.primary.dark : '',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 1.5,
              ':hover': {
                cursor: isLink ? 'pointer' : 'default',
                color: isLink
                  ? theme.palette.primary.main
                  : theme.palette.grey[600],
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textTransform: 'uppercase',
                color: 'inherit',
                fontWeight: isLink ? 'normal' : 'bold',
              }}
            >
              {formaPagamentoNome}
            </Typography>
            <Typography variant="body2">
              {parselas} R$ {valorPagamento}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
