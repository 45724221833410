import { Stack, TextField } from '@mui/material'
import { useFilterApi, useFilterData } from '@data-c/providers'

import DropDownBoletoTipoData, {
  DropDownBoletoTipoDataEnum,
  boletoTipoDataReverseMapping,
} from 'components/Inputs/DropDownBoletoTipoData'
import AutoCompleteConvenio from 'components/AutoCompleteConvenio'
import FilterContainer from 'components/FilterContainer'
import DatePicker from 'components/DatePicker'

import { BoletoFilters, BoletoFiltersOptions } from 'hooks/queries/useBoleto'
import {
  dateEndOfMonth,
  dateStartOfMonth,
  formatDateToDiaMesAno,
} from 'hooks/queries/useDateFormat'

import FilterProps from 'interfaces/FilterProps'
import AutoCompleteEmpresas from 'components/AutoCompleteEmpresa'

export function formatarFiltros(
  filters?: BoletoFiltersOptions,
): BoletoFiltersOptions {
  return {
    ...filters,
    tipoData: filters?.tipoData || DropDownBoletoTipoDataEnum.EMISSAO_0,
    dataFim: filters?.dataFim || dateEndOfMonth(),
    dataInicio: filters?.dataInicio || dateStartOfMonth(),
    convenioId: filters?.convenio?.id || null,
    empresaId: filters?.empresa?.id || '',
  }
}

export function formatarFilterLabels(filterLabel: string, filterValue: any) {
  switch (filterLabel) {
    case 'Início':
      return `${filterLabel}: ${formatDateToDiaMesAno(filterValue || '')}`
    case 'Fim':
      return `${filterLabel}: ${formatDateToDiaMesAno(filterValue || '')}`
    case 'Tipo':
      return `${filterLabel}: ${boletoTipoDataReverseMapping[filterValue]}`
    case 'Convênio':
      return `${filterLabel}: ${filterValue?.descricao || ''}`
    case 'Empresa':
      return `${filterLabel}: ${filterValue?.nome || ''}`
    default:
      return `${filterLabel}: ${filterValue}`
  }
}

export default function Filter(props: FilterProps<BoletoFiltersOptions>) {
  const { onApplyFilters } = props

  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<BoletoFilters, BoletoFiltersOptions>()

  return (
    <FilterContainer
      title="Opções de Filtro"
      onApplyFilters={onApplyFilters}
      triggerButtonLabel="Filtros"
    >
      <Stack spacing={2}>
        <AutoCompleteEmpresas
          name="empresaId"
          label={filterValues?.empresa?.label}
          value={filterValues?.empresa?.value || null}
          onChange={(_, value) => {
            if (!value) return
            changeFilterValue('empresa', value)
          }}
        />
        <DropDownBoletoTipoData
          value={filterValues?.tipoData?.value}
          onChange={(value) => {
            changeFilterValue('tipoData', value)
          }}
        />
        <Stack flexDirection="row" gap={1}>
          <DatePicker
            label={filterValues?.dataInicio?.label}
            onChange={(dataInicio) => {
              changeFilterValue('dataInicio', dataInicio)
            }}
            value={filterValues?.dataInicio?.value}
          />
          <DatePicker
            label={filterValues?.dataFim?.label}
            onChange={(dataFim) => {
              changeFilterValue('dataFim', dataFim)
            }}
            value={filterValues?.dataFim?.value}
          />
        </Stack>
        <TextField
          name="pagadorConteudo"
          label={filterValues?.pagadorConteudo?.label}
          value={filterValues?.pagadorConteudo?.value}
          onChange={(e) => {
            changeFilterValue('pagadorConteudo', e.target.value || null)
          }}
        />
        <AutoCompleteConvenio
          name="convenio"
          label={filterValues?.convenio?.label}
          value={filterValues?.convenio?.value || null}
          onChange={(_, value) => changeFilterValue('convenio', value)}
        />
      </Stack>
    </FilterContainer>
  )
}
