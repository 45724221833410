import { ReactNode } from 'react'

import { IconButton, Stack } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { PageTitle } from '@data-c/ui'
import FixedContainer from 'components/FixedContainer'

interface IframeHeaderProps {
  title: string
  children?: ReactNode
  onClose?: () => void
  onBack?: () => void
}

export default function IframeHeader(props: IframeHeaderProps) {
  const { title, children, onClose, onBack } = props

  return (
    <FixedContainer fixIn="none" borderColor="none" customSx={{ padding: 2 }}>
      <PageTitle title={title} onBack={onBack}>
        <Stack flexDirection="row" alignItems="center" gap={3}>
          {children}

          {onClose && (
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          )}
        </Stack>
      </PageTitle>
    </FixedContainer>
  )
}
