import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Badge, Tab, Tabs, useTheme } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'

import { SearchTextField } from '@data-c/ui'

import useTabs from 'hooks/useTabs'
import useProduto from 'hooks/queries/useProduto'
import useNF from 'hooks/queries/useNF'

import TabPanel from 'components/TabPanel'
import Container from 'components/Container'
import HeaderContainer from 'components/Container/components/HeaderContainer'
import PageTitle from 'components/PageTitle'

import TabProdutosLista from './components/TabProdutosLista'
import TabItensAdicionados from './components/TabItensAdicionados'
import Footer from './components/Footer'

export default function Produtos() {
  const { id: nfId } = useParams()

  const [query, setQuery] = useState('')

  const navigate = useNavigate()
  const theme = useTheme()

  const { value, handleChange } = useTabs()
  const { useQueryObterProdutos } = useProduto()
  const { useQueryObterNFPorId } = useNF()
  const {
    data: produtos,
    isLoading: isLoadingProdutos,
    error: errorProdutos,
  } = useQueryObterProdutos(query)
  const {
    data: nf,
    isLoading: isLoadingNF,
    error: errorNF,
  } = useQueryObterNFPorId(nfId || '')

  const itensAdicionados = nf?.nfItems?.length

  return (
    <Container>
      <HeaderContainer disableFixed>
        <PageTitle
          title="Produtos"
          disablePadding
          onBack={() => navigate('/NF/clientes')}
          onClose={() => navigate('/vendas')}
        >
          <SearchTextField
            onSearch={(query) => setQuery(query)}
            placeholder="Pesquisar produtos"
            autoFocus={false}
          />
        </PageTitle>

        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="tabs produtos"
          sx={{ marginTop: 2 }}
        >
          <Tab label="Produtos" value={0} />
          <Tab
            label={
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    color: theme.palette.background.paper,
                  },
                }}
                badgeContent={itensAdicionados}
                color="primary"
              >
                <ShoppingCart fontSize="medium" />
              </Badge>
            }
            value={1}
          />
        </Tabs>
      </HeaderContainer>

      <TabPanel value={value} index={0}>
        <TabProdutosLista
          produtos={produtos?.data || []}
          nf={nf}
          isLoading={isLoadingProdutos}
          error={errorProdutos}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabItensAdicionados nf={nf} isLoading={isLoadingNF} error={errorNF} />
      </TabPanel>

      <Footer nf={nf} />
    </Container>
  )
}
