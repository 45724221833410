import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material'
import { CircularProgress, InputAdornment } from '@mui/material'
import {
  MobileDateTimePicker as MuiMobileDateTimePicker,
  MobileDateTimePickerProps as MuiMobileDateTimePickerProps,
} from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import 'moment/dist/locale/pt-br'

interface MobileDateTimePickerProps
  extends Omit<MuiMobileDateTimePickerProps<Moment>, 'onChange' | 'value'> {
  error?: boolean | string | null
  helperText?: string
  onChange: (date: string | null) => void
  value?: string | null
  required?: boolean
  isLoading?: boolean
}

export default function MobileDateTimePicker(props: MobileDateTimePickerProps) {
  const { value, error, onChange, helperText, required, isLoading, ...rest } =
    props

  function handleChange(date: Moment | null) {
    if (date?.isValid()) {
      onChange(date?.format('yyyy-MM-DD HH:mm:ss'))
      return
    }

    if (date === null) {
      onChange(null)
      return
    }
  }

  return (
    <MuiMobileDateTimePicker
      format="DD/MM/YYYY HH:mm"
      value={moment(value)}
      onChange={(v: Moment | null) => handleChange(v)}
      slotProps={{
        textField: {
          error: Boolean(error),
          helperText,
          required,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <CalendarMonthIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? <CircularProgress size={18} /> : <></>}
              </InputAdornment>
            ),
          },
        },
      }}
      ampm={false}
      {...rest}
    />
  )
}
