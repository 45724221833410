import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import authApi from 'services/auth-api'
import {
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken,
} from 'services/credentials'

const api = axios.create({
  baseURL: `${import.meta.env.VITE_FINANCEIRO_API_URL}`,
})

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  let conf: AxiosRequestConfig = config
  if (conf.headers === undefined) {
    conf.headers = {}
  }

  const token = getToken()

  const temAuthorizationHeader = Boolean(conf.headers.Authorization)
  if (token && token !== 'undefined' && !temAuthorizationHeader) {
    conf.headers.Authorization = `Bearer ${token}`
  }

  conf.headers['Content-Type'] = 'application/json;charset=UTF-8'
  conf.headers['Resposta-Compactada'] = 'Nunca'
  conf.headers['Access-Control-Allow-Origin'] = '*'
  conf.headers['Access-Control-Allow-Headers'] =
    'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept'
  conf.headers['Access-Control-Allow-Methods'] =
    'GET, POST, OPTIONS, PUT, PATCH, DELETE'
  conf.headers['Access-Control-Allow-Credentials'] = true
  conf.headers['Allow'] = 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
  return conf
})

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config as AxiosRequestConfig
    if (
      error?.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest.refreshTokenAttempted
    ) {
      originalRequest.refreshTokenAttempted = true
      const access_token = await refreshAccessToken()
      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = 'Bearer ' + access_token
      }
      return api(originalRequest)
    } else {
      if (!originalRequest || !originalRequest.attempts) {
        return Promise.reject(error)
      }
      // retry while Network timeout or Network Error
      // if (!(message.includes('timeout') || message.includes('Network Error'))) {
      //   return Promise.reject(err)
      // }

      originalRequest.attempts = originalRequest.attempts - 1
      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log('retry the request', originalRequest.url)
          resolve()
        }, 5000)
      })
      return delayRetryRequest.then(() => api(originalRequest))
    }
  },
)

const refreshAccessToken = async () => {
  const token = getToken()
  const refreshToken = getRefreshToken()

  const response = await authApi.post('refresh-token', {
    token,
    refreshToken,
  })
  setToken(response.data.token)
  setRefreshToken(response.data.refreshToken)
  return response.data.token
}

export default api
