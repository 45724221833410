import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotification'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import apiFinanceiro from 'services/api-financeiro'
import { CategoriaFinanceiroConsultaModel } from './useCategoriaFinanceiro'

//Equivalente à ContaPRConsultaModel na api do swagger
export interface DuplicataModel {
  id?: string
  empresaId: string
  empresaCodigo: number
  tipoConta: number
  tipoContaDesc?: string | null
  origem: number
  origemId?: string | null
  origemDesc?: string | null
  origemSiglaDesc?: string | null
  situacaoDesc?: string | null
  documento?: string | null
  dataLancamento: string
  dataEmissao: string
  dataVencimento: string
  dataLiquidacao?: string | null
  pessoaId: string
  pessoaNome?: string | null
  pessoaDocumentoId?: string | null
  pessoaLogradouro?: string | null
  pessoaNumero?: string | null
  pessoaComplemento?: string | null
  pessoaBairro?: string | null
  pessoaCEP?: string | null
  pessoaCidadeNome?: string | null
  pessoaCidadeUF?: string | null
  diasLiberacao: number
  valor: number
  ipi: number
  icmsst: number
  frete: number
  acrescimo: number
  desconto: number
  total: number
  totalCalc: number
  acrescimoAcum: number
  descontoAcum: number
  isVinculado: boolean
  contaFinanceiraId?: string | null
  contaFinanceiraNome?: string | null
  observacao?: string | null
  boletoId?: string | null
  boletoConvenio?: string | null
  boletoRegistro?: string | null
  boletoSituacao?: string | null
  boletoNossoNumero?: string | null
  boletoBancoCodigo?: number | null
  boletoAlteracao?: string | null
  boletoMensagemErro?: string | null
  dataDescontada?: string | null
  dataDescontadaoDevolucao?: string | null
  acrescimoCalcMultaJuros: number
  totalCalcMultaJuros: number
  diasAtraso: number
  categoriaId?: string | null
  categoria?: CategoriaFinanceiroConsultaModel | null
  categoriaNome?: string | null
  categoriaNormal?: string | null
  nfModelo?: string | null
  nfSerie?: string | null
  nfNumero?: number | null
  meioPagamento: number
  meioPagamentoDesc?: string | null
}

export interface RegistrarBoletoModel {
  contaFinanceiraId: string
  contasReceber: DuplicataModel[]
  categoriaId?: string
  categoria?: CategoriaFinanceiroConsultaModel | null
}

export type RegistrarBoletoParams = {
  contaReceberId: string
} & Omit<RegistrarBoletoModel, 'contasReceber' | 'categoria'>

export async function obterDuplicatasPorNFId(
  nfId: string,
): Promise<HttpResponseInterface<DuplicataModel>> {
  const response = await apiFinanceiro.get('ContaPRConsulta/ObterPorOrigem', {
    params: { origemId: nfId },
  })

  return response?.data
}

export async function registrarBoleto(
  params: RegistrarBoletoParams,
): Promise<any> {
  const response = await apiFinanceiro.post('Boleto/RegistrarBoleto', params)
  return response?.data
}

export function useQueryObterDuplicatasPorNFId(nfId: string) {
  return useQuery<HttpResponseInterface<DuplicataModel>, AxiosError>(
    ['DUPLICATAS', nfId],
    () => {
      return obterDuplicatasPorNFId(nfId)
    },
  )
}

export function useRegistrarMultiplosBoletos() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError<ErrorInterface>, RegistrarBoletoModel>(
    async (data) => {
      const promises = data?.contasReceber?.map((item) =>
        registrarBoleto({
          categoriaId: data?.categoriaId
            ? data?.categoriaId
            : item?.categoriaId || '',
          contaFinanceiraId: data?.contaFinanceiraId,
          contaReceberId: item?.id || '',
        }),
      )

      return Promise.all(promises)
    },
    {
      onSuccess() {
        notifications.notifySuccess(`Boletos Registrados com sucesso!`, {
          position: 'top-right',
        })
        queryClient.invalidateQueries(['DUPLICATAS'])
      },
      onError: (error) => {
        notifications.notifyException(error, {
          position: 'top-right',
        })
      },
    },
  )
}

export default function useDuplicatas() {
  return {
    useQueryObterDuplicatasPorNFId,
    useRegistrarMultiplosBoletos,
  }
}
