import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Box, Stack, Typography } from '@mui/material'

import { formatCurrency, useForm } from '@data-c/hooks'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useNF, { NFModel, nfModeloMapping } from 'hooks/queries/useNF'
import useCapitalize from 'hooks/useCapitalize'

import CardInfo from 'components/CardInfo'
import FloatFooter from 'components/FloatFooter'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'
import ContentContainer from 'components/Container/components/ContentContainer'
import Button from 'components/Button'

import CardFormasPagamento from '../DetalhamentoNF/components/CardFormasPagamento'
import successImage from 'assets/images/success.svg'

export default function NFCancelada() {
  const { nfId } = useParams()
  const navigate = useNavigate()

  const { mensagemSucesso, setMensagemSucesso } = useNFContext()
  const { capitalizeFirstLetter } = useCapitalize()
  const { useQueryObterNFPorId } = useNF()
  const { data: nf, isLoading, error } = useQueryObterNFPorId(nfId || '')

  const { data, setData } = useForm<NFModel>({} as NFModel)

  useEffect(() => {
    if (nf) {
      setData(nf)
    }
  }, [nf])

  function handleBack() {
    setMensagemSucesso(false)
    navigate('/vendas')
  }

  const dataFormatada = useMemo(() => {
    const endereco = `${data?.pessoa?.logradouro || ''}, ${
      data?.pessoa?.cidadeNome || ''
    } - ${data?.pessoa?.cidadeUF || ''}`

    return {
      ...data,
      operacaoNome: data?.operacao?.nome || '',
      clienteNome: data?.pessoa?.nome || '',
      totalNFFormatado: formatCurrency(data?.totalNF || 0),
      pagamentoTotalFormatado: formatCurrency(data?.pagamentoTotal || 0),
      pagamentoTrocoFormatado: formatCurrency(data?.pagamentoTroco || 0),
      documentoIdFormatado: formatToCPFOrCNPJ(data?.pessoa?.documentoId || ''),
      enderecoFormatado: capitalizeFirstLetter(endereco),
      descontoFormatado: formatCurrency(data?.totalDesconto || 0),
    }
  }, [data])

  const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]

  return (
    <Container fullHeight paper>
      <PageTitle title="" onClose={handleBack} />

      <ContentContainer
        disablePadding
        isLoading={isLoading}
        error={error}
        customSx={{ paddingBottom: '80px' }}
      >
        {mensagemSucesso && (
          <Stack
            sx={{
              alignItems: 'center',
              gap: 3,
              padding: 2,
            }}
          >
            <img
              src={successImage}
              width="148px"
              alt="Imagem nota fiscal cancelada com sucesso"
            />

            <Typography variant="h1" fontSize="28px" fontWeight="medium">
              Pronto, {nfModeloDescricao} cancelada com sucesso!
            </Typography>
          </Stack>
        )}

        <Box padding={1}>
          <CardInfo title="Status" subtitle={data?.statusDesc || ''} />
          <CardInfo title="Operação" subtitle={dataFormatada.operacaoNome} />
          <CardInfo title="Cliente" subtitle={dataFormatada.clienteNome}>
            <Typography variant="body2">
              {dataFormatada.documentoIdFormatado}
            </Typography>
            <Typography variant="body2">
              {dataFormatada.enderecoFormatado}
            </Typography>
          </CardInfo>
          <CardInfo title="Formas de Pagamento" subtitle="">
            <CardFormasPagamento
              nfPagamentos={data?.nfPagamentos || []}
              onClick={() => {}}
            />
          </CardInfo>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
            }}
          >
            <CardInfo
              title="Valor total"
              subtitle={`R$ ${dataFormatada.totalNFFormatado}`}
            />
            <CardInfo
              title="Total pagamento"
              subtitle={`R$ ${dataFormatada.pagamentoTotalFormatado}`}
              textAlign="end"
            />
            <CardInfo
              title="Desconto"
              subtitle={`R$ ${dataFormatada.descontoFormatado}`}
            />

            <CardInfo
              title="Troco"
              subtitle={`R$ ${dataFormatada.pagamentoTrocoFormatado}`}
              textAlign="end"
            />
          </Box>
        </Box>
      </ContentContainer>

      <FloatFooter>
        <Button fullWidth variant="contained" onClick={handleBack}>
          Voltar às Vendas
        </Button>
      </FloatFooter>
    </Container>
  )
}
