import { ReactNode } from 'react'
import { Stack, StackProps } from '@mui/material'

interface ButtonContainerProps extends StackProps {
  children: ReactNode
}

export default function ButtonContainer(props: ButtonContainerProps) {
  const { children, ...rest } = props

  return (
    <Stack gap={1.5} {...rest}>
      {children}
    </Stack>
  )
}
