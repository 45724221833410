import { Stack, TextField } from '@mui/material'
import { useFilterApi, useFilterData } from '@data-c/providers'

import DropDownTipoInternoOperacao, {
  nfTipoInternoOperacaoMapping,
} from 'components/Inputs/DropDownTipoInternoOperacao'
import DropDownTransmissao, {
  DropDownTransmissaoEnum,
  nfTipoTransmissao,
} from 'components/Inputs/DropDownTransmissao'
import DropDownNFTipoData, {
  DropDownNFTipoDataEnum,
  nfTipoDataMapping,
} from 'components/Inputs/DropDownNFTipoData'
import DropDownStatus, {
  nfStatusMapping,
} from 'components/Inputs/DropDownStatus'
import AutoCompleteEmpresas from 'components/AutoCompleteEmpresa'
import DropDownNFModelo from 'components/Inputs/DropDownNFModelo'
import FilterContainer from 'components/FilterContainer'
import DatePicker from 'components/DatePicker'

import {
  dateEndOfMonth,
  dateStartOfMonth,
  formatDateToDiaMesAno,
} from 'hooks/queries/useDateFormat'
import {
  NFConsultaFilters,
  NFConsultaFiltersOptions,
  nfModeloMapping,
} from 'hooks/queries/useNF'

import FilterProps from 'interfaces/FilterProps'

export function formatarFiltros(
  filters?: NFConsultaFiltersOptions,
): NFConsultaFiltersOptions {
  const operacaoInterna =
    filters?.operacaoInterna === -1 ? null : filters?.operacaoInterna
  const status = filters?.status === -1 ? null : filters?.status
  const modelo = filters?.modelo === -1 ? null : filters?.modelo

  return {
    ...filters,
    dataInicio: filters?.dataInicio || dateStartOfMonth(),
    dataFim: filters?.dataFim || dateEndOfMonth(),
    tipoTransmissao:
      filters?.tipoTransmissao || DropDownTransmissaoEnum.TODAS_0,
    nfConsultaTipoData:
      filters?.nfConsultaTipoData || DropDownNFTipoDataEnum.EMISSAO_1,
    empresaId: filters?.empresa?.id || null,
    destinatarioId: filters?.destinatario?.id || null,
    operacaoInterna,
    status,
    modelo,
  }
}

export function formatarFilterLabels(filterLabel: string, filterValue: any) {
  switch (filterLabel) {
    case 'Início':
      return `${filterLabel}: ${formatDateToDiaMesAno(filterValue || '')}`
    case 'Fim':
      return `${filterLabel}: ${formatDateToDiaMesAno(filterValue || '')}`
    case 'Status':
      return `${filterLabel}: ${nfStatusMapping[filterValue]}`
    case 'Transmissão':
      return `${filterLabel}: ${nfTipoTransmissao[filterValue]}`
    case 'Empresa':
      return `${filterLabel}: ${filterValue?.nome || ''}`
    case 'Destinatário':
      return `${filterLabel}: ${filterValue?.nome || ''}`
    case 'Operação':
      return `${filterLabel}: ${nfTipoInternoOperacaoMapping[filterValue]}`
    case 'Modelo':
      return `${filterLabel}: ${nfModeloMapping[filterValue]}`
    case 'Tipo':
      return `${filterLabel}: ${nfTipoDataMapping[filterValue]}`
    default:
      return `${filterLabel}: ${filterValue}`
  }
}

export default function Filter(props: FilterProps<NFConsultaFiltersOptions>) {
  const { onApplyFilters } = props

  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<
    NFConsultaFilters,
    NFConsultaFiltersOptions
  >()

  return (
    <FilterContainer
      title="Opções de Filtro"
      triggerButtonLabel="Filtros"
      onApplyFilters={onApplyFilters}
    >
      <Stack spacing={2}>
        <AutoCompleteEmpresas
          name="empresaId"
          label={filterValues?.empresa?.label}
          value={filterValues?.empresa?.value || null}
          onChange={(_, value) => changeFilterValue('empresa', value)}
        />
        <DropDownNFTipoData
          label={filterValues?.nfConsultaTipoData?.label}
          value={filterValues?.nfConsultaTipoData?.value}
          onChange={(e) => {
            changeFilterValue('nfConsultaTipoData', e.target.value)
          }}
        />
        <Stack flexDirection="row" gap={1}>
          <DatePicker
            value={filterValues?.dataInicio?.value}
            label={filterValues?.dataInicio?.label}
            onChange={(date) => changeFilterValue('dataInicio', date)}
          />
          <DatePicker
            value={filterValues?.dataFim?.value}
            label={filterValues?.dataFim?.label}
            onChange={(date) => changeFilterValue('dataFim', date)}
          />
        </Stack>
        <DropDownTipoInternoOperacao
          value={filterValues?.operacaoInterna?.value}
          label={filterValues?.operacaoInterna?.label}
          onChange={(value) => changeFilterValue('operacaoInterna', value)}
        />
        <DropDownTransmissao
          value={filterValues?.tipoTransmissao?.value}
          label={filterValues?.tipoTransmissao?.label}
          onChange={(e) => changeFilterValue('tipoTransmissao', e.target.value)}
        />
        <Stack flexDirection="row" gap={1}>
          <DropDownStatus
            value={filterValues?.status?.value}
            label={filterValues?.status?.label}
            onChange={(e) =>
              changeFilterValue('status', e.target.value || null)
            }
          />
          <DropDownNFModelo
            label={filterValues?.modelo?.label}
            value={filterValues?.modelo?.value}
            onChange={(e) =>
              changeFilterValue('modelo', e.target.value || null)
            }
          />
        </Stack>
        <Stack flexDirection="row" gap={1}>
          <TextField
            label={filterValues?.serie?.label}
            value={filterValues?.serie?.value}
            inputProps={{ maxLength: 9 }}
            onChange={(e) => changeFilterValue('serie', e.target.value || null)}
          />
          <TextField
            label={filterValues?.numeroNF?.label}
            value={filterValues?.numeroNF?.value}
            inputProps={{ maxLength: 9 }}
            onChange={(e) =>
              changeFilterValue('numeroNF', e.target.value || null)
            }
          />
        </Stack>
      </Stack>
    </FilterContainer>
  )
}
