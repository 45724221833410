import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import useAutocomplete, { AutoCompleteProps } from 'hooks/useAutocomplete'
import useCidades, { CidadeModel } from 'hooks/queries/useCidade'

interface AutoCompleteCidadeProps extends AutoCompleteProps<CidadeModel> {
  uf: string | null
}

interface QueryParams {
  uf?: string | null
}

export default function AutoCompleteCidade(props: AutoCompleteCidadeProps) {
  const { onChange, value, uf, name, label, required, helperText, error } =
    props

  const { obterCidades } = useCidades()

  const { options, onInputChange, inputValue, isLoading, setOptions } =
    useAutocomplete<CidadeModel, QueryParams>({
      search: async (query: string, params: QueryParams) => {
        const response = await obterCidades(query, params?.uf)
        if (response) {
          return response.data.data
        }
      },
      params: { uf: uf },
    })

  async function handleObterCidades() {
    if (!uf) return

    try {
      const response = await obterCidades('', uf)

      if (!response) return
      setOptions(response.data.data)
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <Autocomplete
      options={options}
      onOpen={handleObterCidades}
      value={value || null}
      onChange={onChange}
      getOptionLabel={(cidade: CidadeModel) => {
        return `${cidade.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={name}
          label={label}
          required={required}
          helperText={helperText}
          error={error}
          {...params}
        />
      )}
      onInputChange={onInputChange}
      loading={isLoading}
      loadingText={'Carregando'}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
    />
  )
}
