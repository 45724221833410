import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import useEmpresa, { EmpresaModel } from 'hooks/queries/useEmpresa'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'
import EmptyData from 'components/EmptyData'

import ListItens from './components/ListItens'

export default function Empresas() {
  const navigate = useNavigate()

  const { useQueryObterEmpresas } = useEmpresa()
  const { data: empresas, isFetching, error } = useQueryObterEmpresas()

  function handleClick(empresa: EmpresaModel) {
    navigate('/boletos', {
      state: { empresa },
    })
  }

  const empresasAtivas =
    empresas?.data?.filter((empresa) => empresa.ativo === true) || []

  return (
    <Container paper>
      <PageTitle
        title="Empresa"
        onBack={() => navigate('/vendas')}
        onClose={() => navigate('/vendas')}
      />

      <Typography
        variant="h1"
        fontSize="32px"
        fontWeight="medium"
        marginBottom={2}
        padding={2}
      >
        Selecione a empresa para visualizar os boletos
      </Typography>

      <ContentContainer isLoading={isFetching} error={error} disablePadding>
        {empresasAtivas?.length > 0 ? (
          <ListItens
            itens={empresasAtivas}
            onClick={(empresa) => handleClick(empresa)}
          />
        ) : (
          <EmptyData description="Nenhuma empresa foi encontrada" />
        )}
      </ContentContainer>
    </Container>
  )
}
