import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Stack } from '@mui/material'

import { FilterProvider } from '@data-c/providers'

import { FilterControl } from 'components/FilterContainer'
import HeaderContainer from 'components/Container/components/HeaderContainer'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'

import SelectableCardProvider from 'contexts/SelectableCardContext'

import { BoletoFiltersOptions, boletofilters } from 'hooks/queries/useBoleto'
import { EmpresaModel } from 'hooks/queries/useEmpresa'
import useCredentials from 'hooks/useCredentials'

import Filter, { formatarFilterLabels } from './components/Filter'
import Lista from './components/Lista'

interface LocationState {
  empresa?: EmpresaModel
}

export default function Boletos() {
  const { state } = useLocation()
  const navigate = useNavigate()

  const { userLogged } = useCredentials()
  const possuePermissaoHubbank = userLogged?.permissoes['stella-hubbank']

  useEffect(() => {
    if (!possuePermissaoHubbank) {
      navigate('/vendas')
    }
  }, [possuePermissaoHubbank])

  const [appliedFilters, setFilters] = useState<BoletoFiltersOptions>(
    {} as BoletoFiltersOptions,
  )

  const { empresa } = (state as LocationState) || {}

  const parsedFilters = useMemo(() => {
    return {
      ...boletofilters,
      empresa: {
        label: 'Empresa',
        value: empresa,
      },
    }
  }, [])

  return (
    <Container fullHeight={false}>
      <FilterProvider filterValues={parsedFilters}>
        <HeaderContainer>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
          >
            <PageTitle
              title="Boletos"
              disablePadding
              onBack={() => navigate('/vendas')}
            />
            <Filter onApplyFilters={(filters) => setFilters(filters)} />
          </Stack>
          <FilterControl
            renderLabel={formatarFilterLabels}
            initialFilters={parsedFilters}
            disableds={['dataInicio', 'dataFim', 'tipoData', 'empresa']}
          />
        </HeaderContainer>

        <SelectableCardProvider>
          <Lista
            filters={{
              ...appliedFilters,
              empresa: appliedFilters?.empresa || empresa,
            }}
          />
        </SelectableCardProvider>
      </FilterProvider>
    </Container>
  )
}
