import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  BottomNavigation as MuiBottomNavigation,
  Box,
  BottomNavigationAction as MuiBottomNavigationAction,
  useTheme,
  IconButton,
} from '@mui/material'
import {
  Add,
  HomeOutlined,
  ShoppingCartOutlined,
  RequestPage as RequestPageIcon,
} from '@mui/icons-material'

import FixedContainer from 'components/FixedContainer'

import { EmpresaModel } from 'hooks/queries/useEmpresa'
import useCredentials from 'hooks/useCredentials'

interface NavigationBarProps {
  empresas: EmpresaModel[]
  icon?: ReactNode
  action?: ReactNode
  onClick?: () => void
}

export default function BottomNavigation(props: NavigationBarProps) {
  const { empresas, icon, action, onClick } = props

  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const { userLogged } = useCredentials()
  const possuePermissaoHubbank = userLogged?.permissoes['stella-hubbank']

  const [value, setValue] = useState<number | string>(0)

  useEffect(() => {
    setValue(location.pathname)
  }, [location.pathname])

  function handleChangeNavigation(
    _: SyntheticEvent<Element, Event>,
    newValue: any,
  ) {
    if (newValue === '/boletos') {
      const empresaUnica = empresas.length === 1
      const targetRoute = empresaUnica ? newValue : '/empresas'
      const state = empresaUnica
        ? { state: { empresa: empresas[0] } }
        : undefined

      navigate(targetRoute, state)
    } else {
      navigate(newValue)
    }
  }

  return (
    <FixedContainer customSx={{ zIndex: 99 }} fixIn="bottom">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingX: 2,
          paddingY: 1,
          backgroundColor: theme.palette.background.paper,
          // borderTop: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '768px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MuiBottomNavigation
            showLabels
            value={value}
            onChange={handleChangeNavigation}
          >
            <MuiBottomNavigationAction
              label="Vendas"
              value="/vendas"
              icon={<ShoppingCartOutlined />}
            />
            <MuiBottomNavigationAction
              label="Clientes"
              value="/clientes/lista"
              icon={<HomeOutlined />}
            />
            {possuePermissaoHubbank && (
              <MuiBottomNavigationAction
                label="Boletos"
                value="/boletos"
                icon={<RequestPageIcon />}
              />
            )}
          </MuiBottomNavigation>

          {action ? (
            action
          ) : (
            <IconButton
              size="large"
              onClick={onClick}
              sx={{
                width: '64px',
                height: '64px',
                borderRadius: '100%',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.paper,
                ':hover': { backgroundColor: theme.palette.primary.dark },
              }}
            >
              {icon ? icon : <Add fontSize="medium" />}
            </IconButton>
          )}
        </Box>
      </Box>
    </FixedContainer>
  )
}
