import useNotification from 'hooks/useNotification'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

interface SelectableCardData<T> {
  itensSelecionados: T[]
  setItemSelecionado: (selecionado: T) => void
  onClearIntesSelecionados: () => void
  onSelecionarTodosItens: (itens: T[], message?: string) => void
  onRemoverTodosIntesSelecionados: (message?: string) => void
}

interface SelectableCardProps {
  children: ReactNode
}

export const SelectableCardContext = createContext(
  {} as SelectableCardData<any>,
)

export default function SelectableCardProvider<
  T extends { [key: string]: any },
>({ children }: SelectableCardProps) {
  const notification = useNotification()

  const [selecionados, setSelecionados] = useState<T[]>([])

  function clearItensSelecionados() {
    setSelecionados([])
  }

  const handleSetItensSelecionado = useCallback((selecionado: T) => {
    setSelecionados((prevState) => {
      const isJaSelecionado = prevState.some(
        (item) => item?.id === selecionado?.id,
      )

      if (isJaSelecionado) {
        return prevState.filter((item) => item?.id !== selecionado?.id)
      } else {
        return [...prevState, selecionado]
      }
    })
  }, [])

  function handleRemoverTodosItensSelecionados(message?: string) {
    if (selecionados.length === 0 && message) {
      notification.notifyInfo(message, {
        position: 'top-right',
      })
      return
    }
    setSelecionados([])
  }

  function handleSelecionarTodosItens(itens: T[], message?: string) {
    if (itens.length === 0 && message) {
      notification.notifyInfo(message, {
        position: 'top-right',
      })
      return
    }

    setSelecionados(itens)
  }

  return (
    <SelectableCardContext.Provider
      value={{
        itensSelecionados: selecionados,
        setItemSelecionado: handleSetItensSelecionado,
        onClearIntesSelecionados: clearItensSelecionados,
        onSelecionarTodosItens: handleSelecionarTodosItens,
        onRemoverTodosIntesSelecionados: handleRemoverTodosItensSelecionados,
      }}
    >
      {children}
    </SelectableCardContext.Provider>
  )
}

export function useSelectableCardContext<T>() {
  return useContext<SelectableCardData<T>>(SelectableCardContext)
}
