import { Stack, Typography } from '@mui/material'
import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'

interface FooterProps {
  valorTituloFormatado: string
  dataVencimentoFormatada: string
}

export default function Footer(props: FooterProps) {
  const { valorTituloFormatado, dataVencimentoFormatada } = props

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: 1,
        paddingX: 2,
      }}
    >
      <Typography variant="body2">
        <strong>Vencimento: </strong>
        {dataVencimentoFormatada || '---'}
      </Typography>
      <FloatFooterCurrency
        title="Título"
        price={valorTituloFormatado}
        priceProps={{ variant: 'h4', fontWeight: 'bold' }}
      />
    </Stack>
  )
}
