import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { RelatorioModel } from 'hooks/queries/usePDF'
import useNF, { NFModel } from 'hooks/queries/useNF'
import useArrayManipulations from 'hooks/useArrayManipulations'
import useValidations from 'hooks/useValidation'

import FloatFooter from 'components/FloatFooter'
import TextFieldDynamic from 'components/TextFieldDynamic'
import Button from 'components/Button'

import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup.string().required('Informe um e-mail'),
})

interface FormProps {
  nf?: NFModel
  relatorio: RelatorioModel[]
}

export default function Form(props: FormProps) {
  const { relatorio, nf } = props

  const navigate = useNavigate()

  const { setValidationErrors, validationProps } = useValidations()
  const { stringifyArray } = useArrayManipulations()

  const { useEnviarEmailNF } = useNF()
  const {
    mutateAsync: enviarEmail,
    isLoading: isLoadingEnviarEmail,
    status,
  } = useEnviarEmailNF()

  const [data, setData] = useState<{ emails: string[] }>({ emails: [] })

  function handleSubmit() {
    const newData = stringifyArray(data.emails)

    setValidationErrors(null)
    schema
      .validate({ email: newData }, { abortEarly: false })
      .then(async () => {
        const relatorioId = relatorio[0]?.id || ''
        const nfIds = [nf?.id || '']

        await enviarEmail({
          emails: newData,
          nfIds,
          relatorioId,
        }).then(() => {
          navigate(`/NF/${nf?.id}/email-enviado`, {
            state: { emailsEnviados: newData },
          })
        })
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Box sx={{ marginBottom: 10 }}>
      <TextFieldDynamic
        disabledAddButton={isLoadingEnviarEmail}
        inputProps={{
          label: 'E-mail',
          name: 'email',
          autoFocus: true,
          ...validationProps('email'),
        }}
        reset={status === 'success'}
        onChange={(_emails) => {
          const emails = _emails.map((_email) => _email.value)
          setData({
            ...data,
            emails,
          })
        }}
      />

      <FloatFooter>
        <Button
          fullWidth
          variant="contained"
          isLoading={isLoadingEnviarEmail}
          onClick={handleSubmit}
        >
          {data.emails.length === 1 ? 'Enviar email' : 'Enviar emails'}
        </Button>
      </FloatFooter>
    </Box>
  )
}
