import { ReactNode, useMemo } from 'react'
import { Typography, TypographyProps } from '@mui/material'

interface SelectableCountTextProps {
  countQuantidade?: number
  gender?: 'masculino' | 'feminino'
  typographyProps?: TypographyProps
  children?: ReactNode
}

export default function SelectableCountText(props: SelectableCountTextProps) {
  const { countQuantidade, gender, typographyProps } = props

  const flexSufixCountTitle = useMemo(() => {
    const selecionadoText =
      gender === 'feminino' ? 'selecionada' : 'selecionado'
    const selecionadosText =
      gender === 'feminino' ? 'selecionadas' : 'selecionados'
    const nenhumText = gender === 'feminino' ? 'Nenhuma' : 'Nenhum'

    return countQuantidade === 1
      ? `${selecionadoText}`
      : countQuantidade && countQuantidade > 1
      ? `${selecionadosText}`
      : `${nenhumText} ${selecionadoText}`
  }, [countQuantidade])

  return (
    <Typography variant="button" fontSize="16px" {...typographyProps}>
      {countQuantidade && countQuantidade > 0 ? countQuantidade : ''}{' '}
      {flexSufixCountTitle}
    </Typography>
  )
}
