import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { v4 } from 'uuid'
import { Stack, Typography, useTheme } from '@mui/material'

import { SearchTextField } from '@data-c/ui'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useCliente from 'hooks/queries/useCliente'
import useNF from 'hooks/queries/useNF'

import ListItens from './components/ListItens'
import Container from 'components/Container'

import Button from 'components/Button'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'

export default function Clientes() {
  const [query, setQuery] = useState('')

  const navigate = useNavigate()
  const theme = useTheme()

  const { setNFIds, nfIds } = useNFContext()
  const { useQueryObterClientes } = useCliente()
  const { useAdicionarNF } = useNF()
  const { mutateAsync: adicionarNF, isLoading: isLoadingAdicionarNF } =
    useAdicionarNF()
  const { data: clientes, isFetching, error } = useQueryObterClientes(query)

  async function handleClick(pessoaId: string) {
    let nfId = nfIds.id

    if (nfId?.length === 0 || nfId === null) {
      nfId = v4()
    }

    setNFIds('pessoaId', pessoaId)
    setNFIds('id', nfId)

    await adicionarNF({
      operacaoId: nfIds.operacaoId,
      empresaId: nfIds.empresaId,
      id: nfId,
      pessoaId,
    })

    setNFIds('id', '')

    navigate(`/NF/${nfId}/produtos`)
  }

  return (
    <Container paper>
      <PageTitle
        title="Clientes"
        onBack={() => navigate('/NF/operacao')}
        onClose={() => navigate('/vendas')}
      />
      <Stack spacing={3} padding={2} marginBottom={2}>
        <Stack spacing={1}>
          <Typography
            variant="h1"
            fontSize="28px"
            fontWeight="medium"
            marginBottom={1}
          >
            Para quem você deseja fazer o pedido?
          </Typography>
          <Typography
            variant="h5"
            color={theme.palette.grey[500]}
            fontWeight="bold"
          >
            Encontre um cliente na sua lista ou cadastre um novo cliente
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <SearchTextField
            onSearch={(query) => setQuery(query)}
            placeholder="Pesquisar clientes"
            autoFocus={false}
          />

          <Button
            variant="contained"
            sx={{ textTransform: 'uppercase' }}
            onClick={() => navigate('cadastro-cliente')}
          >
            Cadastrar cliente
          </Button>
        </Stack>
      </Stack>

      <ContentContainer disablePadding isLoading={isFetching} error={error}>
        <ListItens
          itens={clientes?.data || []}
          onClick={(pessoaId) => handleClick(pessoaId)}
          isLoading={isLoadingAdicionarNF}
        />
      </ContentContainer>
    </Container>
  )
}
