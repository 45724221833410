import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
  useTheme,
} from '@mui/material'
import { CSSProperties } from 'react'

interface IconButtonProps extends MUIIconButtonProps {
  customSx?: CSSProperties
}

export default function IconButton(props: IconButtonProps) {
  const { customSx, ...rest } = props
  const theme = useTheme()

  return (
    <MUIIconButton
      size="medium"
      sx={{
        ...customSx,
        width: '64px',
        height: '64px',
        borderRadius: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        ':hover': { backgroundColor: theme.palette.primary.dark },
      }}
      {...rest}
    />
  )
}
