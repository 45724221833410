import { ItemProps } from 'pages/NF/Produtos/components/ProdutoCardInfo'
import { NFModel } from '../useNF'
import { ProdutoModel } from '../useProduto'

function atualizarValoresItemPedido(
  produto: ProdutoModel,
  nf?: NFModel,
  origin?: 'paginaDoProduto',
): ItemProps {
  const nfItem = nf?.nfItems?.find((item) => item.produtoId === produto.id)

  if (nfItem) {
    return {
      ...nfItem,
      nome: nfItem?.produto?.nome || '',
      estoque: nfItem?.produto?.estoque,
      nfItemId: nfItem?.id,
      nf,
    }
  }

  let quantidade = 0

  if (origin) {
    quantidade = 1
  }

  return {
    nome: produto.nome,
    produtoId: produto?.id || '',
    quantidade,
    codigo: produto.codigo,
    unitarioBruto: produto?.precoUnitario,
    estoque: produto?.estoque,
    totalBruto: produto?.precoUnitario,
    unitario: produto?.precoUnitario,
    total: produto?.precoUnitario,
    produto,
    nf,
  }
}

export default function useAtualizarValoresItemPedido() {
  return {
    atualizarValoresItemPedido,
  }
}
