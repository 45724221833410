import { Checkbox } from '@data-c/ui'
import { Box, Typography, useTheme } from '@mui/material'
import {
  boletoStatusColors,
  boletoStatusMappingOpacityColors,
} from 'components/Inputs/DropDownBoletoStatus'

interface HeaderProps {
  dataAlteracaoFormatada: string
  statusDesc?: string | null
  status: number
  isSelected: boolean
  onSelect: () => void
}

export default function Header(props: HeaderProps) {
  const { dataAlteracaoFormatada, statusDesc, status, isSelected, onSelect } =
    props

  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: 1,
        paddingX: 2,
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        backgroundColor: isSelected
          ? boletoStatusMappingOpacityColors[status]
          : '',
      }}
    >
      <Checkbox
        label={statusDesc || ''}
        checked={isSelected}
        onChange={onSelect}
        color={boletoStatusColors[status]}
      />
      <Typography variant="body2">
        <strong>Alteração:</strong> {dataAlteracaoFormatada}
      </Typography>
    </Box>
  )
}
