import { Dialog } from '@data-c/ui'
import Button from 'components/Button'
import { NFModel } from 'hooks/queries/useNF'

interface DialogXMLErrorProps {
  data: NFModel
  isOpen: boolean
  onClose: () => void
}

export default function DialogXMLError(props: DialogXMLErrorProps) {
  const { data, isOpen, onClose } = props

  return (
    <Dialog
      open={isOpen}
      type="error"
      title="Erros encontrados ao validar schema XML da NF-e/NFC-e"
      maxWidth="xs"
      actions={
        <Button variant="contained" onClick={onClose}>
          Fechar
        </Button>
      }
    >
      {data?.nFeErrosSchema && data?.nFeErrosSchema[0]?.message}
    </Dialog>
  )
}
