import { ReactNode } from 'react'
import { Paper, SxProps } from '@mui/material'
import Container from 'components/Container'

interface HeaderContainerProps {
  children: ReactNode
  paperElevation?: boolean
  disableFixed?: boolean
  customSx?: SxProps
  containerSx?: SxProps
}

export default function HeaderContainer(props: HeaderContainerProps) {
  const {
    children,
    paperElevation = false,
    disableFixed = false,
    customSx,
    containerSx,
  } = props

  return (
    <Paper
      elevation={paperElevation ? 1 : 0}
      sx={{
        paddingY: 2,
        paddingX: 1,
        position: disableFixed ? 'relative' : 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        ...customSx,
      }}
    >
      <Container overflow="visible" fullHeight={false} customSx={containerSx}>
        {children}
      </Container>
    </Paper>
  )
}
