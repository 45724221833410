import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { LoadingButton as Button } from '@mui/lab'
import { Replay } from '@mui/icons-material'

import errorImage from 'assets/images/error.svg'

interface ErrorProps {
  description?: string
  onClick?: () => void
}

export default function Error(props: ErrorProps) {
  const { description, onClick } = props

  const [isLoading, setLoading] = useState(false)

  function handleReloadPage() {
    setLoading(true)
    window.location.reload()
  }

  function handleClick() {
    if (onClick) {
      onClick()
    } else {
      handleReloadPage()
    }
  }

  return (
    <Stack sx={{ alignItems: 'center', mt: 8, padding: 4, gap: 4 }}>
      <img src={errorImage} alt="Imagem de erro" />

      <Stack sx={{ maxWidth: '554px', textAlign: 'center', gap: 2 }}>
        <Typography variant="h1">Ops! Algo deu errado</Typography>

        {description ? (
          <Typography variant="body2">{description}</Typography>
        ) : (
          <Typography variant="body2">
            Desculpe, no momento não foi possível processar a sua requisição.
            <br />
            Tente novamente mais tarde ou entre em contato com o nosso suporte
            para mais informações
          </Typography>
        )}
      </Stack>

      {onClick && (
        <Button
          variant="contained"
          onClick={handleClick}
          loading={isLoading}
          startIcon={<Replay />}
        >
          Tentar novamente
        </Button>
      )}
    </Stack>
  )
}
