import CardInfo, { CardInfoActions } from 'components/CardInfo'
import { useFormApi } from '@data-c/providers'
import { NFModel } from 'hooks/queries/useNF'
import Form from './components/Form'

interface InteresseDoContribuinteProps {
  nf?: NFModel
}

export default function InteresseDoContribuinte({
  nf,
}: InteresseDoContribuinteProps) {
  const { openForm } = useFormApi<NFModel>()

  return (
    <CardInfo
      subtitle="Interesse do Contribuínte"
      contentText={nf?.informacoesComplementares}
    >
      <CardInfoActions
        showAddButton={!nf?.informacoesComplementares}
        onClick={() => {
          openForm(nf)
        }}
      />

      <Form />
    </CardInfo>
  )
}
