import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum DropDownNFTipoDataEnum {
  LANCAMENTO_0 = 0,
  EMISSAO_1 = 1,
  SAIDA_2 = 2,
}

export const nfTipoDataMapping: Record<string | number, string> = {
  0: 'Data de Lançamento',
  1: 'Data de Emissão',
  2: 'Data de Saída',
}

export default function DropDownNFTipoData(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value="0">Lancamento</MenuItem>
      <MenuItem value="1">Emissão</MenuItem>
      <MenuItem value="2">Saída</MenuItem>
    </TextField>
  )
}
