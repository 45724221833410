import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Container from 'components/Container'
import PageTitle from 'components/PageTitle'

import SelectableCardProvider from 'contexts/SelectableCardContext'
import useCredentials from 'hooks/useCredentials'
import Lista from './components/Lista'

export default function GeracaoDeBoleto() {
  const { nfId } = useParams()
  const navigate = useNavigate()

  const { userLogged } = useCredentials()

  const possuePermissaoHubbank = userLogged?.permissoes['stella-hubbank']

  useEffect(() => {
    if (!possuePermissaoHubbank) {
      navigate('/vendas')
    }
  }, [possuePermissaoHubbank])

  return (
    <Container fullHeight={false}>
      <PageTitle
        title="Emitir Boletos"
        onBack={() => navigate(`/NF/${nfId}/nf-fechada`)}
        onClose={() => navigate('/vendas')}
      />

      <SelectableCardProvider>
        <Lista nfId={nfId || ''} />
      </SelectableCardProvider>
    </Container>
  )
}
