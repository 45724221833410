import { Grid, TextField } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import FormDrawerContainer from 'components/FormDrawerContainer'

import useNFOutrasInformacoes, {
  DocumentoReferenciadoModel,
} from 'hooks/queries/useNFOutrasInformacoes'
import useValidations from 'hooks/useValidation'

import * as yup from 'yup'

const schema = yup.object().shape({
  chaveAcesso: yup.string().required('Informe a Chave de Acesso'),
})

export default function Form() {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data } = useFormData<DocumentoReferenciadoModel>()
  const { changeValue, toggleSubmit, closeForm } =
    useFormApi<DocumentoReferenciadoModel>()

  const { useAdicionarDocumentoReferenciadoNF } = useNFOutrasInformacoes()
  const { mutateAsync: adicionarDocumentoReferenciadoNF } =
    useAdicionarDocumentoReferenciadoNF()

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await adicionarDocumentoReferenciadoNF(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormDrawerContainer
      title="Adicionar Documento Referenciado"
      onSubmit={handleSubmit}
    >
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          name="chaveAcesso"
          label="Chave de Acesso"
          required
          multiline
          rows={10}
          inputProps={{ maxLength: 44 }}
          value={data?.chaveAcesso || ''}
          onChange={(e) => changeValue('chaveAcesso', e.target.value)}
          {...validationProps('chaveAcesso')}
        />
      </Grid>
    </FormDrawerContainer>
  )
}
