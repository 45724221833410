import { ReactNode } from 'react'

import { ButtonProps, DrawerProps, Typography, useTheme } from '@mui/material'
import { FilterList as FilterListIcon } from '@mui/icons-material'

import { useFilterApi } from '@data-c/providers'
import Button from 'components/Button'
import FilterDrawerContainer from './components/FilterDrawerContainer'
export { default as FilterControl } from './components/FilterControl'

export interface FilterProps {
  children: ReactNode
  triggerButton?: 'none' | React.ReactNode
  triggerButtonLabel?: string
  triggerButtonProps?: ButtonProps
  title?: string
  onApplyFilters?: (filters: any) => void
  drawerProps?: DrawerProps
}

export default function FilterContainer(props: FilterProps) {
  const {
    triggerButtonLabel,
    triggerButtonProps,
    triggerButton,
    title,
    children,
    onApplyFilters,
    drawerProps,
  } = props
  const { openFilter } = useFilterApi()

  const theme = useTheme()

  const _triggerButton =
    triggerButton === 'none'
      ? null
      : triggerButton || (
          <Button
            variant="text"
            endIcon={<FilterListIcon fontSize="medium" />}
            onClick={openFilter}
            sx={{ color: theme.palette.grey[500] }}
            {...triggerButtonProps}
          >
            {triggerButtonLabel && (
              <Typography variant="body2">{triggerButtonLabel}</Typography>
            )}
          </Button>
        )

  return (
    <>
      {_triggerButton}

      <FilterDrawerContainer
        {...drawerProps}
        onApplyFilters={onApplyFilters}
        title={title || ''}
        children={children}
      />
    </>
  )
}
