import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useNF from 'hooks/queries/useNF'

import PageTitle from 'components/PageTitle'
import ContentContainer from 'components/Container/components/ContentContainer'
import Container from 'components/Container'

import Header from './Header'
import SendEmailsList from './SendEmailsList'
import Footer from './Footer'

export default function EmailEnviado() {
  const { nfId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { useQueryObterNFPorId } = useNF()
  const { data: nf, isLoading, error } = useQueryObterNFPorId(nfId || '')

  function handleClose() {
    navigate('/vendas')
  }

  const emailsEnviados = location.state?.emailsEnviados as string | undefined
  const arrayEmailsEnviados = emailsEnviados?.split(',')

  return (
    <Container paper>
      <PageTitle title="" onClose={handleClose} />
      <ContentContainer isLoading={isLoading} error={error}>
        <Header
          modelo={nf?.modelo || 0}
          emailsEnviados={arrayEmailsEnviados || []}
        />
        <SendEmailsList emailsEnviados={arrayEmailsEnviados || []} />
        <Footer nfId={nf?.id} />
      </ContentContainer>
    </Container>
  )
}
