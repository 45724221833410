import { Stack, Typography, useTheme } from '@mui/material'
import { nfModeloMapping } from 'hooks/queries/useNF'

interface HeaderProps {
  modelo: number
}

export default function Header(props: HeaderProps) {
  const { modelo } = props

  const theme = useTheme()
  const nfModeloDescricao = nfModeloMapping[modelo]

  return (
    <Stack spacing={1}>
      <Typography
        variant="h1"
        component="h2"
        fontSize="28px"
        fontWeight="medium"
      >
        Compartilhar {nfModeloDescricao} por e-mail
      </Typography>

      <Typography
        variant="h5"
        color={theme.palette.grey[500]}
        fontWeight="bold"
      >
        Informe um ou mais emails para compartilhar a {nfModeloDescricao}
      </Typography>
    </Stack>
  )
}
