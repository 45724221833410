import { useNavigate } from 'react-router-dom'

import {
  Check as CheckIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material'
import { Box, Stack } from '@mui/material'

import { ButtonContainer } from '@data-c/ui'

import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import FloatFooter from 'components/FloatFooter'
import Button from 'components/Button'

interface FooterProps {
  nfId: string
  totalNFFormatado: string
  pagamentoTotalFormatado: string
  descontoFormatado: string
  pagamentoTrocoFormatado: string
  totalDesconto: number
  pagamentoTroco: number
  isLoading?: boolean
  isFetching?: boolean
  onSubmit: () => void
}

export default function Footer(props: FooterProps) {
  const {
    nfId,
    totalNFFormatado,
    pagamentoTotalFormatado,
    descontoFormatado,
    pagamentoTrocoFormatado,
    totalDesconto,
    pagamentoTroco,
    isLoading = false,
    isFetching = false,
    onSubmit,
  } = props

  const navigate = useNavigate()

  function handleNavigateProdutos() {
    navigate(`/NF/${nfId}/produtos`)
  }

  return (
    <FloatFooter isLoading={isFetching}>
      <Stack sx={{ width: '100%', gap: 2 }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FloatFooterCurrency title="Total" price={totalNFFormatado} />

            <FloatFooterCurrency
              title="Total Pagamento"
              price={pagamentoTotalFormatado}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {totalDesconto > 0 && (
              <FloatFooterCurrency title="Desconto" price={descontoFormatado} />
            )}
            {pagamentoTroco > 0 && (
              <FloatFooterCurrency
                title="Troco"
                price={pagamentoTrocoFormatado}
              />
            )}
          </Box>
        </Box>

        <ButtonContainer>
          <Button
            fullWidth
            startIcon={<ShoppingCartIcon />}
            onClick={handleNavigateProdutos}
          >
            Produtos
          </Button>
          <Button
            fullWidth
            startIcon={<CheckIcon />}
            variant="contained"
            disabled={isFetching}
            isLoading={isLoading}
            onClick={onSubmit}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Fechar Venda
          </Button>
        </ButtonContainer>
      </Stack>
    </FloatFooter>
  )
}
