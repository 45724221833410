import { useNavigate } from 'react-router-dom'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'

import Form from './components/Form'
import PageTitle from 'components/PageTitle'

export default function AlteracaoSenha() {
  const navigate = useNavigate()

  function handleBack() {
    navigate('/perfil')
  }

  function handleClose() {
    navigate('/vendas')
  }

  return (
    <Container paper>
      <PageTitle
        title="Alterar senha"
        onBack={handleBack}
        onClose={handleClose}
      />

      <ContentContainer>
        <Form />
      </ContentContainer>
    </Container>
  )
}
