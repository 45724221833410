function stringifyArray(data: string[]) {
  if (data.length > 0) {
    const isAllStringsEmpty = data.every((str) => str.trim() === '')
    if (isAllStringsEmpty) return ''

    const emails = data.filter((item) => item.trim() !== '')
    const stringComTodosEmails = emails.join(',')
    return stringComTodosEmails
  }

  return ''
}

export default function useArrayManipulations() {
  return {
    stringifyArray,
  }
}
