import { ReactNode } from 'react'

import { Circle } from '@mui/icons-material'
import { SxProps } from '@mui/material'

import SliderContent from 'components/Slider/components/SliderContent'
import Slider from 'components/Slider'

interface FlagInfosProps {
  color?: any
  sx?: SxProps
  children: ReactNode
}

export default function FlagInfos(props: FlagInfosProps) {
  const { color, sx, children } = props

  return (
    <Slider sx={sx}>
      <SliderContent>
        {color && (
          <Circle
            sx={{
              fontSize: '10pt',
              color: color,
            }}
          />
        )}

        {children}
      </SliderContent>
    </Slider>
  )
}
