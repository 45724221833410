import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum NFStatusEnum {
  ABERTAS_0 = 0,
  FECHADAS_10 = 10,
  EMITIDAS_EM_CONTIGENCIA_30 = 30,
  TRANSMITIDAS_40 = 40,
  CANCELADAS_90 = 90,
  TODOS = -1,
}

const {
  ABERTAS_0,
  CANCELADAS_90,
  EMITIDAS_EM_CONTIGENCIA_30,
  FECHADAS_10,
  TRANSMITIDAS_40,
  TODOS,
} = NFStatusEnum

export const nfStatusMapping: Record<number | string, string> = {
  [TODOS]: 'Todos',
  [ABERTAS_0]: 'Abertas',
  [FECHADAS_10]: 'Fechadas',
  [EMITIDAS_EM_CONTIGENCIA_30]: 'Emitidas em Contigência',
  [TRANSMITIDAS_40]: 'Transmitidas',
  [CANCELADAS_90]: 'Canceladas',
}

export default function DropDownStatus(props: TextFieldProps) {
  return (
    <TextField select label="Status" {...props}>
      <MenuItem value={-1}>Todos</MenuItem>
      <MenuItem value="0">Abertas</MenuItem>
      <MenuItem value="10">Fechadas</MenuItem>
      <MenuItem value="30">Emitidas em contigência</MenuItem>
      <MenuItem value="40">Transmitidas</MenuItem>
      <MenuItem value="90">Canceladas</MenuItem>
    </TextField>
  )
}
