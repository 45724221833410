import { ReactNode, useContext } from 'react'

import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import {
  Box,
  ButtonProps,
  LinearProgress,
  Popover,
  Typography,
  useTheme,
} from '@mui/material'

export { default as ActionOptionsProvider } from './contexts/ActionOptionsContext'
export { default as OptionListButton } from './components/OptionListButton'
export { default as OptionList } from './components/OptionList'

import { ActionOptionsContext } from './contexts/ActionOptionsContext'
import Button from 'components/Button'

export const useActionOptionsContext = () => useContext(ActionOptionsContext)

export interface OptionListProps<TOrigem> {
  name: TOrigem
  onSubmit?: (origem?: TOrigem) => void
}

interface OptionsListContainerProps {
  triggerLabel?: string
  buttonOptionsProps?: ButtonProps
  children: ReactNode
}

export function OptionsListContainer(props: OptionsListContainerProps) {
  const { triggerLabel, children, buttonOptionsProps } = props
  const theme = useTheme()

  const {
    isLoading,
    anchorEl,
    isOpenOptionsListContainer,
    openOptionsListContainer,
    closeOptionsListContainer,
  } = useActionOptionsContext()

  return (
    <>
      <Button
        variant="text"
        endIcon={<MoreVertIcon fontSize="medium" />}
        onClick={openOptionsListContainer}
        sx={{ color: theme.palette.grey[500] }}
        {...buttonOptionsProps}
      >
        <Typography variant="body2">{triggerLabel && triggerLabel}</Typography>
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={isOpenOptionsListContainer}
        onClose={closeOptionsListContainer}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ marginTop: 0.5 }}
      >
        <Box>{isLoading && <LinearProgress />}</Box>
        {children}
      </Popover>
    </>
  )
}
