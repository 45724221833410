import { Stack, Typography, useTheme } from '@mui/material'
import { Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material'

import Button from 'components/Button'
import FloatDrawer from 'components/FloatDrawer'

interface DeleteDrawerProps {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function DeleteDrawer(props: DeleteDrawerProps) {
  const { isOpen, isLoading = false, onClose, onConfirm } = props
  const theme = useTheme()

  return (
    <FloatDrawer zIndex={9999} isOpen={isOpen} action={<></>}>
      <Stack padding={2} gap={3}>
        <Stack spacing={0.5}>
          <Typography variant="h3" component="h2" fontWeight="bold">
            Excluir Registro Permanentemente?
          </Typography>

          <Typography
            variant="h5"
            color={theme.palette.grey[500]}
            fontWeight="bold"
          >
            Você não poderá recuperar este registro após excluí-lo
          </Typography>
        </Stack>

        <Stack flexDirection="column" gap={1.5}>
          <Button
            fullWidth
            startIcon={<DeleteIcon />}
            isLoading={isLoading}
            variant="contained"
            color="error"
            onClick={onConfirm}
          >
            Excluir
          </Button>
          <Button fullWidth startIcon={<CloseIcon />} onClick={onClose}>
            Cancelar
          </Button>
        </Stack>
      </Stack>
    </FloatDrawer>
  )
}
