import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useCliente, { ClienteModel } from 'hooks/queries/useCliente'

export default function AutoCompleteClientes(
  props: AutoCompleteProps<ClienteModel>,
) {
  const { onChange, value, autoFocus } = props
  const { useQueryObterClientes } = useCliente()
  const { data, isLoading } = useQueryObterClientes('')

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={data?.data || []}
      onChange={onChange}
      getOptionLabel={(cliente) => {
        return `${cliente?.codigo} - ${cliente?.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Clientes"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
