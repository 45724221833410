import { ChangeEvent, useEffect } from 'react'
import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import useContaFinanceira, {
  ContaFinanceiraConsultaModel,
} from 'hooks/queries/useContaFinanceira'

type DropDownContasFinanceirasAtivasProps = {
  value: string
  onChange: (value: string, valueDescription?: string | null) => void
  onRender?: (primeiraContaFinanceira: ContaFinanceiraConsultaModel) => void
} & Omit<TextFieldProps, 'onChange'>

export default function DropDownContasFinanceirasAtivas(
  props: DropDownContasFinanceirasAtivasProps,
) {
  const { onChange, onRender, ...rest } = props

  const { useQueryObterContasFinanceirasAtivas } = useContaFinanceira()
  const { data } = useQueryObterContasFinanceirasAtivas()

  const dataFiltrada = data?.data?.filter((item) => item?.hubBankConvenioId)

  useEffect(() => {
    const primeiraConta = dataFiltrada?.[0]
    if (onRender && primeiraConta) {
      onRender(primeiraConta)
    }
  }, [data?.data])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const valueDescription = dataFiltrada?.find(
      (item) => item.id === value,
    )?.nome
    onChange(value, valueDescription)
  }

  return (
    <TextField
      label="Contas Financeiras"
      select
      onChange={handleChange}
      {...rest}
    >
      {dataFiltrada?.map((item) => (
        <MenuItem key={item?.id} value={item?.id || ''}>
          {item?.nome || ''}
        </MenuItem>
      ))}
    </TextField>
  )
}
