import { formatCurrency } from '@data-c/hooks'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import moment from 'moment'

interface ParcelasProps {
  parcelaAtual: string
  valorParcela: string
  dataParcela: string
}

interface CardParcelasProps {
  valorPagamento: number
  numeroDeParcelas: number
  parcelamentoCondPgto: string
}

export default function CardParcelas(props: CardParcelasProps) {
  const { valorPagamento, numeroDeParcelas, parcelamentoCondPgto } = props

  const theme = useTheme()

  function calcularDatasParcelas(periodicidade: string) {
    const dataAtual = moment()
    const partes = periodicidade.split('/').map(Number)

    const datasParcelas = partes.map((dias) => {
      const dataParcela = moment(dataAtual).add(dias, 'days')
      return dataParcela.format('DD/MM/YYYY')
    })

    return datasParcelas
  }

  function calcularValorDaParcela() {
    const valorParcela = (valorPagamento / numeroDeParcelas).toFixed(2)
    const parcelas: ParcelasProps[] = []

    const datasParcelas = calcularDatasParcelas(parcelamentoCondPgto)

    for (let i = 0; i < numeroDeParcelas; i++) {
      const parcelaAtual = `${i + 1}/${numeroDeParcelas}`
      const valorParcelaFormatado = formatCurrency(valorParcela)
      parcelas.push({
        parcelaAtual,
        valorParcela: valorParcelaFormatado,
        dataParcela: datasParcelas[i],
      })
    }

    return parcelas
  }

  const parcelas = calcularValorDaParcela()

  return (
    <Stack
      sx={{
        marginTop: 2,
        gap: 2,
      }}
    >
      {parcelas?.map((parcela) => {
        return (
          <Box
            key={parcela.parcelaAtual}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              border: `solid 1px ${theme.palette.primary.main}`,
              borderRadius: 1,
              padding: 2,
            }}
          >
            <Typography variant="h4">{parcela.parcelaAtual}</Typography>
            <Stack alignItems="flex-end" gap={1}>
              <Typography variant="h4">{parcela.dataParcela}</Typography>
              <Typography variant="body1" fontWeight="bold">
                R${parcela.valorParcela}
              </Typography>
            </Stack>
          </Box>
        )
      })}
    </Stack>
  )
}
