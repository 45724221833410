import { Box } from '@mui/material'
import { NFModel } from 'hooks/queries/useNF'
import CardInfo from 'components/CardInfo'

interface DialogContentErrorNFProps {
  nf?: NFModel
}

export default function DialogContentErrorNF(props: DialogContentErrorNFProps) {
  const { nf } = props

  return (
    <Box>
      <CardInfo title="Destinatário" subtitle={nf?.pessoa?.nome || ''} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
        }}
      >
        <CardInfo title="Número" subtitle={nf?.numeroNF || ''} />
        <CardInfo title="Série" subtitle={String(nf?.serie) || ''} />
      </Box>

      <CardInfo
        title="Transmissão do documento"
        subtitle={nf?.wS_EnvioDesc || ''}
      />
      <CardInfo title="Envio de email" subtitle={nf?.emailEnviadoDesc || ''} />
      <CardInfo title="chave de acesso" subtitle={nf?.nFe_ChaveAcesso || ''} />
      <CardInfo
        title="Resposta do servidor"
        subtitle={nf?.wS_RetornoDesc || ''}
      />
      <CardInfo
        title="Cancelamento"
        subtitle={nf?.wS_CancelamentoProtocoloDesc || ''}
      />
    </Box>
  )
}
