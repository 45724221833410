import { useState } from 'react'

import { ButtonContainer, Button } from '@data-c/ui'
import { Box, TextField } from '@mui/material'

import useValidations from 'hooks/useValidation'
import useCredentials from 'hooks/useCredentials'
import useNotification from 'hooks/useNotification'

import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup.string().required('Informe o e-mail'),
})

interface EsqueciSenhaFormProps {
  onLoginClick: () => void
}

export default function EsqueciSenhaForm(props: EsqueciSenhaFormProps) {
  const { onLoginClick } = props
  const [email, setEmail] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const { recuperarSenha } = useCredentials()
  const { validationProps, setValidationErrors } = useValidations()
  const notifications = useNotification()

  async function handleRecuperarSenha() {
    setValidationErrors(null)
    schema
      .validate({ email }, { abortEarly: false })
      .then(async () => {
        try {
          setSubmitting(true)
          await recuperarSenha(email)

          notifications.notifySuccess(
            'Foi enviado um e-mail para você com sua nova senha de acesso',
          )
          setTimeout(() => {
            onLoginClick()
          }, 2000)
        } catch (error) {
          notifications.notifyError('Não foi possível recuperar sua senha')
        } finally {
          setSubmitting(false)
        }
      })
      .catch((error) => {
        setValidationErrors(error)
      })
  }

  return (
    <Box component="form">
      <TextField
        name="email"
        label="E-mail"
        margin="normal"
        placeholder="Informe o e-mail utilizado para acessar o sistema"
        autoFocus
        value={email || ''}
        onChange={(e) => setEmail(e.target.value)}
        {...validationProps('email')}
      />
      <ButtonContainer
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 1,
        }}
      >
        <Button
          onClick={onLoginClick}
          variant="text"
          sx={{
            fontWeight: '400',
            ':hover': {
              color: (theme) => theme.palette.primary.dark,
            },
          }}
        >
          Voltar
        </Button>

        <Button
          variant="contained"
          onClick={handleRecuperarSenha}
          isLoading={isSubmitting}
        >
          Recuperar Senha
        </Button>
      </ButtonContainer>
    </Box>
  )
}
