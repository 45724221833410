import {
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import IconButton from 'components/IconButton'

interface DefaultActionProps {
  isOpen: boolean
  onClick: () => void
}

export default function DefaultAction(props: DefaultActionProps) {
  const { isOpen, onClick } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <IconButton
        customSx={{
          marginTop: '-38px',
        }}
        onClick={onClick}
      >
        {isOpen ? (
          <KeyboardDoubleArrowDownIcon fontSize="medium" />
        ) : (
          <KeyboardDoubleArrowUpIcon
            fontSize="medium"
            sx={{
              animation: 'fade 400ms linear infinite',
              '@keyframes fade': {
                '0%': { opacity: 1 },
                '50%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          />
        )}
      </IconButton>
    </Box>
  )
}
