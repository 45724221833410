import { useNavigate } from 'react-router-dom'

import useCredentials, { LoginDataInterface } from 'hooks/useCredentials'

import SimpleList from 'components/SimpleList'
import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import SimpleListItem from 'components/SimpleList/components/SimpleListItem'
import EmptyData from 'components/EmptyData'

import Header from './components/Header'
import PageTitle from 'components/PageTitle'

export default function SelecaoLicenca() {
  const navigate = useNavigate()
  const { userLogged, token, selecionarLicenca, isSubmitting } =
    useCredentials()

  function handleBack() {
    navigate('/perfil')
  }

  function handleClose() {
    navigate('/vendas')
  }

  async function handleSelecionarLicenca(empresaId: string) {
    const d: LoginDataInterface = {
      token: token || '',
      userId: userLogged?.uuid || '',
      userName: userLogged?.name || '',
    }

    await selecionarLicenca(d, empresaId)
    handleBack()
  }

  const empresas = userLogged?.empresas || []
  const temEmpresas = empresas.length > 0

  return (
    <Container paper>
      <PageTitle
        title="Seleção de Licença"
        onBack={handleBack}
        onClose={handleClose}
      />

      <ContentContainer
        disablePadding
        customSx={{
          paddingBottom: 3,
          height: 'calc(100% - 66px)',
          justifyContent: 'space-between',
        }}
      >
        {temEmpresas && <Header userName={userLogged?.name} />}
        {temEmpresas ? (
          <SimpleList>
            {empresas.map((empresa) => (
              <SimpleListItem
                key={empresa?.uuid}
                id={empresa?.uuid}
                isLoading={isSubmitting}
                label={empresa.nome}
                onClick={() => handleSelecionarLicenca(empresa.uuid)}
              />
            ))}
          </SimpleList>
        ) : (
          <EmptyData description="Nenhuma licença foi encontrada" />
        )}
      </ContentContainer>
    </Container>
  )
}
