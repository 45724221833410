import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useFormaPagamento from 'hooks/queries/useFormaPagamento'
import useNF from 'hooks/queries/useNF'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'

import ListItens from './components/ListItens'

export default function FormaPagamento() {
  const { nfId } = useParams()

  const navigate = useNavigate()
  const { setNFIds } = useNFContext()
  const { useQueryObterNFPorId } = useNF()
  const { useQueryObterFormaPagamento } = useFormaPagamento()
  const { data: nf } = useQueryObterNFPorId(nfId || '')
  const {
    data: formaPagamento,
    isLoading,
    error,
  } = useQueryObterFormaPagamento()

  function handleNavigateConfigurarPagamento(formaPagamentoId: string) {
    setNFIds('formaPagamentoId', formaPagamentoId)
    navigate(`/NF/${nfId}/configurar-pagamento`)
  }

  function handleBack() {
    const nfSemPagamentoCadastrado = nf?.nfPagamentos?.length === 0

    if (nfSemPagamentoCadastrado) {
      navigate(`/NF/${nfId}/produtos`)
      return
    }

    navigate(-1)
  }

  return (
    <Container paper>
      <PageTitle title="Forma de Pagamento" onBack={handleBack} />
      <ContentContainer disablePadding isLoading={isLoading} error={error}>
        <Typography
          variant="h1"
          fontSize="28px"
          fontWeight="medium"
          marginBottom={2}
          padding={2}
        >
          Selecione a forma de pagamento desejada
        </Typography>

        <ListItens
          itens={formaPagamento?.data || []}
          onClick={(formaPagamentoId) =>
            handleNavigateConfigurarPagamento(formaPagamentoId)
          }
        />
      </ContentContainer>
    </Container>
  )
}
