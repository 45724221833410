import { ReactNode, createContext, useState } from 'react'
import usePopOver from 'hooks/usePopover'

interface ActionOptionsData {
  origem: any | null
  isLoading: boolean
  anchorEl: HTMLElement | null
  isOpenOptionsListContainer: boolean
  openOptionsListContainer: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void
  closeOptionsListContainer: () => void
  setOrigem: (newOrigem: any | null) => void
  onClearOrigem: (params?: { _closeOptionsListContainer: boolean }) => void
  onLoading: (loading: boolean) => void
}

interface ActionOptionsProps {
  children: ReactNode
}

export const ActionOptionsContext = createContext({} as ActionOptionsData)

export default function ActionOptionsProvider({
  children,
}: ActionOptionsProps) {
  const [origem, setOrigem] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const {
    anchorEl,
    isOpen: isOpenOptionsListContainer,
    open: openOptionsListContainer,
    close: closeOptionsListContainer,
  } = usePopOver()

  function _setOrigem(_newOrigem: any | null) {
    setOrigem(_newOrigem)
  }

  function clearOrigem(params?: { _closeOptionsListContainer: boolean }) {
    if (params?._closeOptionsListContainer) {
      closeOptionsListContainer()
    }
    setOrigem(null)
  }

  return (
    <ActionOptionsContext.Provider
      value={{
        origem,
        anchorEl,
        isLoading,
        isOpenOptionsListContainer,
        setOrigem: _setOrigem,
        onLoading: setLoading,
        onClearOrigem: clearOrigem,
        closeOptionsListContainer,
        openOptionsListContainer,
      }}
    >
      {children}
    </ActionOptionsContext.Provider>
  )
}
