import { Stack, Typography } from '@mui/material'
import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'

interface FooterProps {
  origemDesc?: string | null
  totalFormatado: string
}

export default function Footer(props: FooterProps) {
  const { origemDesc, totalFormatado } = props

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: 1,
        paddingX: 2,
      }}
    >
      <Typography variant="body2">{origemDesc || '---'}</Typography>
      <FloatFooterCurrency
        title="Total:"
        price={totalFormatado}
        priceProps={{ variant: 'h4', fontWeight: 'bold' }}
      />
    </Stack>
  )
}
