import { Cancel as CancelIcon } from '@mui/icons-material'
import { Chip, ChipProps, SxProps, useTheme } from '@mui/material'

interface CustomChipProps extends ChipProps {
  active: boolean
  customSx?: SxProps
}

export default function CustomChip(props: CustomChipProps) {
  const { active, customSx, ...rest } = props

  const theme = useTheme()

  return (
    <Chip
      deleteIcon={<CancelIcon titleAccess="Deletar" />}
      sx={{
        backgroundColor: active === true ? 'rgba(89, 195, 224, 0.15)' : '',
        border:
          active === true
            ? `solid 1px ${theme.palette.primary.main}`
            : 'default',
        ':hover': {
          backgroundColor: active === true ? 'rgba(89, 195, 224, 0.20)' : '',
        },
        ...customSx,
      }}
      {...rest}
    />
  )
}
