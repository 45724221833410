import CardInfo from 'components/CardInfo'
import MobileDateTimePicker from 'components/MobileDateTimePicker'
import useNF, { AlterarIdentificacaoParams, NFModel } from 'hooks/queries/useNF'

interface CardDataSaidaProps {
  nf?: NFModel
}

export default function CardDataSaida({ nf }: CardDataSaidaProps) {
  const { useAlterarIdentificacaoNF } = useNF()
  const {
    mutateAsync: alterarIdentificacaoNF,
    isLoading: isLoadingAlterarIdentificacao,
  } = useAlterarIdentificacaoNF()

  async function handleAlterarIdentificacaoNF(value: string | null) {
    const { modelo, serie, dtEmissao, nFe_NaturezaOperacao, numeroNF } =
      nf || {}

    const dataIdentificacao: AlterarIdentificacaoParams = {
      modelo,
      serie,
      dtEmissao,
      dtSaidaEntrada: value,
      naturezaOperacao: nFe_NaturezaOperacao,
      numeroNF,
    }

    await alterarIdentificacaoNF({
      data: dataIdentificacao,
      nfId: nf?.id || '',
    })
  }

  return (
    <CardInfo
      subtitle="Data de Saída"
      contentText={
        nf?.operacao?.gerarDataSaidaAuto
          ? 'Definida como automática na operação'
          : ''
      }
      gap={0.5}
    >
      <MobileDateTimePicker
        value={nf?.dtSaidaEntrada || null}
        disabled={nf?.operacao?.gerarDataSaidaAuto}
        isLoading={isLoadingAlterarIdentificacao}
        onChange={(value) => handleAlterarIdentificacaoNF(value)}
      />
    </CardInfo>
  )
}
