import { useNavigate, useParams } from 'react-router-dom'
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useDialog } from '@data-c/hooks'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useNF, { NFModel, nfModeloMapping } from 'hooks/queries/useNF'
import useDownload from 'hooks/useDownload'

import { NFStatusEnum } from 'enums/NFStatusEnum'

import DownloadNFDialog from 'components/DownloadNFDialog'
import Container from 'components/Container'
import { TypeNFModelo } from 'components/Inputs/DropDownNFModelo'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import ContentContainer from 'components/Container/components/ContentContainer'

import successImage from 'assets/images/success.svg'

export default function NFTransmitida() {
  const { nfId } = useParams()

  const theme = useTheme()
  const navigate = useNavigate()
  const isDesktopVersion = useMediaQuery(() => theme.breakpoints.up('sm'))

  const { downloadArquivo } = useDownload()
  const { mensagemSucesso, setMensagemSucesso, setNFIds } = useNFContext()
  const { useQueryObterNFPorId } = useNF()
  const { data: nf, isLoading, error } = useQueryObterNFPorId(nfId || '')

  const {
    data: dataDownloadNFDialog,
    isOpen: isOpenDownloadNFDialog,
    closeDialog: closeDownloadNFDialog,
    openDialog: openDownloadNFDialog,
  } = useDialog<TypeNFModelo>()

  function handleBack() {
    setMensagemSucesso(false)
    navigate('/vendas')
  }

  function handleVisualizarPDF(nfModelo: TypeNFModelo) {
    if (nf?.id) {
      setNFIds('nfId', nf.id)

      if (!isDesktopVersion) {
        openDownloadNFDialog(nfModelo)
        return
      }

      navigate(`/NF/${nfId}/visualizar-danfe`, { state: { nfModelo } })
    }
  }

  function handleVisualizarXML() {
    if (nf?.id) {
      if (!isDesktopVersion) {
        const chaveAcesso = nf?.nFe_ChaveAcesso
        const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]

        downloadArquivo(nf?.nFeXML, [chaveAcesso, nfModeloDescricao], 'xml')
        return
      }

      setNFIds('nfId', nf.id)
      navigate(`/NF/${nfId}/visualizar-xml`)
    }
  }

  if (nf?.status && nf?.status !== NFStatusEnum.TRANSMITIDA) {
    navigate('/vendas')
    return
  }

  const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]
  const nfModelo = nf?.modelo === 55 ? 'DANFE' : 'DANFCE'

  return (
    <Container paper fullHeight>
      <PageTitle title="" onClose={handleBack} />

      <ContentContainer
        isLoading={isLoading}
        error={error}
        customSx={{ justifyContent: 'initial', gap: 5 }}
      >
        <Stack alignItems="center" gap={3}>
          <img
            src={successImage}
            width="148px"
            alt="Imagem nota fiscal transmitida com sucesso"
          />

          <Typography
            variant="h1"
            component="h2"
            fontSize="28px"
            fontWeight="medium"
          >
            {mensagemSucesso
              ? `Pronto, ${nfModeloDescricao} transmitida com sucesso!`
              : `${nfModeloDescricao} transmitida!`}
          </Typography>
        </Stack>

        <Stack
          gap={1.5}
          flexDirection={isDesktopVersion ? 'row' : 'column'}
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={() => handleVisualizarPDF(nfModelo)}
          >
            {isDesktopVersion
              ? `Visualizar ${nfModeloDescricao}`
              : `Baixar ${nfModeloDescricao}`}
          </Button>
          <Button onClick={handleVisualizarXML}>
            {isDesktopVersion ? `Visualizar XML` : `Baixar XML`}
          </Button>
          <Button onClick={() => navigate(`/NF/${nfId}/envio-nf-por-email`)}>
            Compartilhar {nfModeloDescricao} por e-mail
          </Button>
          <Button onClick={() => navigate('/vendas')}>Voltar às Vendas</Button>
          <Button
            color="error"
            onClick={() => navigate(`/NF/${nfId}/justificativa-cancelamento`)}
          >
            Cancelar {nfModeloDescricao}
          </Button>
        </Stack>
      </ContentContainer>

      {isOpenDownloadNFDialog && (
        <DownloadNFDialog
          nf={nf || ({} as NFModel)}
          chaveId={dataDownloadNFDialog}
          isOpen={isOpenDownloadNFDialog}
          onClose={closeDownloadNFDialog}
        />
      )}
    </Container>
  )
}
