import {
  Box,
  LinearProgressProps,
  LinearProgress,
  Typography,
} from '@mui/material'
import useCalculos from 'hooks/useCalculos'

interface PercentageLinearProgress extends LinearProgressProps {
  progressValue: number
  targetValue: number
}

export default function PercentageLinearProgress(
  props: PercentageLinearProgress,
) {
  const { progressValue, targetValue } = props
  const { calcularPorcentagem } = useCalculos()
  const porcentagemCalculada = calcularPorcentagem(progressValue, targetValue)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LinearProgress
        sx={{ flex: 1, height: '10px', borderRadius: '50px' }}
        variant="determinate"
        value={porcentagemCalculada}
        {...props}
      />
      <Box
        sx={{
          width: '55px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption">{porcentagemCalculada}%</Typography>
      </Box>
    </Box>
  )
}
