import { useEffect, useState } from 'react'

import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import PlusMinusButton from './components/PlusMinusButton'

interface DoubleActionButtonProps {
  value: number
  disabled?: boolean
  onChange: (quantidadeAcumulada: number) => void
}

export default function DoubleActionButton(props: DoubleActionButtonProps) {
  const { value, disabled, onChange } = props

  const [quantidade, setQuantidade] = useState(0)
  const [quantidadeAcumulada, setQuantidadeAcumulada] = useState(0)

  useEffect(() => {
    setQuantidade(value)
  }, [value])

  useEffect(() => {
    const timeOutQuantidade = setTimeout(() => {
      if (quantidadeAcumulada > 0) {
        onChange(quantidadeAcumulada)
        setQuantidadeAcumulada(0)
      }
    }, 1000)
    return () => clearTimeout(timeOutQuantidade)
  }, [handleIncrement, handleDecrement])

  function handleIncrement() {
    if (disabled) return
    const newQuantidade = quantidade + 1

    setQuantidadeAcumulada(newQuantidade)
    setQuantidade(newQuantidade)
  }

  function handleDecrement() {
    if (disabled) return
    const newQuantidade = quantidade - 1

    if (quantidade > 0) {
      setQuantidadeAcumulada(newQuantidade)
      setQuantidade(newQuantidade)
    }

    if (newQuantidade === 0) {
      onChange(newQuantidade)
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <PlusMinusButton
        icon={<RemoveIcon fontSize="medium" />}
        disabled={quantidade === 0 || disabled}
        onClick={handleDecrement}
      />
      <Typography variant="body1" sx={{ marginX: 2 }}>
        {quantidade}
      </Typography>
      <PlusMinusButton
        disabled={disabled}
        icon={<AddIcon fontSize="medium" />}
        onClick={handleIncrement}
      />
    </Box>
  )
}
