import { CircularProgress, Stack, SxProps } from '@mui/material'

interface LoadingProps {
  customSx?: SxProps
}

export default function Loading({ customSx }: LoadingProps) {
  return (
    <Stack
      sx={{
        position: 'fixed',
        zIndex: '99',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgb(0 0 0 / 50%)',
        overflow: 'hidden',
        ...customSx,
      }}
    >
      <CircularProgress />
    </Stack>
  )
}
