import { Mail } from '@mui/icons-material'
import { Stack } from '@mui/material'
import CardInfo from 'components/CardInfo'

interface SendEmailsListProps {
  emailsEnviados: string[]
}

export default function SendEmailsList(props: SendEmailsListProps) {
  const { emailsEnviados } = props

  return (
    <Stack marginBottom={30}>
      {emailsEnviados.map((email) => (
        <CardInfo key={email} title={email} icon={<Mail />} />
      ))}
    </Stack>
  )
}
