import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SearchTextField } from '@data-c/ui'
import useCliente, { ClienteModel } from 'hooks/queries/useCliente'

import EmptyData from 'components/EmptyData'

import ClientesCardInfo from './components/ClientesCardInfo'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'
import ContentContainer from 'components/Container/components/ContentContainer'
import HeaderContainer from 'components/Container/components/HeaderContainer'
import { Avatar, Stack } from '@mui/material'

export default function Clientes() {
  const navigate = useNavigate()

  const [query, setQuery] = useState('')

  const { useQueryObterClientes } = useCliente()
  const { data: clientes, isLoading, error } = useQueryObterClientes(query)

  function handleClick(cliente: ClienteModel) {
    navigate(`/clientes/cliente/${cliente?.id || ''}`)
  }

  return (
    <Container>
      <HeaderContainer>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={3}
        >
          <PageTitle title="Clientes" disablePadding />
          <Avatar
            alt="photoURL"
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
            onClick={() => navigate('/perfil')}
          />
        </Stack>

        <SearchTextField
          onSearch={(query) => setQuery(query)}
          placeholder="Pesquisar clientes"
          autoFocus={false}
          sx={{ marginTop: 2 }}
        />
      </HeaderContainer>

      <ContentContainer
        isLoading={isLoading}
        error={error}
        disablePadding
        customSx={{
          marginTop: '146px',
          gap: 1.5,
          paddingY: 1.5,
          marginBottom: 10,
        }}
      >
        {clientes?.data && clientes?.data?.length > 0 ? (
          clientes?.data?.map((cliente) => (
            <ClientesCardInfo
              key={cliente.id}
              cliente={cliente}
              onClick={(cliente) => handleClick(cliente)}
            />
          ))
        ) : (
          <EmptyData description="Nenhum registro foi encontrado" />
        )}
      </ContentContainer>
    </Container>
  )
}
