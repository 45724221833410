import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'

export interface FormaPagamentoModel {
  id: string
  codigo: number
  nome: string
  indicadorPagamento: number
  indicadorPagamentoDesc: string
  meioPagamento: number
  meioPagamentoDesc: string
  cartaoBandeiraDesc: string
  cartaoTipoIntegracao: number
  cartaoTipoIntegracaoDesc: string
  cartaoCNPJCredenciadora: string
  cartaoBandeira: number
  parcelamento_CondPgto: string
  exibirPDV: boolean
}

export async function obterFormaPagamento(): Promise<
  HttpResponseInterface<FormaPagamentoModel>
> {
  const response = await api.get<HttpResponseInterface<FormaPagamentoModel>>(
    'FormaPagamento',
  )

  return response.data
}

export async function obterFormaPagamentoPorId(
  id: string,
): Promise<FormaPagamentoModel> {
  const response = await api.get<{ data: FormaPagamentoModel }>(
    `FormaPagamento/${id}`,
  )

  return response.data.data
}

export function useQueryObterFormaPagamento() {
  return useQuery<
    HttpResponseInterface<FormaPagamentoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['FORMA_PAGAMENTO'], () => {
    return obterFormaPagamento()
  })
}

export function useQueryObterFormaPagamentoPorId(id: string) {
  return useQuery<FormaPagamentoModel, AxiosError>(
    ['FORMA_PAGAMENTO', id],
    () => {
      return obterFormaPagamentoPorId(id)
    },
  )
}

export default function useFormaPagamento() {
  return {
    useQueryObterFormaPagamento,
    useQueryObterFormaPagamentoPorId,
  }
}
