import { useEffect, useState } from 'react'
import { AxiosRequestConfig } from 'axios'

import DropDownRelatorio from 'components/Inputs/DropDownRelatorio'

import { TokenPermissoesKeys } from 'hooks/useCredentials'
import usePDF from 'hooks/queries/usePDF'

import IframeContainer from './components/IframeContainer'
import IframeHeader from './components/IframeHeader'
import Iframe from './components/Iframe'

export { default as IframeContainer } from './components/IframeContainer'
export { default as IframeHeader } from './components/IframeHeader'
export { default as Iframe } from './components/Iframe'

interface PDFViewerProps {
  chaveId: string
  pdfIds?: string[] | (string | undefined)[]
  relatorioPath?: string
  pdfPath: string
  pdfAxiosRequestConfig?: AxiosRequestConfig<any>
  title?: string
  pdfApi?: TokenPermissoesKeys
  relatorioApi?: TokenPermissoesKeys
  onBack?: () => void
}

export default function PDFViewer(props: PDFViewerProps) {
  const {
    chaveId,
    pdfIds,
    relatorioPath,
    pdfPath,
    pdfAxiosRequestConfig,
    title,
    pdfApi,
    relatorioApi,
    onBack,
  } = props

  const [relatorioId, setRelatorioId] = useState('')
  const { useQueryObterPDF } = usePDF()
  const { data, refetch, isLoading, error } = useQueryObterPDF({
    route: pdfPath,
    axiosRequestConfig: {
      ...pdfAxiosRequestConfig,
      params: { ...pdfAxiosRequestConfig?.params, relatorioId },
    },
    ids: pdfIds,
    api: pdfApi,
  })

  useEffect(() => {
    if (relatorioId) {
      refetch()
    }
  }, [relatorioId])

  const file = new Blob([data], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)

  return (
    <IframeContainer>
      <IframeHeader title={title || chaveId} onBack={onBack}>
        <DropDownRelatorio
          value={relatorioId}
          requestProps={{ chaveId, path: relatorioPath, api: relatorioApi }}
          onChange={(e) => setRelatorioId(e.target.value)}
          onRender={(primeiroRelatorio) =>
            setRelatorioId(primeiroRelatorio?.id)
          }
          sx={{ width: '300px' }}
        />
      </IframeHeader>

      <Iframe pdf={fileURL} isLoading={isLoading} error={error} />
    </IframeContainer>
  )
}
