import { useEffect } from 'react'
import { useFormApi } from '@data-c/providers'

import {
  OptionListProps,
  useActionOptionsContext,
} from 'components/OptionsListContainer'

import { useSelectableCardContext } from 'contexts/SelectableCardContext'

import { BoletoDetalhadoModel } from 'hooks/queries/useBoleto'
import useNotification from 'hooks/useNotification'

import Form, { PDFBoletosFormProps } from './components/Form'
import { TipoOrigem } from '../..'

export default function BoletoImpressao({ name }: OptionListProps<TipoOrigem>) {
  const { itensSelecionados } = useSelectableCardContext<BoletoDetalhadoModel>()
  const { origem, onClearOrigem } = useActionOptionsContext()

  const notification = useNotification()
  const { openForm } = useFormApi<PDFBoletosFormProps>()

  useEffect(() => {
    if (origem === name) {
      handleOpenPDFDrawer()
    }
  }, [origem])

  function handleOpenPDFDrawer() {
    onClearOrigem({ _closeOptionsListContainer: true })

    const notifyInfo = (message: string) => {
      notification.notifyInfo(message, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
    if (itensSelecionados.some((item) => item?.dataRegistro === null)) {
      return notifyInfo(
        'Apenas os boletos registrados estão disponível para ser baixado.',
      )
    }
    if (
      itensSelecionados.some(
        (item) =>
          item?.convenioBancoCodigo !==
          itensSelecionados[0]?.convenioBancoCodigo,
      )
    ) {
      return notifyInfo(
        'Apenas boletos do mesmo banco podem ser baixados juntos.',
      )
    }

    openForm({
      itensSelecionados,
      relatorioId: '',
    })
  }

  return <Form />
}
