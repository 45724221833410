import { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { formatCurrency, useForm } from '@data-c/hooks'

import { useNFContext } from 'contexts/NFContext/NFContext'

import { ProdutoModel } from 'hooks/queries/useProduto'
import useCalculos, { TipoCalculoTypes } from 'hooks/useCalculos'
import useNF, { NFModel } from 'hooks/queries/useNF'

import DoubleActionButton from 'components/DoubleActionButton'

import CardContainer from './components/CardContainer'
import CardDescriptions from './components/CardDescriptions'

export const defaltItemData = {
  nome: '',
  quantidade: 0,
  produtoId: '',
}

export interface ItemProps {
  nome: string
  quantidade: number
  produtoId: string
  codigo?: string | null
  unitarioBruto?: number | null
  estoque?: number | null
  totalBruto?: number | null
  unitario?: number | null
  total?: number | null
  nfItemId?: string | null
  produto?: ProdutoModel | null
  nf?: NFModel | null
  pDesconto?: number | null
  desconto?: number | null
}

interface ProdutoCardInfoProps {
  item: ItemProps
  onClick: () => void
}

export default function ProdutoCardInfo(props: ProdutoCardInfoProps) {
  const { item, onClick } = props

  const { setLoading } = useNFContext()
  const { calcularItemPedido } = useCalculos()

  const { useRemoverNFItem, useCreateNFItem, useUpdateNFItem } = useNF()
  const { mutateAsync: create, isLoading: isCreating } = useCreateNFItem()
  const { mutateAsync: update, isLoading: isUpdating } = useUpdateNFItem()
  const { mutateAsync: remover, isLoading: isRemoving } = useRemoverNFItem()

  const { data, setData } = useForm<ItemProps>(defaltItemData)

  const [preco, setPreco] = useState(0)

  useEffect(() => {
    setPreco(item?.totalBruto || 0)
    setData(item)
  }, [item])

  const theme = useTheme()

  async function createNFItem(itemCalculado: ItemProps) {
    const { produtoId, quantidade, totalBruto, unitarioBruto } = itemCalculado

    await create({
      item: {
        produtoId,
        quantidade,
        totalBruto,
        unitarioBruto,
      },
      nfId: data?.nf?.id || '',
    }).finally(() => {
      setLoading(false)
    })
  }

  async function updateNFItem(itemCalculado: ItemProps) {
    const findedItem = itemCalculado?.nf?.nfItems?.find(
      (nfItem) => nfItem.id === itemCalculado?.nfItemId,
    )

    const {
      total,
      unitarioBruto,
      unitario,
      totalBruto,
      quantidade,
      desconto,
      pDesconto,
    } = itemCalculado

    if (findedItem) {
      await update({
        item: {
          ...findedItem,
          quantidade,
          totalBruto,
          total,
          unitarioBruto,
          unitario,
          desconto,
          pDesconto,
        },
        nfId: data?.nf?.id || '',
      })
    }

    setLoading(false)
  }

  async function removerNFItem() {
    const { unitarioBruto, nfItemId } = data
    const nfId = data?.nf?.id || ''

    setPreco(unitarioBruto || 0)

    if (nfItemId) {
      await remover({ nfId, nfItemId })
    }

    setLoading(false)
  }

  function handleChangeQuantidade(quantidade: number) {
    const itemJaEstaAdicionado = data?.nfItemId
    setLoading(true)

    if (quantidade === 0) {
      removerNFItem()
      return
    }

    const novaData = {
      ...data,
      quantidade: quantidade,
    }
    const itemCalculado = calcularItemPedido(
      novaData,
      TipoCalculoTypes.QUANTIDADE,
    )
    setPreco(itemCalculado?.totalBruto || 0)

    if (itemJaEstaAdicionado) {
      updateNFItem(itemCalculado)
      return
    }

    createNFItem(itemCalculado)
  }

  const precoFormatado = formatCurrency(preco)
  const isLoading = isCreating || isUpdating || isRemoving

  return (
    <CardContainer>
      <Box sx={{ flex: 1 }}>
        <CardDescriptions
          codigo={data?.codigo || ''}
          nome={data.nome}
          estoque={data?.estoque || 0}
          unitarioBruto={data?.unitarioBruto || 0}
          isLoading={isLoading}
          onClick={onClick}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.grey[600],
              fontWeight: 'bold',
              marginRight: 2,
            }}
          >
            R${precoFormatado}
          </Typography>

          {data?.unitarioBruto && data?.unitarioBruto > 0 ? (
            <DoubleActionButton
              value={data.quantidade}
              disabled={isLoading}
              onChange={(quantidade) => handleChangeQuantidade(quantidade)}
            />
          ) : (
            ''
          )}
        </Box>
      </Box>
    </CardContainer>
  )
}
