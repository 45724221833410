import { useMemo } from 'react'

import { formatCurrency } from '@data-c/hooks'

import ContentContainer from 'components/Container/components/ContentContainer'
import SelectableToolbar from 'components/SelectableToolbar'
import EmptyData from 'components/EmptyData'

import { useSelectableCardContext } from 'contexts/SelectableCardContext'

import useDuplicatas, { DuplicataModel } from 'hooks/queries/useDuplicatas'
import useDateFormat from 'hooks/queries/useDateFormat'

import { CardDuplicata } from './components/CardDuplicata'
import Actions from './components/Actions'

interface ListaProps {
  nfId: string
}

export default function Lista({ nfId }: ListaProps) {
  const {
    itensSelecionados,
    onRemoverTodosIntesSelecionados,
    setItemSelecionado,
  } = useSelectableCardContext<DuplicataModel>()
  const { formatDateToDiaMesAno } = useDateFormat()

  const { useQueryObterDuplicatasPorNFId } = useDuplicatas()
  const { data, isLoading } = useQueryObterDuplicatasPorNFId(nfId || '')

  const dataFormatada = useMemo(() => {
    return (
      data?.data?.map((item) => {
        return {
          ...item,
          totalFormatado: formatCurrency(item?.total || ''),
          dataVencimentoFormatada: formatDateToDiaMesAno(
            item?.dataVencimento || '',
          ),
          boletoRegistroFormatado: formatDateToDiaMesAno(
            item?.boletoRegistro || '',
          ),
        }
      }) || []
    )
  }, [data?.data])

  return (
    <ContentContainer
      isLoading={isLoading}
      disablePadding
      customSx={{
        marginTop: '12px',
      }}
    >
      <SelectableToolbar
        countQuantidade={itensSelecionados.length}
        onClear={() => onRemoverTodosIntesSelecionados()}
      >
        <Actions duplicatas={data?.data || []} />
      </SelectableToolbar>

      {dataFormatada.length > 0 ? (
        dataFormatada.map((duplicata) => (
          <CardDuplicata
            key={duplicata?.id}
            data={duplicata}
            isSelected={itensSelecionados.some(
              (item) => item?.id === duplicata?.id,
            )}
            onSelect={setItemSelecionado}
          />
        ))
      ) : (
        <EmptyData description="Nenhuma duplicata foi encontrada!" />
      )}
    </ContentContainer>
  )
}
