import { Stack, Typography, useTheme } from '@mui/material'

interface CardButtonProps {
  label: string
  image: string
  width?: string
  height?: string
  onClick?: () => void
}

export default function CardButton(props: CardButtonProps) {
  const { label, image, width, height, onClick } = props

  const theme = useTheme()

  return (
    <Stack
      sx={{
        width: '92px',
        alignItems: 'center',
        textAlign: 'center',
        gap: 2,
        padding: 1.5,
        border: `solid 1px ${theme.palette.primary.main}`,
        borderRadius: '4px',
        ':hover': {
          backgroundColor: 'rgba(89, 195, 224, 0.10)',
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      <img
        src={image}
        width={width || '34px'}
        height={height}
        alt="Imagem da NFe"
      />

      <Typography variant="body2" fontWeight="bold">
        {label}
      </Typography>
    </Stack>
  )
}
