import { CurrencyTextField as MioCurrencyTextField } from '@data-c/ui'
import { TextFieldProps } from '@mui/material'

export default function CurrencyTextField(
  props: Omit<TextFieldProps, 'onChange'> & {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: any) => void
  },
) {
  return (
    <MioCurrencyTextField
      inputProps={{
        inputMode: 'numeric',
        style: {
          textAlign: 'right',
        },
      }}
      {...props}
    />
  )
}
