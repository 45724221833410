import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'

export interface ProdutoModel {
  id?: string
  codigo?: string | null
  codigoEAN?: string | null
  codigoEANTrib?: string | null
  nome: string
  unidadeComercial?: string | null
  unidadeTrib?: string | null
  estoque?: number | null
  precoUnitario?: number | null
  precoUnitarioProntaEntrega?: number | null
  produtoGrupoId?: string | null
  produtoGrupoNome: string
  produtoGTId?: string | null
  produtoGTNome?: string | null
  ativo: boolean
  codigoExterno?: string | null
  ncmId?: string | null
  cestId?: string | null
  bloqueiaQuantidadeFracionada: boolean
}

export async function obterProdutos(
  query: string,
): Promise<HttpResponseInterface<ProdutoModel>> {
  const response = await api.get<HttpResponseInterface<ProdutoModel>>(
    `ProdutoConsulta/Pesquisar/${true}/${query}`,
  )

  return response.data
}

export async function obterProdutoPorId(id: string): Promise<ProdutoModel> {
  const response = await api.get<{ data: ProdutoModel }>(
    'ProdutoConsulta/ObterPorId',
    {
      params: { id },
    },
  )

  return response.data.data
}

export function useQueryObterProdutos(query: string) {
  return useQuery<
    HttpResponseInterface<ProdutoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PRODUTOS', query], () => {
    return obterProdutos(query)
  })
}

export function useQueryObterProdutoPorId(id: string) {
  return useQuery<ProdutoModel, AxiosError>(['PRODUTO', id], () => {
    return obterProdutoPorId(id)
  })
}

export default function useProduto() {
  return {
    useQueryObterProdutos,
    useQueryObterProdutoPorId,
  }
}
