import { forwardRef } from 'react'
import { Box, BoxProps } from '@mui/material'

const SliderContent = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        whiteSpace: 'nowrap',
        overflowY: 'hidden',
        // overflowX: 'hidden',
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        position: 'relative',
        scrollbarWidth: 'none',
      }}
      {...props}
    />
  )
})

export default SliderContent
