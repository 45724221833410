import { ReactNode } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import CustomChip from 'components/CustomChip'

interface FlagInfoItemProps {
  infoTitle?: string
  title?: string | number | null
  label?: ReactNode
  subtitle?: string | number | null
  variant?: 'filled' | 'outlined'
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  isLoading?: boolean
  onClick?: () => void
  onDelete?: (event: any) => void
}

export default function FlagInfoItem(props: FlagInfoItemProps) {
  const {
    infoTitle,
    title,
    label,
    subtitle,
    variant = 'filled',
    isLoading = false,
    color,
    onClick,
    onDelete,
  } = props

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        scrollSnapAlign: 'start',
      }}
    >
      <CustomChip
        title={infoTitle}
        variant={variant}
        color={color}
        active={false}
        label={label || `${title || ''}: ${subtitle || ''}`}
        size="medium"
        onClick={onClick}
        onDelete={onDelete}
        disabled={isLoading}
        sx={{
          cursor: onClick ? 'pointer' : 'default',
        }}
      />
      {isLoading && (
        <CircularProgress
          size="12px"
          sx={{ position: 'absolute', color: '#000' }}
        />
      )}
    </Stack>
  )
}
