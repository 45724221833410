import { EmpresaModel } from 'hooks/queries/useEmpresa'

import SimpleList from 'components/SimpleList'
import SimpleListItem from 'components/SimpleList/components/SimpleListItem'

interface ListItensProps {
  itens: EmpresaModel[]
  onClick: (empresaId: string) => void
}

export default function ListItens(props: ListItensProps) {
  const { itens, onClick } = props

  return (
    <SimpleList>
      {itens?.map((item) => {
        return (
          <SimpleListItem
            key={item.id}
            label={`${item.nome} - ${item.cidadeUF}`}
            onClick={() => onClick(item.id)}
          />
        )
      })}
    </SimpleList>
  )
}
