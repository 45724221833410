import { formatCurrency } from '@data-c/hooks'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import useCapitalize from 'hooks/useCapitalize'

interface CardDescriptionsProps {
  codigo: string
  nome: string
  unitarioBruto: number
  estoque: number
  isLoading?: boolean
  onClick: () => void
}

export default function CardDescriptions(props: CardDescriptionsProps) {
  const { codigo, nome, estoque, unitarioBruto, isLoading, onClick } = props

  const theme = useTheme()
  const { capitalizeFirstLetter } = useCapitalize()

  const precoUnitarioFormatado = formatCurrency(unitarioBruto)

  return (
    <Box onClick={onClick} sx={{ position: 'relative' }}>
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 0.5 }}>
        {codigo} - {capitalizeFirstLetter(nome)}
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
        R${precoUnitarioFormatado}
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
        {estoque} em estoque
      </Typography>

      {isLoading && (
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <CircularProgress size={16} color="primary" />
        </Box>
      )}
    </Box>
  )
}
