import { Box, Stack, Typography } from '@mui/material'
import { FormProvider } from '@data-c/providers'
import { NFModel } from 'hooks/queries/useNF'

import InteresseDoContribuinte from './components/InteresseDoContribuinte'
import DocumentoReferenciado from './components/DocumentoReferenciado'
import InteresseDoFisco from './components/InteresseDoFisco'

interface CardOutrasInformacoesProps {
  nf?: NFModel
}

export default function CardOutrasInformacoes({
  nf,
}: CardOutrasInformacoesProps) {
  return (
    <Box>
      <Typography variant="body2" sx={{ padding: 1, paddingBottom: 0 }}>
        Outras Informações
      </Typography>
      <Stack gap={1}>
        <FormProvider>
          <InteresseDoFisco nf={nf} />
        </FormProvider>
        <FormProvider>
          <InteresseDoContribuinte nf={nf} />
        </FormProvider>
        <FormProvider>
          <DocumentoReferenciado nf={nf} />
        </FormProvider>
      </Stack>
    </Box>
  )
}
