import { ReactElement } from 'react'
import { IconButton, useTheme } from '@mui/material'

interface ActionButtonProps {
  icon: ReactElement
  disabled?: boolean
  color?: 'primary' | 'error'
  onClick?: () => void
}

export default function ActionButton(props: ActionButtonProps) {
  const { icon, disabled, color = 'primary', onClick } = props

  const theme = useTheme()

  return (
    <IconButton
      sx={{
        width: '42px',
        height: '100%',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        justifyContent: 'center',
        backgroundColor:
          color === 'primary'
            ? theme.palette.primary.dark
            : theme.palette.error.dark,
        transition: 'ease-in-out 200ms',
        ':hover': {
          backgroundColor:
            color === 'primary'
              ? theme.palette.primary.main
              : theme.palette.error.main,
        },
        cursor: 'pointer',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  )
}
