import { Clear as ClearIcon } from '@mui/icons-material'
import { IconButton, Stack, Typography, useTheme } from '@mui/material'

import { useFormApi } from '@data-c/providers'
import CardInfo, { CardInfoActions } from 'components/CardInfo'
import Delete from 'components/Delete'

import useNFOutrasInformacoes, {
  DocumentoReferenciadoModel,
} from 'hooks/queries/useNFOutrasInformacoes'
import { NFModel } from 'hooks/queries/useNF'

import Form from './components/Form'

interface DocumentoReferenciadoProps {
  nf?: NFModel
}

export default function DocumentoReferenciado({
  nf,
}: DocumentoReferenciadoProps) {
  const theme = useTheme()

  const { openForm: openFormDocumentoReferenciado, openConfirm } =
    useFormApi<DocumentoReferenciadoModel>()

  const { useRemoverDocumentoReferenciadoNF } = useNFOutrasInformacoes()
  const { mutateAsync: removerDocumentoReferenciadoNF } =
    useRemoverDocumentoReferenciadoNF()

  return (
    <CardInfo subtitle="Documentos Referenciados">
      {nf?.nfReferenciados?.map((nfReferenciado) => (
        <Stack
          key={nfReferenciado?.id}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1}
          paddingBottom={0.5}
          gap={1}
          borderBottom={`solid 1px ${theme.palette.grey[400]}`}
        >
          <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
            {nfReferenciado?.chaveAcesso || ''}
          </Typography>

          <IconButton onClick={() => openConfirm(nfReferenciado)}>
            <ClearIcon fontSize="medium" color="error" />
          </IconButton>
        </Stack>
      ))}

      <CardInfoActions
        showAddButton
        onClick={() => {
          openFormDocumentoReferenciado({
            nfId: nf?.id || '',
            tipoDocumento: 1,
          })
        }}
      />

      <Form />
      <Delete onDelete={removerDocumentoReferenciadoNF} />
    </CardInfo>
  )
}
