import { formatCurrency } from '@data-c/hooks'
import { ButtonContainer } from '@data-c/ui'
import { Box, Stack, Typography } from '@mui/material'
import Button from 'components/Button'
import FloatFooter from 'components/FloatFooter'

interface FooterProps {
  pDesconto: number
  desconto: number
  total: number
  totalBruto: number
  nfItemId?: string | null
  isLoading: boolean
  isRemoving: boolean
  onSubmit: () => void
  onRemove: () => void
}

export default function Footer(props: FooterProps) {
  const {
    pDesconto,
    desconto,
    total,
    totalBruto,
    nfItemId,
    isLoading,
    isRemoving,
    onSubmit,
    onRemove,
  } = props

  const totalFormatado = formatCurrency(total)
  const totalBrutoFormatado = formatCurrency(totalBruto)

  return (
    <FloatFooter>
      <Stack gap={2} width="100%">
        <Stack>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="body2">Total:</Typography>
            <Typography variant="button">R$ {totalBrutoFormatado}</Typography>
          </Box>
          {(pDesconto > 0 || desconto > 0) && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Typography variant="body2">Total com desconto:</Typography>
              <Typography variant="button">R$ {totalFormatado}</Typography>
            </Box>
          )}
        </Stack>

        <ButtonContainer>
          {nfItemId && (
            <Button
              fullWidth
              color="error"
              isLoading={isRemoving}
              onClick={onRemove}
            >
              Excluir
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Stack>
    </FloatFooter>
  )
}
