import { Button, Dialog } from '@data-c/ui'
import { NFModel } from 'hooks/queries/useNF'
import DialogContentErrorNF from 'pages/NF/components/DialogContentErrorNF'

interface ErrorNFDialogProps {
  isOpen: boolean
  data: NFModel
  onClose: () => void
}

export default function ErrorNFDialog(props: ErrorNFDialogProps) {
  const { isOpen, onClose, data } = props

  return (
    <Dialog
      open={isOpen}
      type="error"
      title="Não foi possível transmitir a nota"
      actions={
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      }
    >
      <DialogContentErrorNF nf={data} />
    </Dialog>
  )
}
