import { useState } from 'react'
import { Dialog } from '@data-c/ui'

import { TypeNFModelo } from 'components/Inputs/DropDownNFModelo'
import ProgressDownloadDialog from 'components/ProgressDownloadDialog'
import DropDownRelatorio from 'components/Inputs/DropDownRelatorio'
import Button from 'components/Button'

import { NFModel, nfModeloMapping } from 'hooks/queries/useNF'
import useDownload from 'hooks/useDownload'
import usePDF from 'hooks/queries/usePDF'

interface DownloadNFDialogProps {
  nf: NFModel
  chaveId?: TypeNFModelo
  isOpen: boolean
  onClose: () => void
}

export default function DownloadNFDialog(props: DownloadNFDialogProps) {
  const { isOpen, onClose, chaveId, nf } = props

  const { downloadArquivo } = useDownload()

  const { useObterPDF } = usePDF()
  const { obterPDF, isDownloading, progress } = useObterPDF()

  const [relatorioId, setRelatorioId] = useState('')

  async function handleObterPDF(relatorioId: string, nfId: string) {
    const response = await obterPDF({
      route: 'NFConsulta/ObterPDF',
      axiosRequestConfig: { params: { relatorioId, nfId } },
    })

    const chaveAcesso = nf?.nFe_ChaveAcesso || ''
    const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]
    downloadArquivo(response, [chaveAcesso, nfModeloDescricao], 'pdf')
    onClose()
  }

  return (
    <>
      {isDownloading ? (
        <ProgressDownloadDialog
          isOpen={isDownloading}
          progressValue={progress}
          title={`Baixando ${chaveId}...`}
        />
      ) : (
        <Dialog
          open={isOpen}
          title={`Baixar ${chaveId}`}
          actions={
            <>
              <Button color="error" fullWidth onClick={onClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleObterPDF(relatorioId, nf?.id)}
              >
                Baixar
              </Button>
            </>
          }
        >
          <DropDownRelatorio
            value={relatorioId}
            requestProps={{ chaveId: chaveId || '' }}
            onChange={(e) => setRelatorioId(e.target.value)}
            onRender={(primeiroRelatorio) =>
              setRelatorioId(primeiroRelatorio?.id)
            }
          />
        </Dialog>
      )}
    </>
  )
}
