import { memo } from 'react'

import { Email as EmailIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Box, Grow, TextFieldProps } from '@mui/material'

import TextFieldWithAction from 'components/TextFieldWithAction'

interface MemoizedTextFieldProps {
  id: string
  value: string
  disabled?: boolean
  inputProps?: TextFieldProps
  onChange: (value: string, id?: string) => void
  onClick: (id: string) => void
}

function CustomTextField(props: MemoizedTextFieldProps) {
  const { id, value, disabled, inputProps, onChange, onClick } = props

  return (
    <Grow in={true}>
      <Box
        key={id}
        sx={{
          position: 'relative',
          '& .MuiInputBase-input': { paddingLeft: '32px' },
        }}
      >
        <TextFieldWithAction
          id={id}
          value={value}
          startIcon={<EmailIcon />}
          actionIcon={<DeleteIcon sx={{ color: '#fff' }} />}
          disabled={disabled}
          inputProps={inputProps}
          onChange={(value, id) => onChange(value, id)}
          onClick={() => onClick(id)}
        />
      </Box>
    </Grow>
  )
}

export const MemoizedTextField = memo(CustomTextField)
