export enum MeioPagamentoEnum {
  DINHEIRO = 1,
  CHEQUE = 2,
  CARTAO_CREDITO = 3,
  CARTAO_DEBITO = 4,
  CREDITO_LOJA = 5,
  VALE_ALIMENTACAO = 10,
  VALE_REFEICAO = 11,
  VALE_PRESENTE = 12,
  VALE_COMBUSTIVEL = 13,
  BOLETO_BANCARIO = 15,
  DEPOSITO_BANCARIO = 16,
  PAGAMENTO_INSTANTANEO_PIX = 17,
  TRANSFERENCIA_BANCARIA = 18,
  PROGRAMA_FIDELIDADE_CASHBACK_CREDITO_VIRTUAL = 19,
  SEM_PAGAMENTO = 90,
  OUTROS = 99,
}
