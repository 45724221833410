import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import apiHubbank from 'services/api-hubbank'

export interface ConvenioResumoModel {
  id?: string
  tipoCobrancaTarifa: number
  empresaId: string
  convenioDesc?: string | null
  descricao?: string | null
  empresaCNPJCPF?: string | null
  empresaNome?: string | null
  bancoCodigo: number
  bancoNomeDesc?: string | null
  ativo: boolean
  convenioAgencia?: number | null
  convenioConta?: number | null
  convenioNumero?: string | null
  carteiraCodigo?: string | null
  carteiraVariacao?: string | null
  nossoNumeroUltimoSequencial: number
  impressaoBoletoPadraoId?: string | null
  dataConsultaUltimoMovimento?: string | null
  ultimoNossoNumeroConsultado: number
}

async function obterConvenio(
  params?: QueryParamsInterface,
): Promise<HttpResponseInterface<ConvenioResumoModel>> {
  const response = await apiHubbank.get(
    `Convenio/Pesquisar/${params?.query || ''}`,
  )
  return response?.data
}

export function useQueryObterConvenio(params?: QueryParamsInterface) {
  return useQuery<
    HttpResponseInterface<ConvenioResumoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CONVENIO', params], () => {
    return obterConvenio(params)
  })
}

export default function useConvenio() {
  return {
    useQueryObterConvenio,
  }
}
