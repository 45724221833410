import { ReactNode } from 'react'

import { Close as CloseIcon } from '@mui/icons-material'
import { AppBar, IconButton, Stack, Toolbar } from '@mui/material'

import SelectableCountText from 'components/SelectableCountText'
import Container from 'components/Container'

interface SelectableToolbarProps {
  countQuantidade: number
  children: ReactNode
  onClear: () => void
}

export default function SelectableToolbar(props: SelectableToolbarProps) {
  const { countQuantidade, children, onClear } = props

  return (
    <>
      {countQuantidade > 0 && (
        <AppBar
          sx={{
            backgroundColor: '#fff',
            boxShadow: 'none',
            animation: 'fadeIn 200ms ease-out',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
              },
              '100%': {
                opacity: 1,
              },
            },
          }}
        >
          <Container overflow="visible" fullHeight={false}>
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingY: 2,
                paddingX: 1,
                height: '66px',
              }}
            >
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <IconButton onClick={onClear}>
                  <CloseIcon color="error" fontSize="medium" />
                </IconButton>

                <SelectableCountText countQuantidade={countQuantidade} />
              </Stack>

              {children}
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  )
}
