import { ReactNode } from 'react'

import { Drawer, Grid, Stack, Typography, useTheme } from '@mui/material'
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material'

import { useFormApi, useFormData } from '@data-c/providers'

import ButtonContainer from 'components/ButtonContainer'
import Button from 'components/Button'

interface FormDrawerContainerProps {
  title?: string
  description?: string
  confirmLabel?: string
  confirmIcon?: ReactNode
  extraOption?: ReactNode
  children: ReactNode
  onSubmit: () => void
}

export default function FormDrawerContainer(props: FormDrawerContainerProps) {
  const {
    title,
    description,
    confirmLabel,
    confirmIcon,
    extraOption,
    children,
    onSubmit,
  } = props
  const theme = useTheme()

  const { closeForm } = useFormApi()
  const { isOpen, isSubmitting } = useFormData()

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      PaperProps={{
        style: {
          padding: isOpen ? '8px 0 8px 0' : '',
          borderTop: `solid 1px ${theme.palette.primary.main}`,
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        },
      }}
    >
      <Stack padding={2} gap={3}>
        <Stack spacing={0.5}>
          <Typography variant="h2" component="h2" fontWeight="bold">
            {title ? title : ''}
          </Typography>

          <Typography
            variant="h5"
            color={theme.palette.grey[500]}
            fontWeight="bold"
          >
            {description}
          </Typography>
        </Stack>

        <Grid container spacing={2}>
          {children}
        </Grid>

        <ButtonContainer>
          <Button
            fullWidth
            variant="contained"
            startIcon={confirmIcon || <CheckIcon />}
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            {confirmLabel ? confirmLabel : 'Salvar'}
          </Button>
          <Button
            fullWidth
            color="error"
            startIcon={<CloseIcon />}
            onClick={closeForm}
          >
            Cancelar
          </Button>
          {extraOption}
        </ButtonContainer>
      </Stack>
    </Drawer>
  )
}
