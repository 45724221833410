import { useMemo } from 'react'
import { Grid, TextField } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import FormDrawerContainer from 'components/FormDrawerContainer'

import { NFModel } from 'hooks/queries/useNF'
import useNFOutrasInformacoes from 'hooks/queries/useNFOutrasInformacoes'

export default function Form() {
  const { formValues: data, isOpen } = useFormData<NFModel>()
  const { changeValue, toggleSubmit, closeForm } = useFormApi<NFModel>()

  const { useAlterarInformacoesComplementares } = useNFOutrasInformacoes()
  const { mutateAsync: alterarInformacoesComplementares } =
    useAlterarInformacoesComplementares()

  async function handleSubmit() {
    toggleSubmit(true)
    await alterarInformacoesComplementares(data).finally(() => {
      toggleSubmit(false)
    })
    closeForm()
  }

  const title = useMemo(() => {
    return `${
      data?.informacoesComplementares ? 'Alterar' : 'Adicionar'
    } Interesse do Contribuinte`
  }, [isOpen])

  return (
    <FormDrawerContainer title={title} onSubmit={handleSubmit}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          value={data?.informacoesComplementares || ''}
          label="Informações Complementares de Interesse do Contribuinte"
          multiline
          rows={10}
          onChange={(e) =>
            changeValue('informacoesComplementares', e.target.value || null)
          }
        />
      </Grid>
    </FormDrawerContainer>
  )
}
