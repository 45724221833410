import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { HttpResponseInterface } from '@data-c/hooks'

import HttpObjResponseInterface from 'interfaces/HttpObjResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import api from 'services/api'

export interface EmpresaModel {
  id: string
  nome: string
  codigo: string
  cidadeUF?: string | null
  ativo: boolean
}

export async function obterEmpresas() {
  const response = await api.get<HttpResponseInterface<EmpresaModel>>('Empresa')
  return response.data
}

export async function obterEmpresaPorId(id: string) {
  const response = await api.get(`Empresa/${id}`)
  return response?.data
}

export function useQueryObterEmpresas() {
  return useQuery<
    HttpResponseInterface<EmpresaModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['EMPRESAS'], () => {
    return obterEmpresas()
  })
}

export function useQueryObterEmpresaPorId(id: string) {
  return useQuery<
    HttpObjResponseInterface<EmpresaModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['EMPRESA', id], () => {
    return obterEmpresaPorId(id)
  })
}

export default function useEmpresa() {
  return {
    useQueryObterEmpresas,
    useQueryObterEmpresaPorId,
  }
}
