import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, MenuItem, TextField } from '@mui/material'
import useCredentials, { LoginDataInterface } from 'hooks/useCredentials'
import Button from 'components/Button'

interface LicencaFormProps {
  loginData?: LoginDataInterface
}

export default function LicencaForm(props: LicencaFormProps) {
  const { loginData } = props

  const [empresaId, setEmpresaId] = useState<string | undefined>('')
  const { selecionarLicenca, isSubmitting } = useCredentials()
  const navigate = useNavigate()

  useEffect(() => {
    if (Array.isArray(loginData?.empresas)) {
      const empresa = loginData?.empresas[0]
      setEmpresaId(empresa?.uuid)
    }
  }, [loginData])

  async function handleSelecionarLicenca() {
    await selecionarLicenca(loginData, empresaId)
    navigate('/vendas')
  }

  return (
    <Box component="form" sx={{ minWidth: '290px' }}>
      <TextField
        label="Licenças"
        margin="normal"
        select
        autoFocus
        value={empresaId}
        onChange={(e) => {
          setEmpresaId(e.target.value)
        }}
      >
        {loginData?.empresas?.map((empresa) => (
          <MenuItem value={empresa.uuid} key={empresa.uuid}>
            {empresa.nome}
          </MenuItem>
        ))}
      </TextField>
      <Button
        fullWidth
        variant="contained"
        onClick={handleSelecionarLicenca}
        isLoading={isSubmitting}
        sx={{ mt: 1 }}
      >
        Confirmar
      </Button>
    </Box>
  )
}
