import { useNavigate } from 'react-router-dom'

import { Grid, Stack } from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'

import { useForm } from '@data-c/hooks'

import useCredentials from 'hooks/useCredentials'
import useNotification from 'hooks/useNotification'
import useValidations from 'hooks/useValidation'

import Button from 'components/Button'
import PasswordTextField from 'components/PasswordTextField'

import * as yup from 'yup'

const defaultData = {
  senhaAtual: '',
  senhaNova: '',
  confirmarSenhaNova: '',
}

const schema = yup.object().shape({
  senhaAtual: yup.string().required('Informe a Senha Atual.'),
  senhaNova: yup.string().required('Informe a Nova Senha.'),
  confirmarSenhaNova: yup
    .string()
    .required('Informe a Nova Senha a ser Confirmada.')
    .oneOf(
      [yup.ref('senhaNova')],
      'A nova senha e a senha a ser confirmada devem ser iguais.',
    ),
})

export default function Form() {
  const { validationProps, setValidationErrors } = useValidations()
  const { alterarSenha, isSubmitting } = useCredentials()

  const notifications = useNotification()
  const navigate = useNavigate()

  const { data, handleChange, setData } = useForm(defaultData)

  function handleBack() {
    navigate('/perfil')
  }

  async function handleAlterarSenha() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        const { senhaAtual, senhaNova } = data

        try {
          const response = await alterarSenha(senhaAtual, senhaNova)
          if (response) {
            notifications.notifySuccess('A sua senha foi alterada com sucesso')
            setData(defaultData)
            handleBack()
          }
        } catch (error) {
          notifications.notifyError('Não foi possível alterar a senha')
        }
      })
      .catch((error: any) => {
        setValidationErrors(error)
      })
  }

  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PasswordTextField
            name="senhaAtual"
            label="Senha Atual"
            value={data?.senhaAtual || ''}
            onChange={handleChange}
            {...validationProps('senhaAtual')}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PasswordTextField
            name="senhaNova"
            label="Nova Senha"
            value={data?.senhaNova || ''}
            onChange={handleChange}
            {...validationProps('senhaNova')}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PasswordTextField
            name="confirmarSenhaNova"
            label="Confirmar Nova Senha"
            value={data?.confirmarSenhaNova || ''}
            onChange={handleChange}
            {...validationProps('confirmarSenhaNova')}
          />
        </Grid>
      </Grid>

      <Button
        fullWidth
        variant="contained"
        startIcon={<CheckIcon />}
        onClick={handleAlterarSenha}
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Stack>
  )
}
