import { Avatar, Stack, Typography, useTheme } from '@mui/material'

interface HeaderProps {
  userName?: string
  empresaName?: string
}

export default function Header(props: HeaderProps) {
  const { userName, empresaName } = props
  const theme = useTheme()

  return (
    <Stack alignItems="center" gap={3}>
      <Avatar sx={{ width: '62px', height: '62px' }} alt="photo" />

      <Stack gap={1} textAlign="center">
        <Typography variant="h2" fontWeight="bold">
          {userName || ''}
        </Typography>

        <Typography
          variant="h5"
          color={theme.palette.grey[500]}
          fontWeight="bold"
        >
          {empresaName || ''}
        </Typography>
      </Stack>
    </Stack>
  )
}
