import ReactXMLViewer from 'react-xml-viewer'
import { Box } from '@mui/material'

import Loading from 'components/Displays/Loading'
import Error from 'components/Displays/Error'
import EmptyData from 'components/EmptyData'

interface XmlContentViewerProps {
  arquivoXML: string
  isLoading?: boolean
  error?: unknown
}

export default function XmlContentViewer(props: XmlContentViewerProps) {
  const { arquivoXML, isLoading, error } = props

  return (
    <Box
      sx={{
        position: 'relative',
        padding: 2,
        height: '100%',
        marginTop: '78px',
      }}
    >
      {isLoading && <Loading customSx={{ zIndex: 0 }} />}
      {Boolean(error) && <Error />}
      {!isLoading && Boolean(!error) && (
        <>
          {arquivoXML === '' ? (
            <EmptyData description="Nenhum XML encontrado" />
          ) : (
            <ReactXMLViewer xml={arquivoXML} collapsible />
          )}
        </>
      )}
    </Box>
  )
}
