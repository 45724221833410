import {
  DoneAll as DoneAllIcon,
  Download as DownloadIcon,
  MoveUp as MoveUpIcon,
} from '@mui/icons-material'
import { FormProvider } from '@data-c/providers'
import {
  OptionListButton,
  OptionsListContainer,
  ActionOptionsProvider,
} from 'components/OptionsListContainer'
import { useSelectableCardContext } from 'contexts/SelectableCardContext'
import { DuplicataModel } from 'hooks/queries/useDuplicatas'

import BoletoRegistro from './components/BoletoRegistro'
import BoletoImpressao from './components/BoletoImpressao'

interface ActionsProps {
  duplicatas: DuplicataModel[]
}

export enum TipoOrigem {
  SELECIONAR_TODOS = 'SELECIONAR_TODOS',
  REGISTRAR_BOLETOS = 'REGISTRAR_BOLETOS',
  BAIXAR_DUPLICATAS = 'BAIXAR_DUPLICATAS',
}

export default function Actions(props: ActionsProps) {
  const { duplicatas } = props

  const { onSelecionarTodosItens } = useSelectableCardContext<DuplicataModel>()

  function handleSelecionarTodasDuplicatas() {
    onSelecionarTodosItens(duplicatas)
  }

  return (
    <ActionOptionsProvider>
      <OptionsListContainer triggerLabel="Opções">
        <OptionListButton
          name={TipoOrigem.SELECIONAR_TODOS}
          label="Selecionar todos"
          icon={<DoneAllIcon />}
          onClick={handleSelecionarTodasDuplicatas}
        />
        <OptionListButton
          name={TipoOrigem.REGISTRAR_BOLETOS}
          label="Emitir Boletos"
          icon={<MoveUpIcon />}
        />
        <OptionListButton
          name={TipoOrigem.BAIXAR_DUPLICATAS}
          label="Baixar PDF dos boletos"
          icon={<DownloadIcon />}
        />
      </OptionsListContainer>

      <FormProvider>
        <BoletoRegistro name={TipoOrigem.REGISTRAR_BOLETOS} />
      </FormProvider>
      <FormProvider>
        <BoletoImpressao name={TipoOrigem.BAIXAR_DUPLICATAS} />
      </FormProvider>
    </ActionOptionsProvider>
  )
}
