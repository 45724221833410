import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FilterOptions } from '@data-c/providers'
import { DropDownBoletoTipoDataEnum } from 'components/Inputs/DropDownBoletoTipoData'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import apiHubbank from 'services/api-hubbank'

import { dateEndOfMonth, dateStartOfMonth } from './useDateFormat'
import { ConvenioResumoModel } from './useConvenio'
import { EmpresaModel } from './useEmpresa'

export interface BoletoDetalhadoModel {
  id?: string
  shortId?: string | null
  origem: number
  origemDesc?: string | null
  status: number
  statusDesc?: string | null
  dataCriacao: string
  dataAlteracao: string
  dataRegistro?: string | null
  empresaId: string
  convenioId: string
  convenioBancoCodigo: number
  convenioBancoCodigoDesc?: string | null
  carteiraCodigo?: string | null
  carteiraVariacao?: string | null
  convenioAgencia: number
  convenioContaCorrente: number
  convenioNumero?: string | null
  diasParaNegativacao: number
  diasLimiteRecebimento: number
  permiteRecebimentoParcial: boolean
  mensagemOcorrencia?: string | null
  mensagemJurosMulta?: string | null
  beneficiarioFinalCNPJCPF?: string | null
  beneficiarioFinalNome?: string | null
  beneficiarioFinalCEP?: string | null
  beneficiarioFinalLogradouro?: string | null
  beneficiarioFinalBairro?: string | null
  beneficiarioFinalCidadeNome?: string | null
  beneficiarioFinalUF?: string | null
  pagadorCNPJCPF?: string | null
  pagadorNome?: string | null
  pagadorLogradouro?: string | null
  pagadorComplemento?: string | null
  pagadorBairro?: string | null
  pagadorCidadeNome?: string | null
  pagadorUF?: string | null
  pagadorCEP?: string | null
  pagadorTelefone?: string | null
  pagadorEmail?: string | null
  nossoNumero?: string | null
  nossoNumeroSequencial: number
  documentoSistema?: string | null
  dataEmissao: string
  dataVencimento?: string | null
  dataPagamento?: string | null
  dataAntecipacao?: string | null
  dataAntecipacaoCancelamento: string
  valorTitulo: number
  valorAbatimento: number
  multaTipo: number
  multaTipoDesc?: string | null
  multaValor?: number | null
  jurosTipo: number
  jurosTipoDesc?: string | null
  jurosValor?: number | null
  descontoTipo: number
  descontoTipoDesc?: string | null
  descontoDataExpiracao?: string | null
  descontoValor: number
  protestoTipo: number
  protestoTipoDesc?: string | null
  protestoDias?: number | null
  codigoBarras?: string | null
  linhaDigitavel?: string | null
  pixQrCode?: string | null
  incluirPix: boolean
  dataMovimento?: string | null
  tipoMovimento: number
  tipoMovimentoDesc?: string | null
  dataCredito?: string | null
  valorAtual?: number | null
  valorMultaCobrada?: number | null
  valorDescontoConcedido?: number | null
  valorPago?: number | null
  valorTarifa?: number | null
  dataTarifa?: string | null
  dataCancelamento?: string | null
}

export interface BoletoFilters {
  dataInicio: FilterOptions<string>
  dataFim: FilterOptions<string>
  empresaId?: FilterOptions<string>
  empresa?: FilterOptions<EmpresaModel | null>
  pagadorConteudo?: FilterOptions<string>
  convenio?: FilterOptions<ConvenioResumoModel | null>
  convenioId?: FilterOptions<string | null>
  tipoData: FilterOptions<number | string>
}

export interface BoletoFiltersOptions {
  dataInicio: string
  dataFim: string
  empresaId: string
  empresa?: EmpresaModel | null
  pagadorConteudo?: string | null
  convenio?: ConvenioResumoModel | null
  convenioId?: string | null
  tipoData: number
}

export const boletofilters: BoletoFilters = {
  pagadorConteudo: {
    label: 'Pagador',
    value: '',
  },
  dataInicio: {
    label: 'Início',
    value: dateStartOfMonth(),
  },
  dataFim: {
    label: 'Fim',
    value: dateEndOfMonth(),
  },
  empresaId: {
    label: 'Empresa',
  },
  empresa: {
    label: 'Empresa',
  },
  convenio: {
    label: 'Convênio',
  },
  convenioId: {
    label: 'Convênio',
  },
  tipoData: {
    label: 'Tipo',
    value: DropDownBoletoTipoDataEnum.EMISSAO_0.toString(),
  },
}

export async function obterBoletos(
  filters: BoletoFiltersOptions,
): Promise<HttpResponseInterface<BoletoDetalhadoModel>> {
  delete filters.convenio
  delete filters.empresa

  const response = await apiHubbank.post('BoletoConsulta/Consultar', filters)
  return response?.data
}

export function useQueryObterBoletos({
  queryParams,
  requestId,
}: HttpRequestInterface<BoletoFiltersOptions>) {
  return useQuery<
    HttpResponseInterface<BoletoDetalhadoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['BOLETOS', queryParams, requestId], () => obterBoletos(queryParams))
}

export default function useBoleto() {
  return {
    useQueryObterBoletos,
  }
}
