import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export default function DropDownCartaoBandeira(props: TextFieldProps) {
  return (
    <TextField select label="Bandeira" {...props}>
      <MenuItem value="1">Visa</MenuItem>
      <MenuItem value="2">Mastercard</MenuItem>
      <MenuItem value="3">AmericanExpress</MenuItem>
      <MenuItem value="4">Sorocred</MenuItem>
      <MenuItem value="5">DinersClub</MenuItem>
      <MenuItem value="6">Elo</MenuItem>
      <MenuItem value="7">Hipercard</MenuItem>
      <MenuItem value="8">Aura</MenuItem>
      <MenuItem value="9">Cabal</MenuItem>
      <MenuItem value="99">Outros</MenuItem>
    </TextField>
  )
}
