import { MenuItem } from '@mui/material'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownTipoInternoOperacaoEnum {
  VENDA_0 = 0,
  DEVOLUÇÃO_VENDA_1 = 1,
  BONIFICACAO_2 = 2,
  OUTRAS_SAIDAS_3 = 3,
  DEVOLUCAO_COMPRA_10 = 10,
  OUTRAS_ENTRADAS_11 = 11,
  TODAS = -1,
}

const {
  VENDA_0,
  DEVOLUÇÃO_VENDA_1,
  BONIFICACAO_2,
  OUTRAS_SAIDAS_3,
  DEVOLUCAO_COMPRA_10,
  OUTRAS_ENTRADAS_11,
  TODAS,
} = DropDownTipoInternoOperacaoEnum

export const nfTipoInternoOperacaoMapping: Record<number, string> = {
  [VENDA_0]: 'Venda',
  [DEVOLUÇÃO_VENDA_1]: 'Devolição de venda',
  [BONIFICACAO_2]: 'Bonificação',
  [OUTRAS_SAIDAS_3]: 'Outras saídas',
  [DEVOLUCAO_COMPRA_10]: 'Devolução de compra',
  [OUTRAS_ENTRADAS_11]: 'Outras entradas',
  [TODAS]: 'Todas',
}

const menuItems = [
  {
    value: VENDA_0,
    label: '0 - Venda',
  },
  {
    value: DEVOLUÇÃO_VENDA_1,
    label: '1 - Devolução de venda',
  },
  {
    value: BONIFICACAO_2,
    label: '2 - Bonificação',
  },
  {
    value: OUTRAS_SAIDAS_3,
    label: '3 - Outras saídas',
  },
  {
    value: DEVOLUCAO_COMPRA_10,
    label: '10 - Devolução de compra',
  },
  {
    value: OUTRAS_ENTRADAS_11,
    label: '11 - Outras entradas',
  },
  {
    value: TODAS,
    label: 'Todas',
  },
]

export default function DropDownTipoInternoOperacao(
  props: DynamicDropDownProps,
) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown
      label="Tipo interno"
      menuItems={menuItems}
      onChange={onChange}
      {...rest}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </DynamicDropDown>
  )
}
