import { Stack, Typography } from '@mui/material'
import successImage from 'assets/images/success.svg'

interface HeaderProps {
  nfModeloDescricao: string
}

export default function Header(props: HeaderProps) {
  const { nfModeloDescricao } = props

  return (
    <Stack sx={{ alignItems: 'center', gap: 3, paddingY: 3 }}>
      <img
        src={successImage}
        width="148px"
        alt="Imagem nota fiscal fechada com sucesso"
      />

      <Typography
        variant="h1"
        component="h2"
        fontSize="28px"
        fontWeight="medium"
      >
        Pronto, {nfModeloDescricao} fechada com sucesso!
      </Typography>
    </Stack>
  )
}
