import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import { useNFContext } from 'contexts/NFContext/NFContext'
import useEmpresa from 'hooks/queries/useEmpresa'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'
import EmptyData from 'components/EmptyData'

import ListItens from './components/ListItens'

export default function Empresa() {
  const navigate = useNavigate()

  const { setNFIds } = useNFContext()
  const { useQueryObterEmpresas } = useEmpresa()
  const { data: empresas, isFetching, error } = useQueryObterEmpresas()

  function handleClick(empresaId: string) {
    setNFIds('empresaId', empresaId)
    navigate('/NF/operacao')
  }

  const empresasAtivas =
    empresas?.data?.filter((empresa) => empresa.ativo === true) || []

  return (
    <Container paper>
      <PageTitle
        title="Empresa"
        onBack={() => navigate('/vendas')}
        onClose={() => navigate('/vendas')}
      />
      <ContentContainer isLoading={isFetching} error={error} disablePadding>
        <Typography
          variant="h1"
          fontSize="32px"
          fontWeight="medium"
          marginBottom={2}
          padding={2}
        >
          Selecione a empresa desejada
        </Typography>

        {empresasAtivas?.length > 0 ? (
          <ListItens
            itens={empresasAtivas}
            onClick={(empresaId) => handleClick(empresaId)}
          />
        ) : (
          <EmptyData description="Nenhuma empresa foi encontrada" />
        )}
      </ContentContainer>
    </Container>
  )
}
