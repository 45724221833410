import { MenuItem } from '@mui/material'
import { theme } from '@data-c/ui'
import DynamicDropDown, { DynamicDropDownProps } from '../DynamicDropDown'

export enum DropDownBoletoStatusEnum {
  INCLUIDO_0 = 0,
  REGISTRADO_1 = 1,
  ANTECIPADO_2 = 2,
  PAGO_3 = 3,
  CANCELADO_4 = 4,
}

const { INCLUIDO_0, REGISTRADO_1, ANTECIPADO_2, PAGO_3, CANCELADO_4 } =
  DropDownBoletoStatusEnum

export const boletoStatusMapping: Record<number, string> = {
  [INCLUIDO_0]: 'Incluído',
  [REGISTRADO_1]: 'Registrado',
  [ANTECIPADO_2]: 'Antecipado',
  [PAGO_3]: 'Pago',
  [CANCELADO_4]: 'Cancelado',
}

export const boletoStatusMappingColors: Record<number, string> = {
  [INCLUIDO_0]: theme.palette.info.main,
  [REGISTRADO_1]: theme.palette.success.main,
  [ANTECIPADO_2]: theme.palette.warning.main,
  [PAGO_3]: theme.palette.primary.main,
  [CANCELADO_4]: theme.palette.error.main,
}

export const boletoStatusColors: Record<
  number,
  'info' | 'success' | 'warning' | 'primary' | 'error'
> = {
  [INCLUIDO_0]: 'info',
  [REGISTRADO_1]: 'success',
  [ANTECIPADO_2]: 'warning',
  [PAGO_3]: 'primary',
  [CANCELADO_4]: 'error',
}

export const boletoStatusMappingOpacityColors: Record<number, string> = {
  [INCLUIDO_0]: 'rgba(3, 169, 244, 0.15)',
  [REGISTRADO_1]: 'rgba(78, 175, 80, 0.15)',
  [ANTECIPADO_2]: 'rgba(255, 205, 56, 0.15)',
  [PAGO_3]: 'rgba(89, 195, 224, 0.15)',
  [CANCELADO_4]: 'rgba(244, 67, 54, 0.15)',
}

const menuItems = [
  {
    value: INCLUIDO_0,
    label: '0 - Incluído',
  },
  {
    value: REGISTRADO_1,
    label: '1 - Registrado',
  },
  {
    value: ANTECIPADO_2,
    label: '2 - Antecipado',
  },
  {
    value: PAGO_3,
    label: '3 - Pago',
  },
  {
    value: CANCELADO_4,
    label: '4 - Cancelado',
  },
]

export default function DropDownBoletoStatus(props: DynamicDropDownProps) {
  const { onChange, ...rest } = props

  return (
    <DynamicDropDown menuItems={menuItems} onChange={onChange} {...rest}>
      {menuItems.map((item) => (
        <MenuItem value={item.value}>{item.label}</MenuItem>
      ))}
    </DynamicDropDown>
  )
}
