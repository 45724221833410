import { useMemo } from 'react'
import { formatCurrency } from '@data-c/hooks'
import { Box, Typography, useTheme } from '@mui/material'
import useCapitalize from 'hooks/useCapitalize'

interface DescricaoProdutoProps {
  codigo: string
  nome: string
  estoque: number
  unitarioBruto: number
}

export default function DescricaoProduto(props: DescricaoProdutoProps) {
  const { codigo, nome, estoque, unitarioBruto } = props

  const { capitalizeFirstLetter } = useCapitalize()
  const theme = useTheme()

  const unitarioBrutoFormatado = useMemo(() => {
    return formatCurrency(unitarioBruto)
  }, [nome])

  return (
    <Box>
      <Typography variant="h2" fontWeight="bold" sx={{ marginBottom: 0.5 }}>
        {codigo} - {capitalizeFirstLetter(nome || '')}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{ color: theme.palette.grey[500] }}
      >
        {estoque || 0} em estoque
      </Typography>
      <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
        R${unitarioBrutoFormatado}
      </Typography>
    </Box>
  )
}
