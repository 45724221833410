import { ArrowForwardIos } from '@mui/icons-material'
import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  ListItemButton,
  useTheme,
  CircularProgress,
} from '@mui/material'
import SimpleList from 'components/SimpleList'
import { ClienteModel } from 'hooks/queries/useCliente'
import useCapitalize from 'hooks/useCapitalize'
import { useState } from 'react'

interface ListItensProps {
  itens: ClienteModel[]
  isLoading?: boolean
  onClick: (pessoaId: string) => void
}

export default function ListItens(props: ListItensProps) {
  const { itens, onClick, isLoading = false } = props

  const theme = useTheme()
  const { capitalizeFirstLetter } = useCapitalize()

  const [pessoaSelecionadaId, setPessoaSelecionadaId] = useState('')

  function handleClick(pessoaId: string) {
    if (isLoading) return
    setPessoaSelecionadaId(pessoaId)
    onClick(pessoaId)
  }

  return (
    <SimpleList sx={{ position: 'relative' }}>
      {itens.map((item) => {
        return (
          <Box
            key={item.id}
            sx={{
              borderBottom: `solid 1px ${theme.palette.grey[300]}`,
              position: 'relative',
              ':after': {
                content: '""',
                position: 'absolute',
                width: '4px',
                height: '100%',
                backgroundColor: item.ativo ? '' : theme.palette.error.main,
                top: 0,
                left: 0,
                bottom: 0,
              },
              backgroundColor:
                isLoading && pessoaSelecionadaId === item?.id
                  ? theme.palette.grey[300]
                  : 'transparent',
            }}
          >
            <ListItem
              onClick={() => handleClick(item?.id || '')}
              disablePadding
            >
              <ListItemButton
                disabled={isLoading}
                sx={{
                  '&.MuiListItemButton-root': {
                    '&.Mui-disabled': { opacity: 1 },
                  },
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    primary={`${item?.codigo || ''} - ${item.nome}`}
                  />

                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.grey[500] }}
                  >
                    {item.documentoId}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.grey[500],
                      textTransform: '',
                    }}
                  >
                    {capitalizeFirstLetter(
                      `${item.bairro}, ${item.cidadeNome}`,
                    )}{' '}
                    - {item.cidadeUF}
                  </Typography>
                </Box>

                {isLoading && pessoaSelecionadaId === item?.id ? (
                  <CircularProgress size={24} />
                ) : (
                  <ArrowForwardIos />
                )}
              </ListItemButton>
            </ListItem>
          </Box>
        )
      })}
    </SimpleList>
  )
}
