import { ReactNode } from 'react'
import { Box, SxProps, useTheme } from '@mui/material'

interface ContainerProps {
  children: ReactNode
  fullHeight?: boolean
  customSx?: SxProps
  paper?: boolean
  overflow?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
}

export default function Container(props: ContainerProps) {
  const {
    children,
    customSx,
    fullHeight = true,
    paper = false,
    overflow = 'auto',
  } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        width: '100%',
        minWidth: '290px',
        maxWidth: '1120px',
        height: fullHeight ? '100vh' : '',
        margin: '0 auto',
        overflow,
        ...customSx,
        backgroundColor: paper === true ? theme.palette.background.paper : '',
      }}
    >
      {children}
    </Box>
  )
}
