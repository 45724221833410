import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFilterData } from '@data-c/providers'
import { formatCurrency } from '@data-c/hooks'

import ContentContainer from 'components/Container/components/ContentContainer'
import EmptyData from 'components/EmptyData'

import useNF, {
  NFConsultaFiltersOptions,
  NFConsultaModel,
} from 'hooks/queries/useNF'
import useDateFormat from 'hooks/queries/useDateFormat'

import DataListProps from 'interfaces/DataListProps'

import { VendaCardInfo } from '../VendaCardInfo'
import { formatarFiltros } from '../Filter'
import useSort from 'hooks/useSort'

interface ListaProps extends DataListProps<NFConsultaFiltersOptions> {}

export default function Lista({ filters }: ListaProps) {
  const navigate = useNavigate()

  const { formatDateToDiaMesAno } = useDateFormat()
  const { ordenarDataPorMaisRecente } = useSort()
  const { searchId } = useFilterData()

  const { useQueryObterNFConsulta } = useNF()
  const { data, isLoading, isFetching, error } = useQueryObterNFConsulta({
    queryParams: formatarFiltros(filters),
    requestId: searchId,
  })

  const dataFormatada = useMemo(
    () =>
      data?.data?.map((item) => {
        return {
          ...item,
          dtLancamentoFormatada: formatDateToDiaMesAno(
            item?.dtLancamento || '',
          ),
          totalProdutoFormatado: formatCurrency(item?.totalProduto),
          totalNFFormatado: formatCurrency(item?.totalNF),
        }
      }) || [],
    [data?.data],
  )

  const handleClickVendaCardInfo = useCallback(
    (nfConsulta: NFConsultaModel) => {
      const paginaDetalhamentoNF = `/detalhamento-nf`
      const paginaProdutos = `/produtos`
      const paginaNFFechada = `/nf-fechada`
      const paginaNFTransmitida = `/nf-transmitida`
      const paginaNFCancelada = `/nf-cancelada`

      const statusToRouteMap: Record<number, string> = {
        0: nfConsulta?.totalProduto > 0 ? paginaDetalhamentoNF : paginaProdutos,
        10: paginaNFFechada,
        40: paginaNFTransmitida,
        90: paginaNFCancelada,
      }

      const route =
        `/NF/${nfConsulta?.id}` + statusToRouteMap[nfConsulta?.status]

      if (route) {
        navigate(route)
      }
    },
    [],
  )
  const dataOrdenada = ordenarDataPorMaisRecente(dataFormatada, 'dtLancamento')

  return (
    <ContentContainer
      isLoading={isLoading || isFetching}
      error={error}
      disablePadding
      customSx={{
        marginTop: '177px',
        marginBottom: '80px',
      }}
    >
      {dataOrdenada.length > 0 ? (
        dataOrdenada.map((nf) => (
          <VendaCardInfo
            key={nf.id}
            data={nf}
            onClick={handleClickVendaCardInfo}
          />
        ))
      ) : (
        <EmptyData description="Nenhum registro foi encontrado!" />
      )}
    </ContentContainer>
  )
}
