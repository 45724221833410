import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useNFContext } from 'contexts/NFContext/NFContext'

import useNF from 'hooks/queries/useNF'
import useFormaPagamento from 'hooks/queries/useFormaPagamento'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'

import Form from './components/Form'

export default function ConfigurarPagamento() {
  const { nfId } = useParams()
  const navigate = useNavigate()

  const { nfIds, setNFIds } = useNFContext()
  const { useQueryObterNFPorId } = useNF()
  const { useQueryObterFormaPagamentoPorId } = useFormaPagamento()
  const {
    data: nf,
    isLoading: isLoadingNF,
    error: errorNF,
  } = useQueryObterNFPorId(nfId || '')
  const {
    data: formaPagamento,
    isLoading: isLoadingFormaPagamento,
    error: errorFormaPagamento,
  } = useQueryObterFormaPagamentoPorId(nfIds.formaPagamentoId)

  function handleBack() {
    if (nfIds.nfPagamentoId) {
      navigate(`/NF/${nfId}/detalhamento-nf`)
      setNFIds('nfPagamentoId', '')
      return
    }
    navigate(`/NF/${nfId}/forma-de-pagamento`)
  }

  const isLoading = isLoadingNF || isLoadingFormaPagamento
  const error = errorNF || errorFormaPagamento

  return (
    <Container paper>
      <PageTitle title="Configurar Pagamento" onBack={handleBack} />
      <ContentContainer
        isLoading={isLoading}
        error={error}
        customSx={{ marginBottom: 20 }}
      >
        <Typography
          variant="h1"
          fontSize="28px"
          fontWeight="medium"
          marginBottom={6}
        >
          Defina as opções de pagamento
        </Typography>

        <Form nf={nf} formaPagamento={formaPagamento} />
      </ContentContainer>
    </Container>
  )
}
