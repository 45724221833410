import { Box, Typography, TypographyProps } from '@mui/material'

interface FloatFooterCurrencyProps {
  title: string
  price: string
  priceProps?: TypographyProps
  titleProps?: TypographyProps
}

export default function FloatFooterCurrency(props: FloatFooterCurrencyProps) {
  const { title, price, titleProps, priceProps } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <Typography variant="body2" {...titleProps}>
        {title}:
      </Typography>
      <Typography variant="button" {...priceProps}>
        R$ {price}
      </Typography>
    </Box>
  )
}
