import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'
import api from 'services/cadastro-api'

import useAutocomplete, { AutoCompleteProps } from 'hooks/useAutocomplete'
import { UFModel } from 'hooks/queries/useCidade'

export default function AutoCompleteUF(props: AutoCompleteProps<UFModel>) {
  const { onChange, value, name, label, required, helperText, error } = props

  const { options, onInputChange, inputValue, isLoading, setOptions } =
    useAutocomplete<UFModel>({
      search: async (query: string) => {
        const response = await api.get(`UF/Pesquisar/${query}`)
        if (response) {
          return response.data.data
        }
      },
    })

  async function handleObterTodosUFs() {
    try {
      const response = await api.get(`UF/Pesquisar/`)

      if (!response) return
      setOptions(response.data.data)
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <Autocomplete
      options={options}
      onOpen={handleObterTodosUFs}
      value={value || null}
      onChange={onChange}
      getOptionLabel={(uf: UFModel) => {
        return `${uf?.id}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={name}
          label={label}
          required={required}
          helperText={helperText}
          error={error}
          {...params}
        />
      )}
      onInputChange={onInputChange}
      loading={isLoading}
      loadingText={'Carregando'}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
    />
  )
}
