import { ReactElement, useState } from 'react'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'

interface SimpleListItemProps {
  id?: string
  label: string
  isLoading?: boolean
  endIcon?: ReactElement | 'none'
  startIcon?: ReactElement
  onClick?: () => void
}

export default function SimpleListItem(props: SimpleListItemProps) {
  const { id, label, isLoading = false, endIcon, startIcon, onClick } = props
  const theme = useTheme()

  const [itemSelecionadoId, setItemSelecionadoId] = useState('')

  function handleClick() {
    if (isLoading) return
    setItemSelecionadoId(id || '')
    onClick && onClick()
  }

  return (
    <ListItem
      sx={{
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        backgroundColor:
          isLoading && id === itemSelecionadoId
            ? theme.palette.grey[300]
            : 'transparent',
      }}
      disablePadding
      onClick={handleClick}
    >
      <ListItemButton
        disabled={isLoading}
        sx={{
          '&.MuiListItemButton-root': { '&.Mui-disabled': { opacity: 1 } },
        }}
      >
        {startIcon && (
          <Box
            sx={{
              marginRight: 2,
              display: 'flex',
            }}
          >
            {startIcon}
          </Box>
        )}
        <ListItemText sx={{ textTransform: 'uppercase' }} primary={label} />

        {isLoading && itemSelecionadoId === id ? (
          <CircularProgress size={20} />
        ) : endIcon === 'none' ? (
          <></>
        ) : endIcon ? (
          endIcon
        ) : (
          <ArrowForwardIosIcon />
        )}
      </ListItemButton>
    </ListItem>
  )
}
