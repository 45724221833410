import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Stack, TextField, Typography } from '@mui/material'

import { Dialog } from '@data-c/ui'
import { useDialog } from '@data-c/hooks'

import useNF, { NFModel, nfModeloMapping } from 'hooks/queries/useNF'
import useValidations from 'hooks/useValidation'

import { NFStatusEnum } from 'enums/NFStatusEnum'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button'

import DialogContentErrorNF from '../components/DialogContentErrorNF'
import * as yup from 'yup'

const schema = yup.object().shape({
  justificativa: yup
    .string()
    .required('Informe a justificativa')
    .min(15, 'Deve ter no minimo 15 caracteres'),
})

export default function CancelamentoNF() {
  const { nfId } = useParams()
  const navigate = useNavigate()

  const { validationProps, setValidationErrors } = useValidations()

  const { useQueryObterNFPorId, useCancelarNF } = useNF()
  const { mutateAsync: cancelarNF, isLoading: isCancelandoNF } = useCancelarNF()
  const { data: nf, isLoading, error } = useQueryObterNFPorId(nfId || '')

  const {
    data: dataDialog,
    isOpen: isOpenDialog,
    openDialog,
    closeDialog,
  } = useDialog<NFModel>()

  const [data, setData] = useState({ justificativa: '' })

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        const response = await cancelarNF({
          nfId: nf?.id || '',
          justificativa: data.justificativa,
        })

        if (response.status !== NFStatusEnum.CANCELADA_POR_EVENTO_NFE) {
          openDialog(response)
          return
        }

        navigate(`/NF/${nf?.id || ''}/nf-cancelada`)
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]

  return (
    <Container paper>
      <PageTitle
        title={`Cancelamento ${nfModeloDescricao}`}
        onBack={() => navigate(`/NF/${nfId}/nf-transmitida`)}
      />

      <ContentContainer
        isLoading={isLoading}
        error={error}
        customSx={{ gap: 5 }}
      >
        <Box>
          <Typography
            variant="h1"
            marginBottom={1}
            fontSize="28px"
            fontWeight="medium"
          >
            Informe a justificativa para o cancelamento da {nfModeloDescricao}
          </Typography>

          <Typography variant="body1" fontSize="18px" fontWeight="bold">
            Modelo: {nf?.modeloDesc || ''}, Série: {nf?.serie || ''} e Número:
            {nf?.numeroNF || ''}
          </Typography>
        </Box>

        <Stack gap={2}>
          <TextField
            name="justificativa"
            label="Justificativa de cancelamento"
            value={data.justificativa}
            onChange={(e) => {
              const value = e.target.value
              setData({ ...data, justificativa: value })
            }}
            {...validationProps('justificativa')}
          />

          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            isLoading={isCancelandoNF}
          >
            Confirmar o cancelamento da {nfModeloDescricao}
          </Button>
        </Stack>
      </ContentContainer>

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Não foi possível cancelar a nota"
        actions={
          <Button variant="contained" onClick={closeDialog}>
            Ok
          </Button>
        }
      >
        <DialogContentErrorNF nf={dataDialog} />
      </Dialog>
    </Container>
  )
}
