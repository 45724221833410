import { ItemProps } from 'pages/NF/Produtos/components/ProdutoCardInfo'

export enum TipoCalculoTypes {
  QUANTIDADE = 'QUANTIDADE',
  UNITARIO_BRUTO = 'UNITARIO_BRUTO',
  TOTAL_BRUTO = 'TOTAL_BRUTO',
  P_DESCONTO = 'P_DESCONTO',
  DESCONTO = 'DESCONTO',
  P_FRETE = 'P_FRETE',
  FRETE = 'FRETE',
  TOTAIS_LIQUIDOS = 'TOTAIS_LIQUIDOS',
}

function calcularDesconto(values: {
  value: number
  percentual: number
  decimal?: number
}) {
  const { value, percentual, decimal = 2 } = values

  const desconto = parseFloat(((value * percentual) / 100).toFixed(decimal))
  return desconto
}

function calcularItemPedido(
  item: ItemProps,
  tipoCalculo?: TipoCalculoTypes,
): ItemProps {
  switch (tipoCalculo) {
    case TipoCalculoTypes.QUANTIDADE:
      setQuantidadeCalc()
      break
    case TipoCalculoTypes.UNITARIO_BRUTO:
      setUnitarioBrutoCalc()
      break
    case TipoCalculoTypes.TOTAL_BRUTO:
      setTotalBrutoCalc()
      break
    case TipoCalculoTypes.P_DESCONTO:
      setPDescontoCalc()
      break
    case TipoCalculoTypes.DESCONTO:
      setDescontoCalc()
      break
    case TipoCalculoTypes.TOTAIS_LIQUIDOS:
      calcTotaisLiquidos()
      break
    default:
      break
  }

  function setQuantidadeCalc() {
    if (item?.unitarioBruto && item?.unitarioBruto > 0) {
      item.totalBruto = parseFloat(
        ((item?.unitarioBruto || 0) * item.quantidade).toFixed(2),
      )

      calcTotaisLiquidos()
    }
  }

  function setUnitarioBrutoCalc() {
    if (item.quantidade === 0) return item
    item.totalBruto = parseFloat(
      ((item?.unitarioBruto || 0) * (item.quantidade || 0)).toFixed(2),
    )
    calcTotaisLiquidos()
  }

  function setTotalBrutoCalc() {
    if (item?.produto === null) return item
    if (item.quantidade !== 0) {
      item.unitarioBruto = parseFloat(
        Math.round((item?.totalBruto || 0) / item.quantidade).toFixed(4),
      )
    }

    calcTotaisLiquidos()
    setDescontoCalc()
  }

  function setPDescontoCalc() {
    if (!item?.pDesconto) return

    item.desconto = calcularDesconto({
      value: item?.totalBruto || 0,
      percentual: item?.pDesconto || 0,
    })

    calcTotaisLiquidos()
  }

  function setDescontoCalc() {
    item.pDesconto = null
    calcTotaisLiquidos()
  }

  function calcTotaisLiquidos() {
    item.total = (item?.totalBruto || 0) - (item?.desconto || 0)

    if (item.total > 0) {
      if (item.quantidade !== 0) {
        item.unitario = parseFloat((item.total / item.quantidade).toFixed(4))
      } else {
        item.unitario = item?.unitarioBruto
      }
    } else {
      item.unitario = 0
    }
  }

  return item
}

function calcularPorcentagem(progressValue: number, targetValue: number) {
  const porcentagem =
    Math.min(Math.round((progressValue / targetValue) * 10000) / 100, 100) || 0
  return parseFloat(porcentagem.toFixed(2))
}

export default function useCalculos() {
  return {
    calcularPorcentagem,
    calcularItemPedido,
    calcularDesconto,
  }
}
