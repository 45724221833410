import { useEffect } from 'react'
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material'
import usePDF, {
  ObterRelatorioPorChaveIdProps,
  RelatorioModel,
} from 'hooks/queries/usePDF'

type DropDownRelatorioProps = {
  requestProps: ObterRelatorioPorChaveIdProps
  onRender?: (primeiroRelatorio: RelatorioModel) => void
} & TextFieldProps

export default function DropDownRelatorio(props: DropDownRelatorioProps) {
  const { requestProps, onRender } = props

  const { useQueryObterRelatorioPorChaveId } = usePDF()
  const { data, isFetching: isFetchingRelatorio } =
    useQueryObterRelatorioPorChaveId(requestProps)

  useEffect(() => {
    const primeiroRelatorio = data?.data[0]
    if (onRender && primeiroRelatorio) {
      onRender(primeiroRelatorio)
    }
  }, [data?.data])

  return (
    <TextField
      select
      label="Relatorios"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ marginRight: 2 }}>
            {isFetchingRelatorio ? <CircularProgress size={16} /> : <></>}
          </InputAdornment>
        ),
      }}
      {...props}
    >
      {data?.data?.map((relatorio) => {
        return (
          <MenuItem key={relatorio?.id} value={relatorio?.id}>
            {relatorio?.nome}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
