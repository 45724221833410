import { useNavigate, useParams } from 'react-router-dom'

import useNF from 'hooks/queries/useNF'
import usePDF from 'hooks/queries/usePDF'

import { danfDanfceMapping } from 'components/Inputs/DropDownNFModelo'
import Container from 'components/Container'

import Header from './components/Header'
import Form from './components/Form'

import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'

export default function EnvioEmailNF() {
  const { nfId } = useParams()
  const navigate = useNavigate()

  const { useQueryObterNFPorId } = useNF()
  const { data: nf, isLoading, error } = useQueryObterNFPorId(nfId || '')

  const { useQueryObterRelatorioPorChaveId } = usePDF()
  const chaveId = danfDanfceMapping[nf?.modelo || 0]
  const { data: relatorio } = useQueryObterRelatorioPorChaveId({ chaveId })

  function handleBack() {
    navigate(`/NF/${nf?.id || ''}/nf-transmitida`)
  }

  function handleClose() {
    navigate(`/vendas`)
  }

  return (
    <Container paper>
      <PageTitle title="Emails" onClose={handleClose} onBack={handleBack} />
      <ContentContainer
        isLoading={isLoading}
        error={error}
        customSx={{ justifyContent: 'initial', gap: 5, marginBottom: 20 }}
      >
        <Header modelo={nf?.modelo || 0} />
        <Form relatorio={relatorio?.data || []} nf={nf} />
      </ContentContainer>
    </Container>
  )
}
